import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import BaseInputWithValidation from "../BaseInputWithValidation";

class Checkbox extends PureComponent {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    const { checked, onChange } = this.props;
    if (onChange) onChange(!checked, event);
  }

  render() {
    const {
      label,
      labelStyle,
      style,
      checked,
      disabled,
      onChange,
      whiteBack,
      ...baseProps
    } = this.props;
    return (
      <BaseInputWithValidation {...baseProps}>
        <div
          className={`checkbox checkbox-success${
            whiteBack ? " checkbox-white" : ""
          }`}
          onClick={this.onClick}
          style={style}
        >
          <input
            id="1"
            type="checkbox"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            readOnly={!onChange}
            ref={(input) => (this.checkboxInput = input)}
          />
          <label style={labelStyle || null}>{label}</label>
        </div>
      </BaseInputWithValidation>
    );
  }
}

Checkbox.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  errors: PropTypes.array,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  whiteBack: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
