import { getRequest, patchRequest, postRequest, putRequest } from "../../base";
import { phoneToClientFormat } from "utils/formatter";
import { removeEmptyProperties } from "utils/object-helpers";
import { path } from "../../path-helpers";
import { MASTER, PRACTICE, DISPENSER } from "constants/portalTypes";

export const usersTypes = {
  [MASTER]: "users",
  [PRACTICE]: "doctors",
  [DISPENSER]: "pharmacists",
};

export const getAll = (base) => (provider_id, params) => {
  return getRequest(path(base, `${provider_id}/users`), params).then(
    (json) => json
  );
};

export const getOne = (base) => (user_id) => {
  return getRequest(path(base, `${usersTypes[base]}/${user_id}`)).then(
    (json) => ({
      ...json,
      user_id,
      phone: phoneToClientFormat(json.phone),
    })
  );
};

export const submit = (base) => (_id, user) => {
  const preparedData = removeEmptyProperties(user);

  if (_id) {
    return patchRequest(path(base, `${usersTypes[base]}/${_id}`), preparedData);
  }
  return postRequest(path(base, usersTypes[base]), preparedData);
};

export const resendInvitationForUser = (base) => (_id) => {
  return putRequest(path(base, `${usersTypes[base]}/${_id}/invite`));
};

const UsersApi = (base) => {
  return {
    getAll: getAll(base),
    getOne: getOne(base),
    resendInvitationForUser: resendInvitationForUser(base),
    submit: submit(base),
  };
};

export default UsersApi;
