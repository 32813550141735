import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";

const defaultState = {};

const subscriptionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_ONE_SUBSCRIPTION_SUCCESS:
      return objectAssign({}, state, action.subscription);
    case types.UPDATE_SHIPMENT_DATE_SUCCESS:
      return objectAssign({}, state, action.updates);
    case types.RESET_SUBSCRIPTION:
      return defaultState;
    default:
      return state;
  }
};

export default subscriptionReducer;
