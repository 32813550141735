import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { FormikInput as Input } from "shared/components/Input";
import {
  safeStringRequired,
  emailRequired,
  textareaSafeStringRequired,
  getPropsForFormikField,
} from "utils/validations";
import TextArea from "shared/components/TextArea";
import { Modal, Button } from "react-bootstrap";

const validationSchema = yup.object({
  name: safeStringRequired(),
  email: emailRequired(),
  message: textareaSafeStringRequired(),
});

const initialValues = {
  name: "",
  email: "",
  message: "",
};

const ContactUs = ({ show, submitted, toggle, submit }) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={(values) => (submitted ? toggle() : submit(values))}
  >
    {(formikProps) => {
      const { handleSubmit } = formikProps;
      const getFieldProps = getPropsForFormikField(formikProps);

      return (
        <Modal show={show} onHide={toggle}>
          <Modal.Header closeButton />
          <Modal.Body>
            {submitted ? (
              <p>Thank you. Your message has been sent.</p>
            ) : (
              <div className="Alert-content">
                <h4>Contact Us</h4>
                <span className="required">(All fields required)</span>
                <form className="row" onSubmit={handleSubmit}>
                  <Field
                    component={Input}
                    className="col-sm-12"
                    label="Your Name"
                    {...getFieldProps("name")}
                  />
                  <Field
                    component={Input}
                    className="col-sm-12"
                    label="Email Address"
                    {...getFieldProps("admin.first_name")}
                  />
                  <TextArea
                    className="col-sm-12"
                    label="Message"
                    {...getFieldProps("message")}
                  />
                </form>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="primary" bsSize="large">
              {submitted ? "OK" : "Send"}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }}
  </Formik>
);

ContactUs.propTypes = {
  show: PropTypes.bool,
  form: PropTypes.object,
  submitted: PropTypes.bool,
  toggle: PropTypes.func,
  update: PropTypes.func,
  submit: PropTypes.func,
};

export default ContactUs;
