import objectAssign from "object-assign";

import { getRequest } from "../../base";
import { path } from "../../path-helpers";

export const getOne = (base) => (doctor_id) => {
  return getRequest(path(base, `doctors/${doctor_id}`)).then((doctor) =>
    objectAssign({}, doctor, { doctor_id })
  );
};

export const getAll = (base) => ({ q, skip, limit, filters }) => {
  return getRequest(path(base, "doctors"), { q, skip, limit, ...filters }).then(
    (json) => json
  );
};

export const search = (base) => (q) => {
  return getRequest(path(base, "doctors"), { q }).then((json) => json.doctors);
};

const DoctorsApi = (base) => {
  return {
    getOne: getOne(base),
    getAll: getAll(base),
    search: search(base),
  };
};
export default DoctorsApi;
