import objectAssign from "object-assign";
import { LOCATION_CHANGE } from "connected-react-router";
import {
  NEW_ORDER_FETCH_ORDER_SUCCESS,
  NEW_ORDER_FLOW_RESET,
} from "shared/containers/OrderNew/constants/actionTypes";
import { LOAD_FROM_CACHE } from "../../../../modules/local-cache/actions";
import { getStateOnCarrierOptionsLoad } from "shared/containers/OrderNew/reducer/orderReducer";
import * as types from "../constants/actionTypes";
import { PENDING, getSubStatus } from "constants/orders/order_statuses";
import {
  card as defaultCard,
  health_insurance as defaultInsurance,
  address as defaultAddress,
} from "constants/defaults/paymentAndShipping";
import { prepare } from "utils/patient-helpers";
import { normalize } from "utils/data-helpers";
import {
  isPaymentDefault,
  prepareHealthInsurance,
} from "utils/payment-helpers";
import { isEmpty } from "utils/object-helpers";
import { addressSchema } from "api/address-helpers";

const defaultState = null;

const orderReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_FROM_CACHE:
    case NEW_ORDER_FETCH_ORDER_SUCCESS: {
      const { order } = action.state || action;
      const { patient, doctor } = order;
      const doctor_name = doctor
        ? `${doctor.first_name} ${doctor.last_name}`
        : null;

      const isOrderPaymentDefault = isPaymentDefault(
        order.payment_details?.card,
        order.payment_details?.health_insurance
      );

      const payment_details = {
        shipping_address:
          (!isEmpty(order.payment_details.shipping_address) &&
            order.payment_details.shipping_address) ||
          (patient && patient.address) ||
          defaultAddress,
        billing_address:
          (!isEmpty(order.payment_details.billing_address) &&
            order.payment_details.billing_address) ||
          (patient && patient.billing_address) ||
          defaultAddress,
        card:
          (!isOrderPaymentDefault.isCardDefault &&
            order.payment_details.card) ||
          defaultCard,
        health_insurance:
          (!isOrderPaymentDefault.isInsuranceDefault &&
            prepareHealthInsurance(order.payment_details.health_insurance)) ||
          (patient &&
            !isEmpty(patient.health_insurance?.[0]) &&
            prepareHealthInsurance(patient.health_insurance)) ||
          prepareHealthInsurance(defaultInsurance),
      };

      return {
        ...prepare(patient),
        ...order,
        payment_details,
        doctor_name,
      };
    }

    case types.UPDATE_ORDER_DETAILS_SUCCESS: {
      let updates = { ...action.updates };
      if (updates.dispenser_id) {
        updates = {
          ...updates,
          dispenser: {
            _id: updates.dispenser_id,
            name: updates.dispenser_name,
          },
        };
      }
      return objectAssign({}, state, updates);
    }

    case types.FETCH_CARRIERS_SUCCESS:
      return getStateOnCarrierOptionsLoad(state, action);

    case types.UPDATE_ORDER_LABEL: {
      const { ship_to } = action.updates;

      if (ship_to) {
        const shipping_address = normalize(ship_to, addressSchema);

        return objectAssign({}, state, {
          payment_details: {
            ...state.payment_details,
            shipping_address,
          },
        });
      }
      return state;
    }
    case types.PROCESS_PAYMENT_IN_ORDER_DETAILS_SUCCESS: {
      return objectAssign({}, state, {
        order_status: PENDING,
        sub_status: getSubStatus(PENDING, state.delivery_method),
      });
    }
    case LOCATION_CHANGE: {
      const { pathname } = action.payload.location;
      if (!pathname.includes("/orders/")) {
        return defaultState;
      }
      return state;
    }
    case NEW_ORDER_FLOW_RESET:
      return defaultState;
    default:
      return state;
  }
};

export default orderReducer;
