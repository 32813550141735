import styled from "styled-components";

const borderColor = "rgb(204, 204, 204)";

export const Wrapper = styled.div`
  float: right;
  width: 100%;
  max-width: 300px;
  padding: 20px 15px;
  border: 2px solid ${borderColor};
  .line {
    position: relative;
    border-top: 2px solid ${borderColor};
    margin-top: 15px;
  }
`;

export const RawWrapper = styled.div`
  position: relative;
  min-width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  &:first-of-type {
    margin: 0;
  }
  .name {
    font-size: 14px;
    font-weight: bold;
  }
  .price {
  }
`;
