import * as Orders from "./orders";

export const fetch = () => {
  return Promise.all([Orders.getQueue(), Orders.getRecent()]).then(
    ([queue, recent]) => ({
      queue: queue.orders,
      recent: recent.orders,
    })
  );
};

export const approve = (ids) => {
  return Orders.approve(ids);
};
