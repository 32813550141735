import React from "react";
import * as Styles from "./styles";

class Loading extends React.PureComponent {
  render() {
    return (
      <Styles.Wrapper {...this.props}>
        {[0, 1, 2].map((elem) => (
          <Styles.Bubble key={`Loading-Bounce-${elem}`} index={elem} />
        ))}
      </Styles.Wrapper>
    );
  }
}

export default Loading;
