export const STANDARD_SHIPPING = "shipping";
export const PICKUP_IN_PERSON = "pickup";
export const COURIER_SERVICE = "courier";

export const ALL_SHIPPING_OPTIONS = [
  STANDARD_SHIPPING,
  PICKUP_IN_PERSON,
  COURIER_SERVICE,
];

export const deliveryMethodsLabels = {
  [STANDARD_SHIPPING]: "Standard Delivery",
  [PICKUP_IN_PERSON]: "Pick Up In Person",
  [COURIER_SERVICE]: "Courier Service",
};
