import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";
import { getUser } from "utils/auth-helpers";

const defaultState = objectAssign({}, getUser());

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SUBMIT_MASTER_SETTINGS_SUCCESS:
      return objectAssign({}, state, action.settings);
    default:
      return state;
  }
};

export default userReducer;
