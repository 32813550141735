import * as types from "../constants/actionTypes";

const initialState = {};

const receiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_RECEIPT_REQUEST:
      return initialState;
    case types.FETCH_RECEIPT_SUCCESS:
      return action.receipt;
    default:
      return state;
  }
};

export default receiptReducer;
