import { prepareHealthInsurance } from "utils/payment-helpers";

export const prepare = (patient) =>
  patient
    ? {
        _id: patient._id,
        patient_name: [patient.first_name, patient.last_name].join(" "),
        patient_email: patient.email,
        patient_phone: patient.phone,
        date_of_birth: patient.date_of_birth,
        patient_allergies: patient.allergies,
        payment_details: {
          shipping_address: patient.address,
          billing_address: patient.billing_address,
          card: patient.card,
          health_insurance: prepareHealthInsurance(patient.health_insurance),
        },
      }
    : {};
