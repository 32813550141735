import * as types from "../constants/actionTypes";

const notificationsReducer = (state = [], action) => {
  switch (action.type) {
    case types.CREATE_NOTIFICATION:
      return [
        ...state,
        {
          id: action.id,
          text: action.text,
          type: action.alertType,
          timeout: action.timeout,
        },
      ];
    case types.DESTROY_NOTIFICATION: {
      const index = state.findIndex(({ id }) => id == action.id);
      clearTimeout(state[index].timeout);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    default:
      return state;
  }
};

export default notificationsReducer;
