import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";
import * as defaults from "constants/defaults/paymentAndShipping";
import * as validations from "../constants/validations";
import { isValidURL } from "../../OrderNew/constants/validations";
import { getPortal } from "utils/auth-helpers";
import {
  updateStateWithErasingErrors,
  newValidate as validate,
} from "utils/validators";
import { randomIdGen } from "utils/helpers";
import { prepareHealthInsurance } from "utils/payment-helpers";
import { LOCATION_CHANGE } from "connected-react-router";
import { NEW_ORDER_FETCH_ORDER_SUCCESS } from "shared/containers/OrderNew/constants/actionTypes";
import { LOAD_FROM_CACHE } from "../../../../modules/local-cache/actions";

const defaultState = () => [
  { _id: randomIdGen(), ...defaults.health_insurance },
];

const insuranceReducer = (state = defaultState(), action) => {
  switch (action.type) {
    case types.PAYMENT_AND_SHIPPING_INSURANCE_UPDATE: {
      return state.map((insurance) =>
        insurance._id === action._id
          ? updateStateWithErasingErrors(insurance, action.updates)
          : insurance
      );
    }
    case types.PAYMENT_AND_SHIPPING_ADD_INSURANCE:
      return [...state, ...defaultState()];
    case types.PAYMENT_AND_SHIPPING_REMOVE_INSURANCE:
      return state.filter((insurance) => insurance._id !== action._id);
    case NEW_ORDER_FETCH_ORDER_SUCCESS: {
      const { payment_details } = action.order;

      if (!payment_details?.health_insurance) return defaultState();

      return prepareHealthInsurance(payment_details.health_insurance);
    }
    case types.PAYMENT_AND_SHIPPING_BULK_UPDATE: {
      if (!action.health_insurance) return state;

      return prepareHealthInsurance(action.health_insurance);
    }
    case types.INSURANCE_VALIDATE:
      return state.map((insurance) =>
        objectAssign({}, insurance, {
          errors: validate(insurance, validations.health_insurance),
        })
      );
    case types.PAYMENT_AND_SHIPPING_RESET_ALL:
    case types.INSURANCE_RESET: {
      return defaultState();
    }
    case LOCATION_CHANGE: {
      const { pathname } = action.payload.location;
      if (
        !pathname.includes("/orders/") &&
        !isValidURL(pathname?.replace(`/${getPortal()}/`, "") || "")
      ) {
        return defaultState();
      }
      return state;
    }
    case LOAD_FROM_CACHE:
      return action.state.paymentAndShipping.health_insurance;
    default:
      return state;
  }
};

export default insuranceReducer;
