import { combineReducers } from "redux";
import orderReducer from "./orderReducer";
import uiReducer from "./uiReducer";
import templateReducer from "./templateReducer";
import patientReducer from "./patientReducer";
import doctorReducer from "./doctorReducer";

export default combineReducers({
  order: orderReducer,
  ui: uiReducer,
  template: templateReducer,
  patient: patientReducer,
  doctor: doctorReducer,
});
