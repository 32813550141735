import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  text-align: left;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  font-size: 20px;
`;

export default class SwitchButtons extends React.PureComponent {
  constructor(props) {
    super(props);
    this.showTerms = this.showTerms.bind(this);
    this.showPolicy = this.showPolicy.bind(this);
  }

  showTerms() {
    this.props.onSwitch("terms-of-use");
  }

  showPolicy() {
    this.props.onSwitch("privacy-policy");
  }
  render() {
    const { active } = this.props;
    return (
      <Wrapper>
        <span
          onClick={this.showTerms}
          style={{
            fontWeight: active === "terms" ? "bold" : "normal",
            paddingRight: "5px",
          }}
        >
          Terms of Use
        </span>
        <span style={{ padding: "0 5px" }}> | </span>
        <span
          onClick={this.showPolicy}
          style={{
            fontWeight: active === "policy" ? "bold" : "normal",
            paddingLeft: "5px",
          }}
        >
          Privacy Policy
        </span>
      </Wrapper>
    );
  }
}

SwitchButtons.propTypes = {
  active: PropTypes.string,
  onSwitch: PropTypes.func,
};
