import * as types from "../constants/actionTypes";

const defaultState = [];

const ordersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.PRACTICE_DASHBOARD_FETCH_SUCCESS: {
      return action.recent;
    }
    default:
      return state;
  }
};

export default ordersReducer;
