import { normalize } from "utils/data-helpers";
import { addressToServerFormat } from "./address-helpers";
import { prepareHealthInsurance } from "utils/payment-helpers";

export const preparePaymentDetails = (payment_details) => {
  return {
    card: cardToServerFormat(payment_details?.card),
    billing_address: addressToServerFormat(payment_details?.billing_address),
    shipping_address: addressToServerFormat(payment_details?.shipping_address),
    health_insurance: payment_details?.health_insurance?.[0]?.account_number
      ? payment_details?.health_insurance
      : null,
  };
};

export const creditCardSchema = {
  name: { type: "String" },
  number: { type: "String" },
  expiration_date: { type: "String" },
};

export const singleInsuranceSchema = {
  insurance_id: { type: "String" },
  payor_id: { type: "String" },
  payor_name: { type: "String" },
  account_number: { type: "String" },
  group_number: { type: "String" },
  insurer_phone: { type: "String" },
  pc_number: { type: "String" },
  rx_bin: { type: "String" },
};

export const cardToServerFormat = (card) => {
  if (!card || !card.number) return null;

  const year = card.expiry_year && card.expiry_year.slice(-2);

  const cardPrepared = {
    ...card,
    expiration_date:
      card.expiry_month && card.expiry_year
        ? `${card.expiry_month}/${year}`
        : null,
  };

  return {
    ...normalize(cardPrepared, creditCardSchema),
    security_code: card.cvv,
  };
};

export const insuranceToServerFormat = (health_insurance) => {
  if (!health_insurance) return null;

  return health_insurance.map((insurance) =>
    normalize(insurance, singleInsuranceSchema)
  );
};

export const fromServerToClient = (payment_details) =>
  payment_details
    ? {
        card: payment_details.card || {},
        shipping_address: payment_details.shipping_address || {},
        billing_address: payment_details.billing_address || {},
        health_insurance: prepareHealthInsurance(
          payment_details.health_insurance
        ),
      }
    : {};
