export const FETCH_ONE_PATIENT_REQUEST = "FETCH_ONE_PATIENT_REQUEST";
export const FETCH_ONE_PATIENT_SUCCESS = "FETCH_ONE_PATIENT_SUCCESS";
export const FETCH_ONE_PATIENT_FAILURE = "FETCH_ONE_PATIENT_FAILURE";

export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const RESET_PATIENT_FORM = "RESET_PATIENT_FORM";

export const SUBMIT_PATIENT_REQUEST = "SUBMIT_PATIENT_REQUEST";
export const SUBMIT_PATIENT_SUCCESS = "SUBMIT_PATIENT_SUCCESS";
export const SUBMIT_PATIENT_FAILURE = "SUBMIT_PATIENT_SUCCESS";

export const FINISH_PATIENT_FLOW = "PRACTICE_FINISH_PATIENT_FLOW";
