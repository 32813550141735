import { DispenserOrders } from "api/dispensers";
import * as types from "./constants/actionTypes";

export const fetch = (order_id) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_PRESCRIPTION_REQUEST });

    DispenserOrders.getPrescription(order_id).then((prescription) => {
      dispatch({ type: types.FETCH_PRESCRIPTION_SUCCESS, prescription });
    });
  };
};
