import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Paper from "shared/components/Paper";
import PatientDetails from "./PatientDetails";
import OrderContents from "./OrderContents";
import DoctorDetails from "./DoctorDetails";
import DispenserDetails from "./DispenserDetails";
import Footer from "./Footer";
import Logo from "./Logo";
import styled from "styled-components";

const Wrapper = styled.div`
  line-height: 1.7;
  color: #4e5052;
`;

const LogoAndPatientWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: #d9d9d9 5px solid;
  padding: 0 10px 20px;
  margin: 0 -10px 30px;
`;

class Prescription extends PureComponent {
  render() {
    const {
      _id,
      patient,
      items,
      doctor,
      order_date,
      patient_allergies,
      dispenser,
      prescriber_notes,
      esigned,
    } = this.props;

    if (!patient && !items && !doctor) return null;

    return (
      <Paper trigger={Boolean(_id)} bgColor="#fff">
        <Wrapper>
          <LogoAndPatientWrapper>
            <PatientDetails {...patient} allergies={patient_allergies} />
            <Logo />
          </LogoAndPatientWrapper>
          <OrderContents items={items} notes={prescriber_notes} />
          <DoctorDetails {...doctor} date={order_date} esigned={esigned} />
          <DispenserDetails {...dispenser} />
          <Footer />
        </Wrapper>
      </Paper>
    );
  }
}

Prescription.propTypes = {
  _id: PropTypes.string,
  patient: PropTypes.object,
  shipping_address: PropTypes.object,
  order_date: PropTypes.string,
  items: PropTypes.array,
  doctor: PropTypes.object,
  patient_allergies: PropTypes.array,
  dispenser: PropTypes.object,
  prescriber_notes: PropTypes.string,
  esigned: PropTypes.bool,
};

export default Prescription;
