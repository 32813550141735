export const FETCH_ONE_USER_REQUEST = "FETCH_ONE_ORGANIZATION_USER_REQUEST";
export const FETCH_ONE_USER_SUCCESS = "FETCH_ONE_ORGANIZATION_USER_SUCCESS";
export const FETCH_ONE_USER_FAILURE = "FETCH_ONE_ORGANIZATION_USER_FAILURE";

export const RESET_USER_FORM = "RESET_ORGANIZATION_USER_FORM";

export const SUBMIT_USER_REQUEST = "SUBMIT_ORGANIZATION_USER_REQUEST";
export const SUBMIT_USER_FAILURE = "SUBMIT_ORGANIZATION_USER_SUCCESS";

export const FETCH_USER_PROVIDER_SUCCESS = "FETCH_USER_PROVIDER_SUCCESS";
