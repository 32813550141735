import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { phoneToClientFormat } from "utils/formatter";
import { toString as addressToString } from "utils/address-helpers";

const DispenserDetails = ({ name, phone, address }) => (
  <Row>
    <Col md={12}>
      <h4 style={{ color: "#414244" }}>Directly Transmitted To:</h4>
      <strong>Pharmacy Name:</strong> {name}
      <br />
      <strong>Address:</strong> {addressToString(address)}
      <br />
      <strong>Phone Number:</strong> {phoneToClientFormat(phone)}
      <br />
    </Col>
  </Row>
);

DispenserDetails.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.object,
};

export default DispenserDetails;
