import commonApiServices from "../shared";
import { MASTER } from "constants/portalTypes";
import { masterGetRequest } from "./base";

export const getAll = (_, params) =>
  masterGetRequest("users", params).then((json) => json);

export const { getOne, submit, resendInvitationForUser } = commonApiServices(
  MASTER,
  "users"
);

export const getTimezones = () => masterGetRequest("users/list_tz");
