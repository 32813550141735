import React, { Component } from "react";
import { Title, Contacts } from "./styles";

class Footer extends Component {
  render() {
    return (
      <>
        <Title>Have questions?</Title>
        <div>
          For order and product related questions please call the number listed
          above in the “sold by” section.
          <br />
          For any other questions, please contact us at:
        </div>
        <Contacts>
          (714) 243-8373 | support@legranderx.com | www.legranderx.com
        </Contacts>
        <p>
          For terms and conditions, privacy policy and more, visit us at
          www.legranderx.com
        </p>
      </>
    );
  }
}

export default Footer;
