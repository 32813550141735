import commonApiServices from "../shared";
import { masterPostRequest } from "./base";
import { MASTER } from "constants/portalTypes";

export const create = (payor) => {
  return masterPostRequest("insurance/payors", payor).then((response) => {
    if (response.errors && response.errors.length) {
      throw response;
    } else {
      return response;
    }
  });
};

export const checkUniquePayorName = (name) => {
  return masterPostRequest("insurance/payor_validation", { name });
};

export const { getAll } = commonApiServices(MASTER, "payors");
