import { patientsGetRequest, patientsPatchRequest } from "./base";
import objectAssign from "object-assign";
import { sameShippingAddress } from "../address-helpers";
import { health_insurance } from "constants/defaults/paymentAndShipping";
import { prepareHealthInsurance } from "utils/payment-helpers";

export const get = (id) => {
  return patientsGetRequest(id).then((json) =>
    objectAssign(
      {},
      {
        health_insurance: prepareHealthInsurance(health_insurance),
        same_shipping: sameShippingAddress(
          json.billing_address,
          json.shipping_address
        ),
      },
      json
    )
  );
};

export const submit = (updates) => {
  const { _id, ...otherUpdates } = updates;

  return patientsPatchRequest(_id, otherUpdates);
};

export const submitTermsOfUse = (updates) => {
  const { _id, ...otherUpdates } = updates;

  return patientsPatchRequest(_id, otherUpdates);
};
