import React from "react";
import { render } from "react-dom";
import * as Sentry from "@sentry/browser";
import { AppContainer } from "react-hot-loader";
import moment from "moment";

import Root from "./Root";
import withClearCache from "./ClearCache";
import store, { history } from "./store";
import "./styles/styles.scss";
import "font-awesome/scss/font-awesome.scss";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENV,
  server_name: process.env.SENTRY_SERVER,
});

moment.parseTwoDigitYear = (yearString) =>
  parseInt(yearString) + 2000 > moment().year()
    ? parseInt(yearString) + 1900
    : parseInt(yearString) + 2000;

const mainApp = () => {
  return (
    <AppContainer>
      <Root store={store} history={history} />
    </AppContainer>
  );
};

const ClearCacheComponent = withClearCache(mainApp);

render(<ClearCacheComponent />, document.getElementById("app"));
