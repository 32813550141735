import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import SwitchButtons from "./SwitchButtons";
import { TABS, default as agreement } from "./agreement";

const TextWrapper = styled.div`
  overflow-y: scroll;
  padding: 5px 0;
  padding-right: 15px;
  text-align: left;
  font-size: 16px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

class TermsAndPolicy extends React.PureComponent {
  render() {
    const { match } = this.props;
    const active = match.path?.includes("terms") ? TABS.terms : TABS.policy;
    const text = agreement[active].text;

    return (
      <Wrapper>
        <SwitchButtons active={active} onSwitch={this.props.switchTab} />
        <TextWrapper>{text}</TextWrapper>
      </Wrapper>
    );
  }
}

TermsAndPolicy.propTypes = {
  match: PropTypes.object,
  switchTab: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  switchTab: (tabId) => (dispatch) => dispatch(push(`/${tabId}`)),
};

export default connect(null, mapDispatchToProps)(TermsAndPolicy);
