import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  OrderDropchartsList,
  DropchartDocumentsModal,
} from "shared/components/Dropcharts";

const OrderDropchartsListAndModal = ({ dropcharts, doctor_id, update }) => {
  const [showChooseDocumentsModal, changeModalVisibility] = useState(false);

  function toggleDisplayModal() {
    changeModalVisibility(!showChooseDocumentsModal);
  }

  function updateOrderDocuments(updates) {
    update({ dropcharts: updates });
  }

  return (
    <>
      <OrderDropchartsList
        dropcharts={dropcharts}
        onClickEdit={toggleDisplayModal}
      />
      <DropchartDocumentsModal
        show={showChooseDocumentsModal}
        dropcharts={dropcharts}
        onHide={toggleDisplayModal}
        selectedDoctorId={doctor_id}
        onSelect={updateOrderDocuments}
      />
    </>
  );
};

OrderDropchartsListAndModal.defaultProps = {
  dropcharts: [],
};

OrderDropchartsListAndModal.propTypes = {
  dropcharts: PropTypes.array.isRequired,
  doctor_id: PropTypes.string,
  update: PropTypes.func.isRequired,
};

export default OrderDropchartsListAndModal;
