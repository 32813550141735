import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";

const defaultState = {
  password: "",
  new_password: "",
  password_confirmation: "",
  errors: {},
};

const passwordsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.UPDATE_PASSWORD_REQUEST:
      return objectAssign({}, state, action.password);
    case types.UPDATE_PASSWORD_SUCCESS:
      return defaultState;
    case types.UPDATE_PASSWORD_FAILURE: {
      const { errors } = action;
      return objectAssign({}, state, { errors: errors[0] });
    }
    default:
      return state;
  }
};

export default passwordsReducer;
