import {
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
} from "../base";

export const dispensersGetRequest = (method, query = {}) =>
  getRequest(path(method), query);

export const dispensersPostRequest = (method, query = {}) =>
  postRequest(path(method), query);

export const dispensersPutRequest = (method, query = {}) =>
  putRequest(path(method), query);

export const dispensersPatchRequest = (method, query = {}) =>
  patchRequest(path(method), query);

export const dispensersDeleteRequest = (method, query = {}) =>
  deleteRequest(path(method), query);

const path = (method = "") => {
  const ending = method ? `${method}` : "";
  return `dispenser/${ending}`;
};
