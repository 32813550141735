import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Paper from "shared/components/Paper";
import Loading from "shared/components/Loading";
import { APIModule } from "api";
import { getPortal } from "utils/auth-helpers";

const SingleDropchartDocumentPrint = ({
  match: {
    params: { dropchart_id },
  },
}) => {
  const [fileUrl, updateFileUrl] = useState("");

  useEffect(() => {
    async function fetchDropchartInfo() {
      const { file_link } = await APIModule(getPortal()).dropcharts.getOne(
        dropchart_id
      );

      updateFileUrl(file_link);
    }

    fetchDropchartInfo();
  }, []);

  if (!fileUrl) return <Loading />;

  return (
    <Paper trigger={false}>
      <object data={fileUrl} type="application/pdf">
        <embed src={fileUrl} type="application/pdf" />
      </object>
    </Paper>
  );
};

SingleDropchartDocumentPrint.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      dropchart_id: PropTypes.string.isRequired,
    }),
  }),
  fileUrl: PropTypes.string,
};

export default withRouter(SingleDropchartDocumentPrint);
