import objectAssign from "object-assign";
import { LOCATION_CHANGE } from "connected-react-router";

import * as types from "../constants/actionTypes";
import { ONE_TIME } from "constants/orders/order_types";

const defaultState = {
  type: ONE_TIME,
  name: "",
  items: [],
  groups: [{ items: [] }],
  prescriber_notes: "",
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_ONE_TEMPLATE_SUCCESS:
      return objectAssign({}, state, action.template);
    case types.UPDATE_TEMPLATE:
      return objectAssign({}, state, action.updates);
    case LOCATION_CHANGE:
      return defaultState;
    default:
      return state;
  }
};
