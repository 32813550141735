import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Paper from "shared/components/Paper";
import { Row } from "react-bootstrap";
import OrderDetails from "./OrderDetails";
import PaymentInfo from "./PaymentInfo";
import ShippedTo from "./ShippedTo";
import Products from "./Products";
import Bill from "./Bill";
import Footer from "./Footer";
import * as Styles from "./styles";

// TODO: Need to optimize this component
class OrderTemplate extends Component {
  render() {
    if (!this.props.order_number) return null;

    const {
      title,

      pageHeader,
      pageContent,
      pageFooter,

      orderDetails,
      paymentInfo,
      shippedTo,
      products,
      bill,

      headerStyle,
      contentStyle,
      footerStyle,

      items,
      pharmacy_name,
      pharmacy_phone,
      shipped_to,
      billed_to,
      price: { shipping_price, subtotal_price, tax, total_price },
      ...order_details
    } = this.props;

    const CustomHeader = pageHeader;
    const CustomContent = pageContent;
    const CustomeFooter = pageFooter;

    const CustomOrderDetails = orderDetails;
    const CustomPaymentInfo = paymentInfo;
    const CustomShippedTo = shippedTo;
    const CustomProducts = products;
    const CustomBill = bill;

    return (
      <Paper>
        <Styles.Wrapper>
          <div style={headerStyle}>
            {pageHeader ? (
              <CustomHeader {...this.props} />
            ) : (
              <Fragment>
                <Styles.TitleLogoWrapper>
                  <Styles.Title>{title}</Styles.Title>
                  <img src="/assets/logo.svg" />
                </Styles.TitleLogoWrapper>
                <Row>
                  {orderDetails ? (
                    <CustomOrderDetails {...order_details} />
                  ) : (
                    <OrderDetails {...order_details} />
                  )}
                  {paymentInfo ? (
                    <CustomPaymentInfo {...billed_to} />
                  ) : (
                    <PaymentInfo {...billed_to} />
                  )}
                  {shippedTo ? (
                    <CustomShippedTo {...shipped_to} />
                  ) : (
                    <ShippedTo {...shipped_to} />
                  )}
                </Row>
              </Fragment>
            )}
          </div>
          <div style={contentStyle}>
            {pageContent ? (
              <CustomContent {...this.props} />
            ) : (
              <Fragment>
                <Row>
                  {products ? (
                    <CustomProducts
                      products={items}
                      pharmacy_name={pharmacy_name}
                      pharmacy_phone={pharmacy_phone}
                    />
                  ) : (
                    <Products
                      products={items}
                      pharmacy_name={pharmacy_name}
                      pharmacy_phone={pharmacy_phone}
                    />
                  )}
                </Row>
                <Row>
                  {bill ? (
                    <CustomBill
                      shipping_price={shipping_price}
                      subtotal_price={subtotal_price}
                      tax={tax}
                      total_price={total_price}
                      {...order_details}
                    />
                  ) : (
                    <Bill
                      shipping_price={shipping_price}
                      subtotal_price={subtotal_price}
                      tax={tax}
                      total_price={total_price}
                    />
                  )}
                </Row>
              </Fragment>
            )}
          </div>
          <Styles.FooterWrapper style={footerStyle}>
            {pageFooter ? <CustomeFooter {...this.props} /> : <Footer />}
          </Styles.FooterWrapper>
        </Styles.Wrapper>
      </Paper>
    );
  }
}

const ReactElement = PropTypes.oneOfType([PropTypes.func, PropTypes.element]);

OrderTemplate.defaultProps = {
  title: "Order",
};

OrderTemplate.propTypes = {
  title: PropTypes.string,
  pageHeader: ReactElement,
  pageContent: ReactElement,
  pageFooter: ReactElement,
  orderDetails: ReactElement,
  paymentInfo: ReactElement,
  shippedTo: ReactElement,
  products: ReactElement,
  bill: ReactElement,
  headerStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  footerStyle: PropTypes.object,
  order_number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  billed_to: PropTypes.object,
  shipped_to: PropTypes.object,
  items: PropTypes.array,
  pharmacy_name: PropTypes.string,
  pharmacy_phone: PropTypes.string,
  price: PropTypes.shape({
    subtotal_price: PropTypes.number,
    shipping_price: PropTypes.number,
    tax: PropTypes.number,
    total_price: PropTypes.number,
  }),
};

export default OrderTemplate;
