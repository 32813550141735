import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { formatDateForClient } from "utils/date";
import { TITLES } from "constants/orders/order_types";

const ReceiptOrderDetails = ({
  order_number,
  patient_name,
  patient,
  order_date,
  payment_date,
  type,
}) => (
  <Col md={4} sm={4}>
    <h5>Order Details:</h5>
    Order: {order_number}
    <br />
    Patient: {patient_name || `${patient.first_name} ${patient.last_name}`}
    <br />
    {"Order Date"}: {formatDateForClient(order_date)}
    {payment_date && (
      <>
        <br />
        {"Payment Date"}: {formatDateForClient(payment_date)}
      </>
    )}
    <br />
    Type: {TITLES[type]}
    <br />
  </Col>
);

ReceiptOrderDetails.propTypes = {
  order_number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  patient_name: PropTypes.string,
  patient: PropTypes.object,
  order_date: PropTypes.string,
  payment_date: PropTypes.string,
  type: PropTypes.string,
};

export default ReceiptOrderDetails;
