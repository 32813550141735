import * as types from "../constants/actionTypes";

const queueReducer = (state = [], action) => {
  switch (action.type) {
    case types.PRACTICE_DASHBOARD_FETCH_SUCCESS:
      return action.queue;
    default:
      return state;
  }
};

export default queueReducer;
