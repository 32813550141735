import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Grid, Row, Col } from "react-bootstrap";
import TermsPolicyLinks from "./TermsPolicyLinks";
import * as Styles from "./styles";

const Footer = ({ role }) => {
  return (
    <Styles.Wrapper role={role}>
      <Grid>
        <Row>
          <Col sm={0} md={1} lg={1} />
          <Col sm={6} md={7} lg={7}>
            <Link to={`/${role}`}>
              <Styles.Logo width="350px" />
            </Link>
            {role !== "master" && <TermsPolicyLinks />}
            <p className="copyright">
              Copyright © {new Date().getFullYear()} Legrande Corp. All rights
              reserved.
            </p>
          </Col>

          <Col sm={6} md={4} lg={4}>
            <Styles.ContactUs>
              <h4>Contact Us</h4>
              <a href="mailto:support@legrandehealth.com">
                support@legrandehealth.com
              </a>
              <strong>(855) 276-8555</strong>
            </Styles.ContactUs>
          </Col>
        </Row>
      </Grid>
    </Styles.Wrapper>
  );
};

Footer.propTypes = {
  role: PropTypes.string,
};

export default Footer;
