import objectAssign from "object-assign";

export const updateNestedAttr = (object, objectName, nestedName, value) => {
  let update = {};
  update[nestedName] = value;
  let result = {};
  result[objectName] = objectAssign({}, object, update);
  return result;
};

export const randomIdGen = () => {
  let S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};
