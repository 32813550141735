import {
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
} from "../base";

export const masterGetRequest = (method, query = {}) =>
  getRequest(path(method), query);

export const masterPostRequest = (method, query = {}) =>
  postRequest(path(method), query);

export const masterPutRequest = (method, query = {}) =>
  putRequest(path(method), query);

export const masterPatchRequest = (method, query = {}) =>
  patchRequest(path(method), query);

export const masterDeleteRequest = (method, query = {}) =>
  deleteRequest(path(method), query);

const path = (method = "") => {
  const ending = method ? `${method}` : "";
  return `master/${ending}`;
};
