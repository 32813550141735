import React from "react";
import styled from "styled-components";
import logo from "/assets/logo-rx-prescription.png";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
`;

const RxLogo = styled.img`
  width: 80px;
  height: 80px;
`;

const Logo = () => (
  <Wrapper>
    <RxLogo src={logo} />
    <h1 style={{ marginTop: "5px", color: "#414244" }}>Prescription</h1>
  </Wrapper>
);

export default Logo;
