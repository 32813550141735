import React from "react";
import Fetching from "./Fetching";

const withFetching = (WrappedComponent, id_field = "id") => {
  const Wrapper = (props) => (
    <Fetching
      {...props}
      WrappedComponent={WrappedComponent}
      id_field={id_field}
    />
  );

  return Wrapper;
};

export default withFetching;
