import React from "react";
import { omit } from "lodash";
import OrderDetails from "../PrintTemplates/Order/OrderDetails";

const Details = (props) => {
  const passedProps = omit({ ...props }, ["payment_date"]);
  return <OrderDetails {...passedProps} />;
};

export default Details;
