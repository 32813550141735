import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";

const defaultState = {
  show: false,
  children: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SHOW_DISPENSER_POPUP:
      return objectAssign({}, state, {
        show: true,
        children: action.children,
        ...action.options,
      });
    case types.DISMISS_DISPENSER_POPUP:
      return defaultState;
    default:
      return state;
  }
};
