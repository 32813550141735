import { isArray, omit, isEmpty } from "lodash";
import { randomIdGen } from "utils/helpers";
import { simpleCompare } from "utils/object-helpers";
import * as defaults from "constants/defaults/paymentAndShipping";

export const isPaymentMissing = (payment_details) => {
  if (!payment_details) return true;

  const { card, billing_address, shipping_address } = payment_details;

  const isCardMissing = isEmpty(card) || simpleCompare(defaults.card, card);
  const isShippingMissing =
    isEmpty(shipping_address) ||
    simpleCompare(defaults.address, shipping_address);
  const isBillingMissing =
    isEmpty(billing_address) ||
    simpleCompare(defaults.address, billing_address);

  return isCardMissing && isShippingMissing && isBillingMissing;
};

export const isPaymentFull = (payment_details) => {
  if (!payment_details) return false;

  const {
    card,
    health_insurance,
    shipping_address,
    billing_address,
  } = payment_details;

  const hasNonEmptyInsurance =
    health_insurance?.length &&
    health_insurance.every((insurance) => !isEmpty(insurance));

  const { isInsuranceDefault } = isPaymentDefault(null, health_insurance);

  if (
    (!card && !health_insurance && !health_insurance?.length) ||
    (hasNonEmptyInsurance && isInsuranceDefault) ||
    (!shipping_address && !billing_address)
  )
    return false;

  return true;
};

export const isPaymentDefault = (card = {}, health_insurance) => {
  let insurances = health_insurance;

  if (isArray(health_insurance)) {
    insurances = [...health_insurance].map((insurance) => {
      omit(insurance, ["_id"]);
      return insurance;
    });
  }

  const isCardDefault = simpleCompare(defaults.card, card);
  const isInsuranceDefault = insurances
    ? insurances.every((insurance) =>
        simpleCompare(defaults.health_insurance, insurance)
      )
    : simpleCompare(defaults.health_insurance, insurances);

  return { isCardDefault, isInsuranceDefault };
};

export const showPaymentDetails = (
  health_insurance,
  patient_insurance = {}
) => {
  const insuranceFields = [...Object.keys(defaults.health_insurance)];

  if (
    !Object.keys(patient_insurance).length &&
    Object.keys(health_insurance).length
  ) {
    return true;
  } else if (Object.keys(patient_insurance).length) {
    return insuranceFields.some(
      (field) => patient_insurance[field] !== health_insurance[field]
    );
  }
};

export const prepareHealthInsurance = (
  health_insurance = defaults.health_insurance
) => {
  const prepareSingle = (insurance) => ({ _id: randomIdGen(), ...insurance });

  return isArray(health_insurance) && health_insurance.length
    ? health_insurance.map((insurance) => prepareSingle(insurance))
    : [prepareSingle(health_insurance)];
};

export const isInsuranceEmpty = (health_insurance) => {
  if (!health_insurance) return true;

  const checkEmpty = (insurance) => {
    if (isEmpty(insurance)) {
      return true;
    } else {
      const { isInsuranceDefault } = isPaymentDefault(null, [insurance]);
      return isInsuranceDefault;
    }
  };

  return isArray(health_insurance) && health_insurance.length
    ? health_insurance.every((insurance) => checkEmpty(insurance))
    : checkEmpty(health_insurance);
};
