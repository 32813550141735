import { getItemPrice } from "./product-helpers";
import { APIModule } from "api";
import { getPortal } from "utils/auth-helpers";

export const round = (float) => Math.round(float * 1000) / 1000;

// TODO: make sure items always have numeric `price`
/**
 *
 * @param {Item[]} items
 * @returns {number}
 */
export const sumPrices = (items) => {
  return round(items.reduce((sum, item) => sum + getItemPrice(item), 0));
};

/**
 *
 * @param {number|string} price
 * @returns {string}
 */
export const priceToString = (price) => {
  let number = typeof price === "number" ? price : parseFloat(price);
  if (isNaN(number)) {
    return "-";
  } else {
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
};

export const recalcOrderPrice = async (items = []) => {
  const price = await APIModule(getPortal()).orders.getOrderPrice({ items });

  return price;
};

export const stringAsPrice = (string) => {
  const clear = string && string.replace(/[A-Za-z$\s]/g, "");
  const amount = parseFloat(clear);

  if (isNaN(amount)) {
    return "";
  } else {
    return clear;
  }
};

export const getParsedPrice = (price) => {
  const priceParsed = price.replace(/[^\d.-]/g, "");
  const [integer, rawFraction] = priceParsed.split(".");

  const fraction = rawFraction && rawFraction.slice(0, 2);

  return { integer, fraction };
};
