import styled from "styled-components";

const Edit = styled.span`
  display: inline;
  cursor: pointer;
  color: #c6aa76;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  float: ${(props) => props.float || "right"};
  &:hover {
    color: #a98746;
  }
`;

export default Edit;
