import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import objectAssign from "object-assign";
import Login from "../../components/Login";
import * as actions from "./actions";
import { capitalize } from "utils/string-helpers";
import { getLoggedInUser, mapRoleToPortal } from "utils/auth-helpers";
import Popup from "shared/containers/Popup";
import { PRACTICE } from "constants/portalTypes";

class LoginPage extends React.Component {
  render() {
    const { role, location, ...otherProps } = this.props;
    const currentUser = getLoggedInUser();
    const portalName = mapRoleToPortal(role) || PRACTICE;

    if (currentUser && currentUser.role && currentUser.terms_accepted) {
      return <Redirect to={`/${currentUser.role}`} />;
    }

    if (portalName !== role) {
      return <Redirect to={`/login/${portalName}`} />;
    } else if (location.pathname === "/") {
      return <Redirect to={`/login/${PRACTICE}`} />;
    }

    return (
      <div>
        <Login
          title={`${capitalize(portalName)} Portal`}
          role={portalName}
          {...otherProps}
        />
        <Popup className="modalGlobal" />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: { params },
    role,
  } = ownProps;
  return objectAssign(
    { role: params.role || role, popup: state.uniform.popup },
    state.login
  );
};

LoginPage.propTypes = {
  role: PropTypes.string,
  location: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, actions)(LoginPage));
