import { combineReducers } from "redux";
import cardReducer from "./cardReducer";
import shippingAddressReducer from "./shippingAddressReducer";
import billingAddressReducer from "./billingAddressReducer";
import insuranceReducer from "./insuranceReducer";
import uiReducer from "./uiReducer";

const paymentAndShippingReducer = combineReducers({
  card: cardReducer,
  shipping_address: shippingAddressReducer,
  billing_address: billingAddressReducer,
  health_insurance: insuranceReducer,
  ui: uiReducer,
});

export default paymentAndShippingReducer;
