import React from "react";
import PropTypes from "prop-types";
import * as Styles from "./styles";
import { typeByNumber, getLast4, ALL_TYPES, AMEX } from "utils/card-helpers";

class Card extends React.Component {
  getCardType() {
    const { number, card_type } = this.props;
    if (number) {
      return typeByNumber(number);
    } else if (card_type) {
      return card_type;
    }
  }

  render() {
    const { number, last_4 } = this.props;
    const last4 = last_4 || getLast4(number);
    return (
      <Styles.Wrapper data-test="wrapper">
        <Styles.CardIcon type={this.getCardType()} data-test="card-icon" />
        {`ending in ${last4}`}
      </Styles.Wrapper>
    );
  }
}

Card.propTypes = {
  card_type: PropTypes.oneOf([...ALL_TYPES, AMEX]),
  number: PropTypes.string,
  last_4: PropTypes.string,
};

export default Card;
