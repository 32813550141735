import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import resetPasswordReducer from "./resetPasswordReducer";
import contactUsReducer from "./contactUsReducer";

export default combineReducers({
  login: loginReducer,
  resetPassword: resetPasswordReducer,
  contactUs: contactUsReducer,
});
