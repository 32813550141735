import * as types from "../actionTypes";

const allergiesReducer = (state = [], action) => {
  switch (action.type) {
    case types.RESET_PATIENT_FORM:
      return [];
    case types.UPDATE_PATIENT:
      return action.updates.allergies ? action.updates.allergies : [];
    default:
      return state;
  }
};

export default allergiesReducer;
