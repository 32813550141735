import React from "react";
import PropTypes from "prop-types";

import OrderTemplate from "../PrintTemplates/Order";
import Payment from "./Payment";

const Receipt = (props) => {
  return <OrderTemplate {...props} title="Receipt" paymentInfo={Payment} />;
};

Receipt.propTypes = {
  title: PropTypes.string,
};

export default Receipt;
