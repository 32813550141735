export const FETCH_PATIENT_PROFILE_REQUEST = "FETCH_PATIENT_PROFILE_REQUEST";
export const FETCH_PATIENT_PROFILE_SUCCESS = "FETCH_PATIENT_PROFILE_SUCCESS";
export const FETCH_PATIENT_PROFILE_FAILURE = "FETCH_PATIENT_PROFILE_FAILURE";

export const FETCH_PATIENT_ORDERS_REQUEST = "FETCH_PATIENT_ORDERS_REQUEST";
export const FETCH_PATIENT_ORDERS_SUCCESS = "FETCH_PATIENT_ORDERS_SUCCESS";
export const FETCH_PATIENT_ORDERS_FAILURE = "FETCH_PATIENT_ORDERS_FAILURE";

export const FETCH_PATIENT_SUBSCRIPTION_REQUEST =
  "FETCH_PATIENT_SUBSCRIPTION_REQUEST";
export const FETCH_PATIENT_SUBSCRIPTION_SUCCESS =
  "FETCH_PATIENT_SUBSCRIPTION_SUCCESS";
export const FETCH_PATIENT_SUBSCRIPTION_FAILURE =
  "FETCH_PATIENT_SUBSCRIPTION_FAILURE";

export const UPDATE_PATIENT_PROFILE_REQUEST = "UPDATE_PATIENT_PROFILE_REQUEST";
export const UPDATE_PATIENT_PROFILE_SUCCESS = "UPDATE_PATIENT_PROFILE_SUCCESS";
export const UPDATE_PATIENT_PROFILE_FAILURE = "UPDATE_PATIENT_PROFILE_FAILURE";

export const TOGGLE_POPUP = "TOGGLE_POPUP_IN_PRACTICE";
