export const ADDRESS_FIELDS = ["street_1", "street_2", "city", "state", "zip"];

export const toString = (address) => {
  if (!address) return "";

  const { street_1, street_2, city, state, zip } = address;

  const street = [street_1, street_2]
    .filter((item) => Boolean(item))
    .join(", ");

  return `${street} ${city}, ${state} ${zip}`;
};

export const theSame = (address_1, address_2) => {
  if (address_1 && address_2) {
    return ADDRESS_FIELDS.every((key) => {
      return address_1[key] === address_2[key];
    });
  } else {
    return !(address_1 && address_2);
  }
};

export const isEmpty = (address) => {
  if (!address) return true;
  const stateIsEmpty = !(address.state && address.state.length);
  const cityIsEmpty = !(address.city && address.city.length);
  return stateIsEmpty || cityIsEmpty;
};
