import * as types from "../constants/actionTypes";
import {
  SHOW_POPUP,
  DISMISS_POPUP,
} from "app-shared/containers/Popup/constants/actionTypes";
import objectAssign from "object-assign";
import { formatDateForClient } from "utils/date";
import { LOAD_FROM_CACHE } from "../../../../modules/local-cache/actions";

const defaultPopup = {
  show: false,
  text: "",
  yesAction: null,
  noAction: null,
  dismissAction: null,
};

const defaultUi = {
  disabled: false,
  treatment_plans: [],
  submittingStatus: null,
  searchingPatient: false,
  searchingDoctor: false,
  loadingOrder: false,
  patients: [],
  maxPatients: 0,
  doctors: [],
  maxDoctors: 0,
  products: [],
  maxProducts: 0,
  alertMessage: null,
  popup: defaultPopup,
  dispensersOptions: [],
  carrierOptions: [],
};

const uiReducer = (state = defaultUi, action) => {
  switch (action.type) {
    case types.NEW_ORDER_FETCH_ORDER_REQUEST:
      return objectAssign({}, state, { loadingOrder: true });
    case types.NEW_ORDER_FETCH_ORDER_FAILURE:
    case types.NEW_ORDER_FETCH_ORDER_SUCCESS:
      return objectAssign({}, state, { loadingOrder: false });
    case types.NEW_ORDER_FAILURE:
      return objectAssign({}, state, { alertMessage: action.message });
    case types.NEW_ORDER_CLOSE_ALERT:
      return objectAssign({}, state, { alertMessage: null });
    case types.NEW_ORDER_TEMPLATES_SUCCESS:
      return objectAssign({}, state, {
        treatment_plans: action.treatment_plans.concat(),
      });
    case types.SUBMIT_ORDER_REQUEST:
      return objectAssign({}, state, { submittingStatus: "creating" });
    case types.SUBMIT_ORDER_PROCESSING_PAYMENT:
      return objectAssign({}, state, { submittingStatus: "processingPayment" });
    case types.SUBMIT_ORDER_PAYMENT_SUCCESSFUL:
      return objectAssign({}, state, { submittingStatus: "paymentSuccessful" });
    case types.SUBMIT_ORDER_NOTIFICATION_SENT:
      return objectAssign({}, state, { submittingStatus: "notificationSent" });
    case types.NEW_ORDER_FLOW_RESET:
      return defaultUi;
    case types.NEW_ORDER_FETCH_PATIENT_SUCCESS: {
      if (!state.patients.length) {
        const { _id, patient_name, date_of_birth } = action.patient;
        return objectAssign({}, state, {
          patients: [
            {
              id: _id,
              text: `${patient_name} (${formatDateForClient(date_of_birth)})`,
            },
          ],
        });
      }
      return state;
    }
    case types.SEARCH_PATIENT_REQUEST:
      return objectAssign({}, state, { searchingPatient: true });
    case types.SEARCH_PATIENT_SUCCESS: {
      const patients = action.results.map((p) => ({
        id: p._id,
        text: `${p.first_name} ${p.last_name} (${formatDateForClient(
          p.date_of_birth
        )})`,
      }));

      return objectAssign({}, state, {
        searchingPatient: false,
        patients,
        maxPatients: action.count,
      });
    }
    case types.SEARCH_DOCTOR_REQUEST:
      return objectAssign({}, state, { searchingDoctor: true });
    case types.SEARCH_DOCTOR_SUCCESS: {
      const doctors = action.results.map((d) => ({
        id: d._id,
        text: `${d.first_name} ${d.last_name}`,
      }));
      return objectAssign({}, state, {
        searchingDoctor: false,
        doctors,
        maxDoctors: action.count,
      });
    }
    case types.SEARCH_PRODUCT_REQUEST:
      return objectAssign({}, state, { searchingProduct: true });
    case types.SEARCH_PRODUCT_SUCCESS: {
      const products = action.results.map((p) => ({
        id: p._id,
        text: p.name,
      }));
      return objectAssign({}, state, {
        searchingProduct: false,
        products,
        maxProducts: action.count,
      });
    }
    case types.FETCH_DISPENSERS_REQUEST:
      return objectAssign({}, state, { dispensersOptions: [] });
    case types.FETCH_DISPENSERS_SUCCESS:
      return objectAssign({}, state, {
        dispensersOptions: action.dispensers.map((d) => ({
          value: d._id,
          label: d.name,
        })),
      });
    case types.FETCH_CARRIERS_REQUEST:
      return objectAssign({}, state, { carrierOptions: [] });
    case types.FETCH_CARRIERS_SUCCESS:
      return objectAssign({}, state, { carrierOptions: action.carriers });
    case SHOW_POPUP:
      return objectAssign({}, state, {
        popup: {
          show: true,
          text: action.text,
          yesAction: action.yesAction,
          noAction: action.noAction,
          dismissAction: action.dismissAction,
        },
      });
    case DISMISS_POPUP:
      return objectAssign({}, state, {
        popup: defaultPopup,
        submittingStatus: null,
      });
    case LOAD_FROM_CACHE:
      return action.state.ui;
    default:
      return state;
  }
};

export default uiReducer;
