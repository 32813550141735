export const SAVE_CURRENT_TEMPLATE_REQUEST = "SAVE_CURRENT_TEMPLATE_REQUEST";
export const SAVE_CURRENT_TEMPLATE_SUCCESS = "SAVE_CURRENT_TEMPLATE_SUCCESS";
export const SAVE_CURRENT_TEMPLATE_FAILURE = "SAVE_CURRENT_TEMPLATE_FAILURE";

export const FETCH_ONE_TEMPLATE_REQUEST = "FETCH_ONE_TEMPLATE_REQUEST";
export const FETCH_ONE_TEMPLATE_SUCCESS = "FETCH_ONE_TEMPLATE_SUCCESS";
export const FETCH_ONE_TEMPLATE_FAILURE = "FETCH_ONE_TEMPLATE_FAILURE";

export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const RESET_TEMPLATE_FORM = "RESET_TEMPLATE_FORM";
