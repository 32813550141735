import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";

import {
  addGroup,
  removeGroup,
  addProductToBox,
  removeProductFromBox,
  updateProductInBox,
} from "utils/template-helpers";

const defaultTemplate = {
  groups: [],
};

const reducerMap = {
  [types.FETCH_ONE_SUBSCRIPTION_SUCCESS]: (_, { subscription }) =>
    objectAssign({}, { groups: subscription.groups }),
  [types.NEW_SUBSCRIPTION_ADD_BOX]: addGroup,
  [types.NEW_SUBSCRIPTION_REMOVE_BOX]: removeGroup,
  [types.NEW_SUBSCRIPTION_ADD_PRODUCT_TO_BOX_SUCCESS]: addProductToBox,
  [types.NEW_SUBSCRIPTION_REMOVE_PRODUCT_FROM_BOX]: removeProductFromBox,
  [types.NEW_SUBSCRIPTION_UPDATE_PRODUCT_IN_BOX]: updateProductInBox,
  [types.RESET_SUBSCRIPTION]: () => defaultTemplate,
};

const templateReducer = (state = defaultTemplate, action) => {
  const reducer = reducerMap[action.type];

  if (reducer) {
    return reducer(state, action);
  }

  return state;
};

export default templateReducer;
