import { takeLatest, put } from "redux-saga/effects";
import { SHOW_POPUP_REQUEST } from "shared/containers/Popup/constants/actionTypes";
import { showPopup } from "app-shared/containers/Popup/actions";

function* displayPopup(action) {
  yield put(showPopup(action.children));
}

function* popupSagas() {
  yield takeLatest(SHOW_POPUP_REQUEST, displayPopup);
}

export default popupSagas;
