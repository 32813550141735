import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";

const defaultState = {
  dispenser_users: [],
  admin: { first_name: "", last_name: "", email: "", phone: "" },
};

const masterAdminFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_USERS_IN_ADMIN_SUCCESS: {
      return objectAssign({}, state, { dispenser_users: action.users });
    }
    default:
      return state;
  }
};

export default masterAdminFormReducer;
