import * as types from "../constants/actionTypes";

const prescriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_PRESCRIPTION_REQUEST:
      return null;
    case types.FETCH_PRESCRIPTION_SUCCESS: {
      return action.prescription;
    }
    default:
      return state;
  }
};

export default prescriptionReducer;
