import React from "react";
import PropTypes from "prop-types";
import DefaultCheckbox from "./DefaultCheckbox";

const FormikInput = ({
  field,
  form: { errors, touched, setFieldValue },
  ...restProps
}) => (
  <DefaultCheckbox
    error={touched[field.name] && errors[field.name]}
    checked={field.value}
    {...field}
    {...restProps}
    onChange={(value) => {
      setFieldValue(field.name, value);
    }}
  />
);

FormikInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

export default FormikInput;
