import React from "react";
import PropTypes from "prop-types";
import * as Styles from "./styles";

const Error = ({ error, style }) => {
  return (
    <Styles.Wrapper style={style}>
      {error || "Couldn't load data"}
    </Styles.Wrapper>
  );
};

Error.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
};

export default Error;
