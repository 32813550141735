import * as types from "../constants/actionTypes";
import { isValidURL } from "../constants/validations";
import { assignProduct } from "utils/product-helpers";
import { getPortal } from "utils/auth-helpers";
import { defaultOrder } from "constants/defaultModels";
import objectAssign from "object-assign";
import { ONE_TIME, SUBSCRIPTION } from "constants/orders/order_types";
import { LOAD_FROM_CACHE } from "../../../../modules/local-cache/actions";
import { LOCATION_CHANGE } from "connected-react-router";

export function getStateOnCarrierOptionsLoad(state, action) {
  const carrier_name = state.shipment_details?.carrier_name;
  const isCarrierNameCustom =
    carrier_name &&
    action.carriers.map((carrier) => carrier.label).indexOf(carrier_name) ===
      -1;

  return {
    ...state,
    shipment_details: {
      ...state.shipment_details,
      carrier_name: isCarrierNameCustom ? "Other" : carrier_name,
      other_carrier_name: isCarrierNameCustom ? carrier_name : "",
    },
  };
}

const orderReducer = (state = defaultOrder, action) => {
  switch (action.type) {
    case types.HANDLE_QUERY_NEW_ORDER: {
      const {
        query: { patient_id },
      } = action;
      return patient_id ? objectAssign({}, state, { patient_id }) : state;
    }
    case types.NEW_ORDER_FETCH_ORDER_SUCCESS:
      return action.order;
    case types.UPDATE_NEW_ORDER: {
      return objectAssign({}, state, action.updates);
    }

    case types.NEW_ORDER_USE_TEMPLATE_SUCCESS: {
      const { template, price, items } = action;
      let order;

      if (template.type === ONE_TIME) {
        order = objectAssign({}, state, {
          type: ONE_TIME,
          prescriber_notes: template.prescriber_notes,
        });
      } else {
        order = objectAssign({}, state, {
          type: SUBSCRIPTION,
          treatment__id: template._id,
          prescriber_notes: template.groups[0].prescriber_notes,
        });
      }

      return objectAssign({}, order, { price, items });
    }
    case types.NEW_ORDER_FETCH_PRODUCT_SUCCESS: {
      const productAlreadyAdded = state.items.find(
        (p) => p.product_id === action.product.product_id
      );

      if (!productAlreadyAdded) {
        const items = state.items.concat(assignProduct(action.product));
        return { ...state, items, price: action.price };
      } else {
        return state;
      }
    }
    case types.UPDATE_PRODUCT_IN_NEW_ORDER:
    case types.UPDATE_PRODUCT_PRICE_IN_NEW_ORDER:
    case types.REMOVE_PRODUCT_FROM_NEW_ORDER: {
      const { items, price } = action;
      return { ...state, items, price };
    }
    case types.FETCH_CARRIERS_SUCCESS:
      return getStateOnCarrierOptionsLoad(state, action);
    case types.NEW_ORDER_SUCCESS:
      return objectAssign({}, state, action.updates);
    case types.NEW_ORDER_FLOW_RESET:
      return defaultOrder;
    case LOCATION_CHANGE: {
      const { pathname } = action.payload.location;
      if (
        !pathname.includes("/orders/") &&
        !isValidURL(pathname?.replace(`/${getPortal()}/`, "") || "")
      ) {
        return defaultOrder;
      }
      return state;
    }
    case LOAD_FROM_CACHE:
      return action.state.order;
    default:
      return state;
  }
};

export default orderReducer;
