import * as types from "../constants/actionTypes";

export default (state = [], action) => {
  switch (action.type) {
    case types.FETCH_ORDER_STATUS_HISTORY_SUCCESS: {
      return action.payload;
    }
    case types.RESET_ORDER_STATUS_HISTORY: {
      return [];
    }
    default:
      return state;
  }
};
