import { getRequest, patchRequest, postRequest } from "../base";
import { getMultiple } from "./products";
import { prepareToServerFormat } from "../patient-helpers";
import objectAssign from "object-assign";
import { getUser } from "utils/auth-helpers";
import { prepare } from "../product-helpers";
import { fromServerToClient } from "../order-helpers";

import { practiceEndpoint as portalEndpoint } from "../path-helpers";
import commonApiServices from "../shared";
import { PRACTICE } from "constants/portalTypes";

export const update = (user) => {
  return patchRequest(portalEndpoint(`doctors/${user.practice_id}`), user);
};

export const create = (user) => {
  return postRequest(portalEndpoint(`doctors`), user);
};

export const submit = (params) => {
  const user = getUser();
  const practice_id = user.practice_id;

  return postRequest(
    "doctors",
    prepareToServerFormat(params, practice_id, practice_id)
  );
};

export const getAllOrders = (doctor_id) => {
  return getRequest(portalEndpoint(`doctors/${doctor_id}/orders`)).then(
    ({ orders }) => ({
      orders: orders.map((order) => fromServerToClient(order.order_id, order)),
    })
  );
};

export const getOneOrder = (doctor_id, order_id) => {
  return getRequest(portalEndpoint(`doctors/${doctor_id}/orders`))
    .then(({ orders }) => orders.find((o) => o.order_id === order_id))
    .then((order) => {
      const ids = order.items.map((c) => c.product_id);
      return getMultiple(ids).then((products) => {
        const items = order.items.map((item) => {
          const product = products.find(
            (p) => p.product_id === item.product_id
          );
          return objectAssign({}, product, prepare(item));
        });
        return objectAssign({}, order, { items });
      });
    });
};

export const { getOne, getAll, search } = commonApiServices(
  PRACTICE,
  "doctors"
);
