import * as types from "../constants/actionTypes";
import { removeById } from "utils/state-helpers";
import { updateArrayItemById } from "utils/product-helpers";
import { prepareOrganization } from "../../DispensersPage/reducer/dispensersReducer";

const practicesReducer = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_PRACTICES_IN_ADMIN_SUCCESS: {
      return action.practices.map((p) => {
        const practice = prepareOrganization(p);
        delete practice.type;

        return practice;
      });
    }
    case types.SUBMIT_PRACTICE_IN_ADMIN_REQUEST:
    case types.SUBMIT_PRACTICE_IN_ADMIN_SUCCESS:
    case types.UPDATE_PRACTICE_IN_ADMIN:
      return updateArrayItemById(
        state,
        action.practice_id,
        action.updates,
        "practice_id"
      );
    case types.DELETE_PRACTICE_IN_ADMIN_SUCCESS:
      return removeById(state, action.practice_id, "practice_id");
    case types.FETCH_PRACTICES_IN_ADMIN_REQUEST:
      return [];
    default:
      return state;
  }
};

export default practicesReducer;
