export const VISA = "visa";
export const MASTERCARD = "mastercard";
export const AMERICAN_EXPRESS = "americanexpress";
export const AMEX = "amex";
export const DISCOVER = "discover";

export const AMEX_ALIASES = [AMERICAN_EXPRESS, AMEX];
export const ALL_TYPES = [VISA, MASTERCARD, AMERICAN_EXPRESS, DISCOVER];

export const typeByNumber = (number) => {
  switch (number && number[0]) {
    case "3":
      return AMERICAN_EXPRESS;
    case "4":
      return VISA;
    case "5":
      return MASTERCARD;
    case "6":
      return DISCOVER;
    default:
      return null;
  }
};

export const cvvMaskByType = (type) =>
  AMEX_ALIASES.includes(type) ? "0000" : "000";

export const numberMaskByType = (type) => {
  return AMEX_ALIASES.includes(type)
    ? "0000 000000 00000"
    : "0000 0000 0000 0000";
};

export const getLast4 = (number) => {
  const splitted = number.split(" ");
  return splitted[splitted.length - 1];
};

export const parseCard = (card = {}) => {
  const [expiry_month, expiry_year] = card.expiration_date
    ? card.expiration_date.split("/")
    : [null, null];

  return {
    name: card.name,
    number: card.number || "",
    expiry_month: card.expiry_month || expiry_month,
    expiry_year: card.expiry_year || (expiry_year && `20${expiry_year}`) || "",
    cvv: card.cvv || "",
    last_4: card.last_4 || "",
  };
};

export const showCardDetails = (card, patient_card = {}) => {
  const { last_4: patient_card_last_4 } = patient_card;
  const { cvv } = card;

  if (cvv) {
    return true;
  } else if (!cvv && !patient_card_last_4) {
    return true;
  } else if (!cvv && patient_card_last_4) {
    return false;
  }
};
