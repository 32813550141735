import { patchRequest } from "../base";

import { dispenserEndpoint as portalEndpoint } from "../path-helpers";
import commonApiServices from "../shared";
import { DISPENSER } from "constants/portalTypes";

export const submit = (product) => {
  const { _id, ...updates } = product;
  return patchRequest(portalEndpoint(`products/${_id}`), updates);
};

export const {
  getFilters,
  getAll,
  getOne,
  getMultiple,
  searchActive,
} = commonApiServices(DISPENSER, "products");
