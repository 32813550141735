import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";

const defaultState = {
  email: "",
  password: "",
  password_confirmation: "",
  errors: {},
  success: false,
};

const resetPasswordReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SUBMIT_RESET_PASSWORD_SUCCESS:
      return objectAssign({}, defaultState, { success: true });
    case types.CHECK_TOKEN_REQUEST:
      return defaultState;
    case types.CHECK_TOKEN_SUCCESS:
      return objectAssign({}, state, action.json);
    case types.UPDATE_RESET_PASSWORD:
      return objectAssign({}, state, action.updates, { errors: {} });
    case types.SUBMIT_RESET_PASSWORD_FAILURE:
      return objectAssign({}, state, { errors: action.errors });
    default:
      return state;
  }
};

export default resetPasswordReducer;
