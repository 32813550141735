export const UPDATE_PRODUCT = "UPDATE_PRODUCT_IN_DISPENSER";

export const SUBMIT_PRODUCT_REQUEST = "SUBMIT_PRODUCT_IN_DISPENSER_REQUEST";
export const SUBMIT_PRODUCT_SUCCESS = "SUBMIT_PRODUCT_IN_DISPENSER_SUCCESS";
export const SUBMIT_PRODUCT_FAILURE = "SUBMIT_PRODUCT_IN_DISPENSER_FAILURE";

export const SEARCH_PRODUCTS_REQUEST = "SEARCH_PRODUCTS_REQUEST";
export const SEARCH_PRODUCTS_SUCCESS = "SEARCH_PRODUCTS_SUCCESS";

export const FETCH_SINGLE_PRODUCT_REQUEST =
  "FETCH_SINGLE_PRODUCT_IN_DISPENSER_REQUEST";
export const FETCH_SINGLE_PRODUCT_SUCCESS =
  "FETCH_SINGLE_PRODUCT_IN_DISPENSER_SUCCESS";
export const FETCH_SINGLE_PRODUCT_FAILURE =
  "FETCH_SINGLE_PRODUCT_IN_DISPENSER_FAILURE";

export const TOGGLE_SHOW_EDIT_PRODUCT = "TOGGLE_SHOW_EDIT_PRODUCT_IN_DISPENSER";
export const TOGGLE_SHOW_PRICING_CHANGE_WARNING =
  "TOGGLE_SHOW_PRICING_CHANGE_WARNING";
export const CLOSE_ALL_EDIT_FORMS = "CLOSE_ALL_EDIT_FORMS";
export const DISMISS_SUCCESSFUL_SUBMIT = "DISMISS_SUCCESSFUL_SUBMIT";
