import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";
import * as uiTexts from "../constants/uiTexts";

const defaultState = {
  loading: false,
  masterAdminText: uiTexts.masterAdminText,
  dispensers: {
    rx: [],
    retail: [],
    compound: [],
  },
};

const uiReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_ORGANIZATIONS_IN_MASTER_REQUEST:
      return objectAssign({}, state, { loading: true });
    case types.FETCH_ORGANIZATIONS_IN_MASTER_FAIL:
      return objectAssign({}, state, { loading: false });
    case types.FETCH_ORGANIZATIONS_IN_MASTER_SUCCESS: {
      const prepareSingleDispenser = (d) => ({
        value: d._id,
        label: d.name,
      });
      const preparedDispenser = action.dispensers.map(prepareSingleDispenser);

      return objectAssign({}, state, {
        dispensers: {
          rx: preparedDispenser,
          retail: preparedDispenser,
          compound: preparedDispenser,
        },
        loading: false,
      });
    }
    default:
      return state;
  }
};

export default uiReducer;
