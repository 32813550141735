import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Paper = ({ trigger, bgColor, children }) => {
  const [printed, togglePrinted] = useState(false);

  useEffect(() => {
    triggerPrint();
  }, [trigger]);

  function triggerPrint() {
    if (trigger && !printed) {
      window.print();
      togglePrinted(true);

      return false;
    }
  }

  return (
    <div
      className="Paper"
      style={bgColor ? { backgroundColor: bgColor } : null}
    >
      {children}
    </div>
  );
};

Paper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  trigger: PropTypes.bool,
  bgColor: PropTypes.string,
};

export default Paper;
