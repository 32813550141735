import { getRequest } from "../../base";
import { path } from "../../path-helpers";

export const getAll = (base) => ({ q, skip, limit, filters }) => {
  return getRequest(path(base, "insurance/payors"), {
    q,
    skip,
    limit,
    ...filters,
  }).then((json) => json);
};

const PayorsApi = (base) => {
  return {
    getAll: getAll(base),
  };
};

export default PayorsApi;
