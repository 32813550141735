import { STANDARD_SHIPPING } from "./orders/delivery_methods";
import { getUser } from "../utils/auth-helpers";

export const defaultOrder = {
  patient_id: "",
  doctor_id: "",
  practice_id: getUser().practice_id,
  items: [],
  price: {
    subtotal_price: 0,
    shipping_price: 0,
    tax: 0,
    total_price: 0,
  },
  delivery_method: STANDARD_SHIPPING,
  best_price: false,
  payment_program: "full",
  preauthorized_amount: null,
  prescriber_notes: null,
  surgery_date: null,
  dropcharts: [],
};

export const defaultOrderCompetedState = {
  response: null,
  header: "",
  description: "",
  success: false,
  order: null,
};
