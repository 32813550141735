import styled from "styled-components";

const text_color = "#0e2439";

export const MonthlyPaymentText = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

export const Questions = styled.div`
  margin: 8px 0;
  color: ${text_color};
  font-size: 16px;
`;

export const Contacts = styled.div`
  margin-bottom: 10px;
  font-weight: 600;
  color: ${text_color};
`;

export const Phone = styled.strong`
  font-size: 18px;
`;

export const BillWraper = styled.div`
  margin-top: 10px;
`;

export const BillText = styled.div`
  font-size: 12px;
  margin-top: 5px;
`;

export const MonthlyPayment = styled.div`
  margin: 10px 0;
  color: ${text_color};
`;
