import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { SUBSCRIPTION, ONE_TIME } from "constants/orders/order_types";
import Bill from "../PrintTemplates/Order/Bill";
import {
  MonthlyPayment,
  BillText,
  MonthlyPaymentText,
  BillWraper,
} from "./styles";

const CustomBill = (props) => {
  const {
    subtotal_price,
    tax,
    shipping_price,
    total_price,
    payment_program,
    transaction,
    type,
  } = props;

  const monthlyPayment = transaction.length ? transaction[0].amount : null;

  return (
    <BillWraper>
      <Col md={6}>
        {type === "subscription" && (
          <div>
            <MonthlyPaymentText>
              <img src="/assets/refresh-icon.svg" />
              <strong>Treatment Plan:</strong>
            </MonthlyPaymentText>
            {payment_program === "monthly" ? (
              <MonthlyPayment>
                Your monthly payment:
                <strong style={{ fontSize: 14 }}>
                  {` $ ${monthlyPayment}` || ""}
                </strong>
              </MonthlyPayment>
            ) : null}
            <BillText>
              You may adjust your shipment details, products or cancel
              <br />
              anytime. To make any changes to your treatment plan
              <br />
              please call 855 276-8555
              <br />
              <br />
              Your personalized treatment will be delivered to your door
              <br />
              conveniently every three months with an affordable
              <br />
              monthly payment option for qualifying orders
            </BillText>
          </div>
        )}
      </Col>
      <Col md={6}>
        <Bill
          shipping_price={shipping_price}
          subtotal_price={subtotal_price}
          tax={tax}
          total_price={total_price}
        />
      </Col>
    </BillWraper>
  );
};

const priceProp = PropTypes.oneOf([PropTypes.string, PropTypes.number]);

CustomBill.propTypes = {
  subtotal_price: priceProp,
  tax: priceProp,
  shipping_price: priceProp,
  total_price: priceProp,
  transaction: PropTypes.array,
  payment_program: PropTypes.oneOf(["full", "monthly"]),
  type: PropTypes.oneOf([SUBSCRIPTION, ONE_TIME]),
};

export default CustomBill;
