import { logout } from "api/users";
import { put, call } from "redux-saga/effects";
import { NEW_ORDER_FLOW_RESET } from "constants/actionTypes";
import { FINISH_PATIENT_FLOW } from "modules/patientData/actionTypes";
import { getPortal, clearUser } from "utils/auth-helpers";
import { push } from "connected-react-router";

export const redirectToHomePage = () => (dispatch) => dispatch(push("/"));

export const redirectToLogin = (portal) => (dispatch) => {
  dispatch(push(`/login/${portal}`));
};

export const resetForms = () => {
  return (dispatch) => {
    dispatch({ type: NEW_ORDER_FLOW_RESET });
    dispatch({ type: FINISH_PATIENT_FLOW });
  };
};

export const signOut = async () => {
  try {
    await logout();
  } catch {
    /* eslint-disable-next-line */
  } finally {
    clearUser();
  }
};

export function* quitPortal() {
  const portal = getPortal();
  yield call(signOut);
  yield put(redirectToLogin(portal));
}
