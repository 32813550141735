import React from "react";
import PropTypes from "prop-types";
import Edit from "shared/components/EditButton";
import { isStdDispenser } from "utils/auth-helpers";
import {
  getCanonicalStatus,
  isEditable as isOrderEditable,
} from "constants/orders/order_statuses";

const OrderDropchartsList = ({
  location: { pathname },
  dropcharts,
  onPrintDocuments,
  onClickEdit,
  order_status,
  readOnly,
}) => {
  function handleSingleDocumentPrint(e, document) {
    e.preventDefault();
    e.stopPropagation();

    onPrintDocuments([document]);
  }

  const status = order_status || getCanonicalStatus(order_status);
  const isPrintable =
    dropcharts.length !== 0 && isStdDispenser() && !isOrderEditable(status);

  const isEditable = !isPrintable && isOrderEditable(status) && !readOnly;

  const buttonSign = dropcharts?.length ? "Edit" : "Add";

  const isNameClickable = !/orders\/new$/.test(pathname);

  return (
    <section>
      <strong>{"Documents "}</strong>
      {isPrintable && (
        <Edit
          float="none"
          onClick={() => onPrintDocuments(dropcharts)}
          data-test="print-all"
        >
          Open All
        </Edit>
      )}
      {isEditable && (
        <Edit float="none" onClick={onClickEdit} data-test="edit-document">
          {buttonSign}
        </Edit>
      )}
      <p>
        <span style={{ display: "flex" }}>
          {dropcharts?.map((document, index) => {
            const name =
              document.name + `${index + 1 === dropcharts.length ? "" : ", "}`;

            return isNameClickable ? (
              <Edit
                key={document._id}
                data-test="dropchart-document"
                float="left"
                style={{ paddingRight: "4px" }}
                onClick={(e) => handleSingleDocumentPrint(e, document)}
              >
                {name}
              </Edit>
            ) : (
              <span key={document._id}>{name}</span>
            );
          })}
        </span>
      </p>
    </section>
  );
};

OrderDropchartsList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  dropcharts: PropTypes.array.isRequired,
  onPrintDocuments: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func,
  order_status: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default OrderDropchartsList;
