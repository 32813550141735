import "whatwg-fetch";
import objectAssign from "object-assign";
import { getJWT } from "utils/auth-helpers";
import apiFailhandler from "./apiFailhandler";
import * as API_MESSAGES from "constants/api/apiErrorMessages";
import { fromJsonToQueryString } from "./_helpers/queryStringHelpers";

export const getRequest = (path, query = {}, headers = {}) => {
  return get(path, query, headers)
    .then((response) => handleSafeResponse(response))
    .catch((err) => handleErrorResponse(err, path));
};

export const pureGetRequest = (path, query = {}, headers = {}) => {
  return pureGet(path, query, headers)
    .then((response) => handleSafeResponse(response))
    .catch((err) => handleErrorResponse(err, path));
};

export const postRequest = (path, params = {}, headers = {}) => {
  return post(path, params, headers)
    .then((response) => handleSafeResponse(response))
    .catch((err) => handleErrorResponse(err, path));
};

export const purePostRequest = (path, params = {}, headers = {}) => {
  return purePost(path, params, headers)
    .then((response) => handleSafeResponse(response))
    .catch((err) => handleErrorResponse(err, path));
};

export const purePatchRequest = (path, params = {}, headers = {}) => {
  return purePatch(path, params, headers)
    .then((response) => handleSafeResponse(response))
    .catch((err) => handleErrorResponse(err, path));
};

export const patchRequest = (path, params = {}, headers = {}) => {
  return patch(path, params, headers)
    .then((response) => handleSafeResponse(response))
    .catch((err) => handleErrorResponse(err, path));
};

export const purePutRequest = (path, params = {}, headers = {}) => {
  return purePut(path, params, headers)
    .then((response) => handleSafeResponse(response))
    .catch((err) => handleErrorResponse(err, path));
};

export const putRequest = (path, params = {}, headers = {}) => {
  return put(path, params, headers)
    .then((response) => handleSafeResponse(response))
    .catch((err) => handleErrorResponse(err, path));
};

export const deleteRequest = (path, params = {}, headers = {}) => {
  return pureDelete(path, params, headers)
    .then((response) => handleSafeResponse(response))
    .catch((err) => handleErrorResponse(err, path));
};

const get = (path, query, headers) => {
  return pureGet(path, query, {
    ...headers,
    Authorization: `bearer ${getJWT()}`,
  });
};

const pureGet = (path, query, headers) => {
  return fetch(apiUrl(path, query), {
    method: "GET",
    headers: new Headers(
      objectAssign(
        {},
        {
          "content-type": "application/json",
        },
        headers
      )
    ),
  });
};

const post = (path, params, headers) => {
  return purePost(path, params, {
    ...headers,
    authorization: `bearer ${getJWT()}`,
  });
};

const purePost = (path, params, headers) => {
  return fetch(apiUrl(path), {
    method: "POST",
    headers: objectAssign(
      {
        "content-type": "application/json",
      },
      headers
    ),
    body: JSON.stringify(params),
  });
};

const patch = (path, params, headers) => {
  return purePatch(path, params, {
    ...headers,
    authorization: `bearer ${getJWT()}`,
  });
};

const put = (path, params, headers) => {
  return purePut(path, params, {
    ...headers,
    authorization: `bearer ${getJWT()}`,
  });
};

const purePatch = (path, params, headers) => {
  return fetch(apiUrl(path), {
    method: "PATCH",
    headers: objectAssign(
      {
        "content-type": "application/json",
      },
      headers
    ),
    body: JSON.stringify(params),
  });
};

const purePut = (path, params, headers) => {
  return fetch(apiUrl(path), {
    method: "PUT",
    headers: objectAssign(
      {
        "content-type": "application/json",
      },
      headers
    ),
    body: JSON.stringify(params),
  });
};

const pureDelete = (path, params, headers) => {
  return fetch(apiUrl(path), {
    method: "DELETE",
    headers: objectAssign(
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJWT()}`,
      },
      headers
    ),
    body: JSON.stringify(params),
  });
};

const handleSafeResponse = (response) => {
  return new Promise((resolve, reject) => {
    if (response.status >= 200 && response.status < 300) {
      response.json().then(
        (json) => {
          resolve(json);
        },
        () => resolve(null)
      );
    } else {
      try {
        response
          .json()
          .then((json) => reject({ status: response.status, response: json }));
      } catch {
        reject({ status: response.status, response });
      }
    }
  });
};

export const extractErrorMessage = (error) => {
  const responseMessage =
    error.response?.errors?.errors?.[0]?.message ||
    error.response?.errors?.[0] ||
    error.response?.message ||
    error.response?.error;

  const defaultMessage = API_MESSAGES.errorMessagesWithStatuses[error.status];

  return responseMessage || defaultMessage;
};

const handleErrorResponse = (error, path) => {
  const errorResponse = extractErrorMessage(error);
  const errorInfo = {
    status: error.status || 403,
    response: errorResponse,
    requestPath: path,
  };

  apiFailhandler(errorInfo);

  return Promise.reject(new Error(errorResponse));
};

const apiUrl = (path, query = {}) => {
  return `${process.env.API_URL}/api/${path}/${fromJsonToQueryString(query)}`;
};
