import React from "react";
import PropTypes from "prop-types";

const Link = ({ to, children, style }) => (
  <a
    style={{ textDecoration: "none", ...style }}
    href={`${to}`}
    rel="noopener noreferrer"
    target="_blank"
  >
    {children}
  </a>
);

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  style: PropTypes.object,
};

export default Link;
