import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";

const defaultState = {
  loading: false,
  items: [],
  error: null,
};

const ordersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_PATIENT_ORDERS_REQUEST:
      return objectAssign({}, { loading: true, error: null, items: [] });
    case types.FETCH_PATIENT_ORDERS_FAILURE:
      return objectAssign(
        {},
        { loading: false, error: action.error, items: [] }
      );
    case types.FETCH_PATIENT_ORDERS_SUCCESS:
      return objectAssign(
        {},
        {
          loading: false,
          error: null,
          items: action.orders.map((o) => objectAssign({}, o)),
        }
      );
    default:
      return state;
  }
};

export default ordersReducer;
