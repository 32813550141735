import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import OrderConfirmation from "./containers/OrderConfirmation";
import { SingleDropchartDocumentPrint } from "shared/components/Dropcharts";

class PracticePrint extends React.Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route
          path={`${match.url}/order_confirmation/:order_id`}
          component={OrderConfirmation}
        />
        <Route
          exact
          path={`${match.url}/dropcharts/:dropchart_id`}
          component={SingleDropchartDocumentPrint}
        />
      </Switch>
    );
  }
}

PracticePrint.propTypes = {
  match: PropTypes.object,
};

export default PracticePrint;
