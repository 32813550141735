import * as Dispensers from "./dispensers";
import * as DispenserDetails from "./details";
import * as DispenserDoctors from "./doctors";
import * as DispenserOrders from "./orders";
import * as DispenserPatients from "./patients";
import * as DispenserUsers from "./users";
import * as DispenserTemplates from "./treatment_plans";
import * as DispenserPayments from "./payments";
import * as DispenserProducts from "./products";
import * as DispenserSubscriptions from "./subscriptions";
import * as DispenserDropcharts from "./dropcharts";
import * as DispenserPayors from "./payors";

export {
  Dispensers,
  DispenserDetails,
  DispenserDoctors,
  DispenserOrders,
  DispenserPatients,
  DispenserUsers,
  DispenserTemplates,
  DispenserPayments,
  DispenserProducts,
  DispenserSubscriptions,
  DispenserDropcharts,
  DispenserPayors,
};

const DispenserApi = {
  dispensers: Dispensers,
  payments: DispenserPayments,
  details: DispenserDetails,
  doctors: DispenserDoctors,
  orders: DispenserOrders,
  patients: DispenserPatients,
  users: DispenserUsers,
  templates: DispenserTemplates,
  products: DispenserProducts,
  subscriptions: DispenserSubscriptions,
  dropcharts: DispenserDropcharts,
  payors: DispenserPayors,
};

export default DispenserApi;
