import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import FormikInput from "./FormikInput";

const ZipCodeInput = ({ label, ...props }) => (
  <Field
    component={FormikInput}
    label={label || "Zip Code *"}
    {...props}
    maxLength={5}
  />
);

ZipCodeInput.propTypes = {
  label: PropTypes.string,
};

export default ZipCodeInput;
