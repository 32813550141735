import React from "react";
import { Field } from "formik";
import FormikInput from "./FormikInput";

const DateOfBirthInput = (props) => (
  <Field
    component={FormikInput}
    label="DOB *"
    mask="00/00/0000"
    placeholder="MM/DD/YYYY"
    {...props}
  />
);

export default DateOfBirthInput;
