import objectAssign from "object-assign";

export const generateProps = (props, field, nestedField = null) => {
  return {
    value: nestedField ? props[field][nestedField] : props[field],
    errors: props.errors && props.errors[field],
    onChange: (value) =>
      props.update({
        [field]: nestedField
          ? objectAssign({}, props[field], { [nestedField]: value })
          : value,
      }),
  };
};

export const generateNextProps = (props, name) => ({
  name: name,
  value: props[name],
  error: props.errors && props.errors[name],
});

export const gridClass = (lg, md, sm, xs) => {
  const lgClass = lg ? `col-lg-${lg}` : null;
  const mdClass = md ? `col-md-${md}` : null;
  const smClass = sm ? `col-sm-${sm}` : null;
  const xsClass = xs ? `col-xs-${xs}` : null;

  return joinClassNames(lgClass, mdClass, smClass, xsClass);
};

export const joinClassNames = (...classNames) => {
  const result = classNames.filter((className) => Boolean(className)).join(" ");

  return result || null;
};
