import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import loginReducer from "shared/containers/LoginPage/reducer";
import passwordsReducer from "shared/containers/withUpdatePassword/reducer";

import currentUserReducer from "./currentUserReducer";

import masterReducer from "../app-master/reducers";
// import patientReducer from "../app-patient/reducers";
import dispenserReducers from "../app-dispenser/reducers";
import practiceReducers from "../app-practice/reducers";
import staticReducers from "../app-static/reducers";

import manageUsersReducer from "shared/containers/Users/reducer";
import patientSearchReducer from "shared/containers/PatientSearch/reducer";
import notificationsReducer from "shared/containers/Notifications/reducer";

import uniformContainersReducer from "./uniformReducers";

import productListReducer from "shared/containers/Formulary/reducers";

import {
  reducer as patientDataReducer,
  REDUCER_NAME as PATIENT_DATA_REDUCER_NAME,
} from "modules/patientData/reducer";

import orderListReducers from "shared/containers/OrderList/reducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),

    login: loginReducer,
    passwords: passwordsReducer,

    currentUser: currentUserReducer,

    // patient: patientReducer,
    master: masterReducer,
    dispenser: dispenserReducers,
    practice: practiceReducers,
    static: staticReducers,

    orderList: orderListReducers,
    products: productListReducer,
    search: patientSearchReducer,

    manageUsers: manageUsersReducer,

    notifications: notificationsReducer,

    uniform: uniformContainersReducer,

    [PATIENT_DATA_REDUCER_NAME]: patientDataReducer,
  });
