export const FETCH_DISPENSERS_IN_ADMIN_REQUEST =
  "FETCH_DISPENSERS_IN_ADMIN_REQUEST";
export const FETCH_DISPENSERS_IN_ADMIN_SUCCESS =
  "FETCH_DISPENSERS_IN_ADMIN_SUCCESS";
export const FETCH_DISPENSERS_IN_ADMIN_FAILURE =
  "FETCH_DISPENSERS_IN_ADMIN_FAILURE";

export const UPDATE_DISPENSER_IN_ADMIN = "UPDATE_DISPENSER_IN_ADMIN";

export const DELETE_DISPENSER_IN_ADMIN_REQUEST =
  "DELETE_DISPENSER_IN_ADMIN_REQUEST";
export const DELETE_DISPENSER_IN_ADMIN_SUCCESS =
  "DELETE_DISPENSER_IN_ADMIN_SUCCESS";
export const DELETE_DISPENSER_IN_ADMIN_FAILURE =
  "DELETE_DISPENSER_IN_ADMIN_FAILURE";

export const UPLOAD_DISPENSERS_IN_ADMIN_REQUEST =
  "UPLOAD_DISPENSERS_IN_ADMIN_REQUEST";
export const UPLOAD_DISPENSERS_IN_ADMIN_SUCCESS =
  "UPLOAD_DISPENSERS_IN_ADMIN_SUCCESS";
export const UPLOAD_DISPENSERS_IN_ADMIN_FAILURE =
  "UPLOAD_DISPENSERS_IN_ADMIN_FAILURE";

export const SUBMIT_DISPENSER_IN_ADMIN_REQUEST =
  "SUBMIT_DISPENSER_IN_ADMIN_REQUEST";
export const SUBMIT_DISPENSER_IN_ADMIN_SUCCESS =
  "SUBMIT_DISPENSER_IN_ADMIN_SUCCESS";
export const SUBMIT_DISPENSER_IN_ADMIN_FAILURE =
  "SUBMIT_DISPENSER_IN_ADMIN_FAILURE";

export const UPDATE_FILTER = "UPDATE_FILTER";
