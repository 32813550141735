import React from "react";
import PropTypes from "prop-types";
import { gridClass, joinClassNames } from "utils/component-helpers";

const baseLabelStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "1.5",
  letterSpacing: "normal",
  color: "#0e2439",
};

const BaseInputWithValidation = ({
  label,
  labelStyle,
  errors,
  error,
  className,
  children,
  normal,
  lg,
  md,
  sm,
  xs,
}) => {
  const formGroup = normal ? "form-group" : "form-group-lg";
  const errorText = error || (errors && errors.length > 0 && errors[0]);
  const classNames = joinClassNames(
    formGroup,
    errorText ? "has-error" : "",
    className,
    gridClass(lg, md, sm, xs)
  );

  return (
    <div className={classNames}>
      {label ? (
        <label
          style={{ ...baseLabelStyle, ...labelStyle }}
          className="control-label"
        >
          {label}
        </label>
      ) : null}
      {children}
      {errorText && <span className="help-block">{errorText}</span>}
    </div>
  );
};

BaseInputWithValidation.propTypes = {
  normal: PropTypes.bool,
  label: PropTypes.string,
  labelStyle: PropTypes.object,
  className: PropTypes.string,
  errors: PropTypes.array,
  error: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
};

export default BaseInputWithValidation;
