import React from "react";
import { Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import Loading from "shared/components/Loading";
import { getPortal, getUserType } from "utils/auth-helpers";

const Static = (params) => {
  const currentPortal = getPortal();
  const userType = getUserType();

  if (currentPortal && userType) {
    return <Redirect to={`/${currentPortal}`} />;
  } else {
    const StaticApp = Loadable({
      loader: () => import("./containers/App"),
      loading: Loading,
    });

    return <StaticApp {...params} />;
  }
};

export default Static;
