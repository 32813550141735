import * as types from "actions/actionTypes";

const defaultState = [];

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_TIMEZONES_REQUEST:
      return defaultState;
    case types.FETCH_TIMEZONES_SUCCESS: {
      return action.timezones.map((t) => ({
        value: t.timezone,
        label: `${t.timezone} ${t.description}`,
      }));
    }

    default:
      return state;
  }
};
