import commonApiServices from "../shared";
import { PRACTICE } from "constants/portalTypes";

export const {
  getAll,
  getOne,
  search,
  getAllOrders,
  submit,
} = commonApiServices(PRACTICE, "patients");
