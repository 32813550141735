import styled, { keyframes } from "styled-components";

const bounedelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 60px auto 60px;
  width: 80px;
  text-align: center;
`;

export const Bubble = styled.div`
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  animation: ${bounedelay} 1.4s infinite ease-in-out both;
  animation-delay: ${(props) => `${-0.32 + props.index * 0.16}s`};
`;
