import { push } from "connected-react-router";
import * as types from "./constants/actionTypes";
import { dismissPopupsOnDispenser } from "app-shared/containers/OrderReview/actions";
import { isDispenser } from "utils/auth-helpers";

export const showPopup = (text, options) => (dispatch) => {
  dispatch({
    type: types.SHOW_POPUP,
    children: text,
    isYesActionExists: options?.isYesActionExists,
    apiErrorStatus: options?.apiErrorStatus,
  });
};

export const dismissAction = () => (dispatch, getState) => {
  const {
    router: {
      location: { pathname },
    },
    uniform: { popup },
  } = getState();

  const isOrderProcessOnDispenser =
    (pathname.includes("review") ||
      (pathname.includes("edit") && !pathname.includes("subscriptions"))) &&
    isDispenser();

  if (isOrderProcessOnDispenser && !popup.apiErrorStatus) {
    dispatch(dismissPopupsOnDispenser());
  }
  dispatch({ type: types.DISMISS_POPUP });
};

export const navigateToRouteAndShowPopup = (path, text) => (dispatch) => {
  dispatch(push(path));
  dispatch({ type: types.SHOW_POPUP_REQUEST, children: text });
};
