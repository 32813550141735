import {
  LOGIN_SUCCESS,
  UPDATE_USER_INFO,
} from "app-shared/containers/LoginPage/constants/actionTypes";
import { SUBMIT_DISPENSER_SETTINGS_SUCCESS } from "../app-dispenser/containers/Settings/constants/actionTypes";
import { SUBMIT_PRACTICE_SETTINGS_SUCCESS } from "../app-practice/containers/Settings/constants/actionTypes";
import { SUBMIT_PATIENT_SETTINGS_SUCCESS } from "../app-patient/containers/Settings/constants/actionTypes";
import { FETCH_SETTINGS_SUCCESS } from "../constants/actionTypes";
import objectAssign from "object-assign";
import { getUser } from "utils/auth-helpers";

const defaultCurrentUser = getUser();

const currentUserReducer = (state = defaultCurrentUser, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return objectAssign({}, state, action.user);
    case SUBMIT_DISPENSER_SETTINGS_SUCCESS:
    case SUBMIT_PRACTICE_SETTINGS_SUCCESS:
    case SUBMIT_PATIENT_SETTINGS_SUCCESS: {
      if (action.settings) {
        const {
          settings: { first_name, last_name, role },
        } = action;
        return objectAssign({}, state, { first_name, last_name, role });
      } else {
        return state;
      }
    }
    case FETCH_SETTINGS_SUCCESS:
    case UPDATE_USER_INFO:
      return objectAssign({}, state, action.updates);
    default:
      return state;
  }
};

export default currentUserReducer;
