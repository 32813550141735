import { combineReducers } from "redux";

import subscriptionReducer from "./subscriptionReducer";
import subscriptionDetailsReducer from "./subscriptionDetailsReducer";
import templateReducer from "./templateReducer";
import uiReducer from "./uiReducer";

export default combineReducers({
  subscription: subscriptionReducer,
  details: subscriptionDetailsReducer,
  template: templateReducer,
  ui: uiReducer,
});
