import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";

const defaultState = {
  loading: false,
  items: [],
  error: null,
};

const subscriptionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_PATIENT_SUBSCRIPTION_REQUEST:
      return objectAssign({}, { loading: true, error: null, items: [] });
    case types.FETCH_PATIENT_SUBSCRIPTION_FAILURE:
      return objectAssign(
        {},
        { loading: false, error: "Failed to fetch subscriptions", items: [] }
      );
    case types.FETCH_PATIENT_SUBSCRIPTION_SUCCESS:
      return objectAssign(
        {},
        {
          loading: false,
          error: null,
          items: action.subscriptions.map((o) => objectAssign({}, o)),
        }
      );
    default:
      return state;
  }
};

export default subscriptionReducer;
