import objectAssign from "object-assign";
import { assignProduct } from "./product-helpers";

export const updateState = (state, updates) => {
  let changes = {};
  for (let key in updates) {
    changes[key] = { value: updates[key] };
  }
  return objectAssign({}, state, changes);
};

const simpleAssign = (object, updates) => objectAssign({}, object, updates);

export const updateByIndex = (
  collection,
  index,
  updates,
  updateFunc = simpleAssign
) => {
  if (index + 1) {
    return [
      ...collection.slice(0, index),
      ...[updateFunc(collection[index], updates)],
      ...collection.slice(index + 1),
    ];
  } else {
    return collection;
  }
};

export const updateById = (
  collection,
  id,
  updates,
  key = "_id",
  updateFunc = simpleAssign
) =>
  updateByIndex(
    collection,
    collection.findIndex((e) => e.id === id || e[key] === id),
    updates,
    updateFunc
  );

export const removeById = (collection, id, key = "_id") =>
  removeByIndex(
    collection,
    collection.findIndex((item) => item.id === id || item[key] === id)
  );

export const removeByIndex = (collection, index) => {
  if (index === -1) {
    return collection;
  } else {
    return [...collection.slice(0, index), ...collection.slice(index + 1)];
  }
};

// Helpers for filters

export const pageOf = (state, currentPage, count, perPage = 10) => {
  const pages = Math.ceil(count / perPage);
  return objectAssign({}, state, { currentPage, pages });
};

export const fetchedState = (state, action) => {
  const products = action.products.map((p) => assignProduct(p));

  return pageOf(
    objectAssign({}, state, {
      products,
      brands: action.brands,
      categories: action.categories,
    }),
    action.currentPage,
    action.count
  );
};
