import { omit } from "lodash";
import objectAssign from "object-assign";
import { dispensersGetRequest, dispensersPatchRequest } from "./base";

export const getOne = (dispenser_id) => {
  return dispensersGetRequest(`dispensers/${dispenser_id}`).then((dispenser) =>
    objectAssign({}, dispenser, { dispenser_id })
  );
};

export const getAll = ({ q, skip, limit, filters }) => {
  return dispensersGetRequest("dispensers", {
    q,
    skip,
    limit,
    ...filters,
  }).then((json) => json);
};

export const submit = (dispenser) => {
  const requestBody = omit(dispenser, ["_id"]);
  return dispensersPatchRequest(`dispensers/${dispenser._id}`, requestBody);
};

export const fetchCarriers = () => {
  return dispensersGetRequest(`dispensers/carriers`);
};

export const getTimezones = () => dispensersGetRequest("dispensers/list_tz");
