import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";

const defaultState = {
  email: "",
  password: "",
  password_confirmation: "",
  errors: {},
  popup: false,
};

const confirmReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SUBMIT_CONFIRM_SUCCESS:
      return objectAssign({}, state, { popup: true });
    case types.CHECK_TOKEN_REQUEST:
      return defaultState;
    case types.CHECK_TOKEN_SUCCESS:
      return objectAssign({}, state, action.json);
    case types.UPDATE_CONFIRM:
      return objectAssign({}, state, action.updates, { errors: {} });
    case types.CHECK_TOKEN_FAILURE:
    case types.SUBMIT_CONFIRM_FAILURE:
      return objectAssign({}, state, { errors: action.errors });
    default:
      return state;
  }
};

export default confirmReducer;
