import { takeLatest, delay } from "redux-saga/effects";
import {
  isAuth,
  setTimestamp,
  getTimestamp,
  getTimeout,
} from "utils/auth-helpers";
import { quitPortal } from "actions/globalActions";

function* logoutTimer() {
  if (isAuth()) {
    const timestamp = new Date().getTime();
    const timeout = getTimeout();

    setTimestamp(timestamp);

    yield delay(timeout);

    if (timestamp === getTimestamp()) {
      yield quitPortal();
    }
  }
}

function* loginSagas() {
  yield takeLatest("*", logoutTimer);
}

export default loginSagas;
