import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import CardEnding from "shared/components/CardEnding";

const PaymentInfo = ({
  card_type,
  last_4,
  patient_name,
  street_1,
  street_2,
  city,
  state,
  zip,
  title,
}) => (
  <Col md={4} sm={4}>
    <h5>{title ? `${title}:` : "Payments:"}</h5>
    {last_4 && <CardEnding card_type={card_type} last_4={last_4} />}
    <br />
    {patient_name}
    <br />
    {[street_1, street_2].join(" ")}
    <br />
    {[city, `${state} ${zip}`].filter((s) => s).join(", ")}
    <br />
  </Col>
);

PaymentInfo.propTypes = {
  card_type: PropTypes.string,
  last_4: PropTypes.string,
  patient_name: PropTypes.string,
  street_1: PropTypes.string,
  street_2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  title: PropTypes.string,
};

export default PaymentInfo;
