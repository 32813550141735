import * as types from "../constants/actionTypes";

const defaultState = {
  loading: false,
  url: "",
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.RESET_FORM:
      return defaultState;
    case types.FETCH_ONE_PRODUCT_IN_MASTER_SUCCESS:
      return { loading: false, url: action.product.image_url };
    case types.UPLOAD_PRODUCT_IMG_IN_MASTER_SUCCESS:
      return { loading: false, url: action.data.Location };
    case types.UPLOAD_PRODUCT_IMG_IN_MASTER_REQUEST:
    case types.FETCH_ONE_PRODUCT_IN_MASTER_REQUEST:
      return { loading: true, url: "" };
    default:
      return state;
  }
};
