export const CACHE_KEY = "cached-data";

export const LOAD_FROM_CACHE = "LOAD_FROM_CACHE";

export const loadFromCache = (state) => {
  return (dispatch) => {
    dispatch({
      type: LOAD_FROM_CACHE,
      state,
    });
  };
};
