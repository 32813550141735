import React, { Component } from "react";
import PropTypes from "prop-types";
import LoginForm from "./LoginForm";
import ResetPassword from "./ResetPassword";
import ContactUs from "./ContactUs";
import Footer from "../Footer";
import "./LoginPage.scss";
import AgreeToTermsAndPolicy from "./AgreeToTermsAndPolicy";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      terms_and_policy_agreed: false,
    };

    this.loginSubmit = this.loginSubmit.bind(this);
    this.onAgreedCheck = this.onAgreedCheck.bind(this);
  }

  loginSubmit({ email, password }) {
    const { role, submitLogin } = this.props;
    submitLogin(email, password, role);
  }

  onAgreedCheck(val) {
    this.setState({ terms_and_policy_agreed: val });
  }

  render() {
    const {
      login,
      match: {
        params: { role },
      },
      submitTermsAgreement,
      resetPassword,
      toggleResetPassword,
      submitResetPassword,
      contactUs,
      toggleContactUs,
      submitContactUs,
    } = this.props;

    return (
      <div className="LoginPage hm-body">
        <div className={`dashboard-login-container`}>
          <div className="dashboard-login">
            <div className="db-login-hdr">
              <a href="https://www.legranderx.com">
                <img
                  src="/assets/legrande-connect.svg"
                  width="100%"
                  height="100px"
                />
              </a>
            </div>
            <LoginForm {...login} submit={this.loginSubmit} />
            <div className="button-set">
              <div className="subset left">
                <a className="link" onClick={toggleResetPassword}>
                  Forgot Your Password?
                </a>
              </div>
              {role !== "master" && (
                <div className="subset right">
                  <a className="link" onClick={toggleContactUs}>
                    Do You Need Help?
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        <Footer />

        <ResetPassword
          {...resetPassword}
          toggle={toggleResetPassword}
          submit={submitResetPassword}
          toggleContactUs={toggleContactUs}
        />
        <ContactUs
          {...contactUs}
          toggle={toggleContactUs}
          submit={submitContactUs}
        />
        <AgreeToTermsAndPolicy
          show={login.show_terms_policy_agreement}
          value={this.state.terms_and_policy_agreed}
          onAgree={this.onAgreedCheck}
          onSubmit={submitTermsAgreement}
        />
      </div>
    );
  }
}

Login.propTypes = {
  title: PropTypes.string,
  role: PropTypes.string,
  login: PropTypes.object,
  match: PropTypes.object,
  show_terms_policy_agreement: PropTypes.bool,
  toggleTermsAgree: PropTypes.func,
  submitTermsAgreement: PropTypes.func,
  submitLogin: PropTypes.func,
  resetPassword: PropTypes.object,
  toggleResetPassword: PropTypes.func,
  submitResetPassword: PropTypes.func,
  contactUs: PropTypes.object,
  toggleContactUs: PropTypes.func,
  submitContactUs: PropTypes.func,
};

export default Login;
