import * as types from "./constants/actionTypes";
import { DispenserOrders } from "api/dispensers";

export const fetch = (order_id) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_ORDER_LABEL_REQUEST });
    DispenserOrders.getLabel(order_id).then(
      ({ label_url, label_zpl, label_pdf }) =>
        dispatch({
          type: types.FETCH_ORDER_LABEL_SUCCESS,
          label_url,
          file_url: label_zpl || label_pdf,
        })
    );
  };
};
