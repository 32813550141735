import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  bottom: 10px;
  display: flex;
  margin-top: 150px;
`;

const VerticalBar = styled.div`
  padding: 0 10px;
`;

const Footer = () => (
  <Wrapper>
    <strong> LegrandeRx Platform </strong>
    <VerticalBar> | </VerticalBar>
    18 Bunsen, Irvine, CA 92618
    <VerticalBar> | </VerticalBar>
    (855) 276 - 8555
  </Wrapper>
);

export default Footer;
