import { updateArrayItemById } from "utils/product-helpers";
import * as types from "../constants/actionTypes";

const productsReducer = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_PRODUCTS_LIST_SUCCESS:
      return action.products;
    case types.CHANGE_PRODUCT: {
      const { productId, changes } = action.payload;
      return updateArrayItemById(state, productId, changes);
    }
    case types.FETCH_PRODUCTS_LIST_REQUEST:
      return [];

    default:
      return state;
  }
};

export default productsReducer;
