import { extendedOrderContentsItemSchema as itemSchema } from "schemas";

export const treatmentPlanSchema = {
  name: { type: "String" },
  practice_id: { type: "String" },
  prescriber_notes: { type: "String" },
  groups: {
    type: "Array",
    itemSchema: {
      items: { type: "Array", itemSchema },
      group_no: { type: "String" },
      preauthorized_amount: { type: "Price" },
      prescriber_notes: { type: "String" },
    },
  },
};
