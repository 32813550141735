import React from "react";
import PropTypes from "prop-types";
import DefaultInput from "./DefaultInput";

const FormikInput = ({ field, form: { errors, touched }, ...restProps }) => (
  <DefaultInput
    error={touched[field.name] && errors[field.name]}
    {...field}
    {...restProps}
  />
);

FormikInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

export default FormikInput;
