import { takeLatest, put, call, all } from "redux-saga/effects";
import { showPopup } from "app-shared/containers/Popup/actions";
import { SHOW_API_ERROR } from "shared/containers/Popup/constants/actionTypes";
import { UNAUTHORIZED } from "constants/api/apiErrorMessages";
import { getPortal, isAuth, clearUser } from "utils/auth-helpers";
import { redirectToLogin } from "actions/globalActions";

const doNotDisplayExceptions = ["auth/logout"];

function* displayError(action) {
  const { status, response, requestPath } = action.value;

  if (!doNotDisplayExceptions.includes(requestPath)) {
    yield put(showPopup(response, { apiErrorStatus: status }));
  }

  if (response === UNAUTHORIZED && isAuth()) {
    yield put(redirectToLogin(getPortal()));
    yield call(clearUser);
  }
}

function* apiErrorSagas() {
  yield all([takeLatest(SHOW_API_ERROR, displayError)]);
}

export default apiErrorSagas;
