import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withFetching from "shared/containers/withFetching";
import Label from "../../components/Label";
import { fetch } from "./actions";

const mapStateToProps = (state) => state.dispenser.orderLabel;

const LabelWithFetching = withFetching(Label, "order_id");

export default withRouter(
  connect(mapStateToProps, { fetch })(LabelWithFetching)
);
