export const APPLY_CHECKBOX_ORDERS_FILTER = "APPLY_CHECKBOX_ORDERS_FILTER";

export const ORDERS_FILTER_UPDATE = "ORDERS_FILTER_UPDATE";

export const FETCH_ORDERS_REQUEST = "FETCH_ORDERS_REQUEST";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";

export const SORT_ORDERS_REQUEST = "SORT_ORDERS_REQUEST";
export const SORT_ORDERS_SUCCESS = "SORT_ORDERS_SUCCESS";
export const SORT_ORDERS_FAILURE = "SORT_ORDERS_FAILURE";

export const FETCH_PAYORS_REQUEST = "FETCH_PAYORS_REQUEST";
export const FETCH_PAYORS_SUCCESS = "FETCH_PAYORS_SUCCESS";
export const FETCH_PAYORS_FAILURE = "FETCH_PAYORS_FAILURE";
