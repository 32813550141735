import { combineReducers } from "redux";
import detailsReducer from "./detailsReducer";
import uiReducer from "./uiReducer";
import popupReducer from "./popupReducer";

export default combineReducers({
  settings: detailsReducer,
  ui: uiReducer,
  popup: popupReducer,
});
