import React, { Component } from "react";
import BillRow from "./BillRow";
import { Wrapper } from "./styles";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";

class Bill extends Component {
  render() {
    const { subtotal_price, tax, shipping_price, total_price } = this.props;

    return (
      <Col md={12} className="Products">
        <Wrapper>
          <BillRow name="Subtotal" value={subtotal_price} />
          <BillRow name="Tax" value={tax} />
          <BillRow name="Shipping" value={shipping_price} />
          <div className="line" />
          <BillRow name="Total" value={total_price} />
        </Wrapper>
      </Col>
    );
  }
}

export default Bill;

Bill.propTypes = {
  subtotal_price: PropTypes.string,
  shipping_price: PropTypes.string,
  tax: PropTypes.string,
  total_price: PropTypes.string,
};
