import objectAssign from "object-assign";
import isObject from "lodash/isObject";
import pick from "lodash/pick";
import _ from "lodash";

// func: compare objects against identical properties
export const simpleCompare = (a, b) => {
  if (!a || !b) {
    return false;
  }
  for (let property in a) {
    if (
      Object.prototype.hasOwnProperty.call(a, property) &&
      Object.prototype.hasOwnProperty.call(b, property) &&
      typeof a[property] === typeof b[property] &&
      a[property] !== b[property]
    ) {
      return false;
    }
  }
  return true;
};

export const isEmpty = (object, { only = null, except = [] } = {}) => {
  if (!object) return true;

  const toTest = only ? pick(object, ...only) : object;

  for (let property in toTest) {
    if (property === "errors" || except.includes(property)) continue;
    if (isObject(toTest[property])) {
      if (!isEmpty(toTest[property])) return false;
    } else if (toTest[property]) return false;
  }

  return true;
};

export const deepObjectsCompare = (object, base) => {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
};

export const removeEmptyProperties = (object) => {
  const copy = objectAssign({}, object);

  Object.keys(copy).forEach((key) => {
    const emptyObject =
      isObject(copy[key]) &&
      !Array.isArray(copy[key]) &&
      (copy[key] == null || !Object.values(copy[key]).length);

    if (isObject(copy[key]) && !Array.isArray(copy[key])) {
      copy[key] = removeEmptyProperties(copy[key]);
    }

    if (emptyObject || copy[key] == undefined || copy[key] === "") {
      delete copy[key];
    }
  });

  return copy;
};

export const initEmptyProperties = (object) => {
  const clone = objectAssign({}, object);

  Object.keys(clone).forEach((key) => {
    if (!clone[key]) {
      clone[key] = "";
    }
  });

  return clone;
};
