import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";

const defaultState = {
  name: "",
  type: "",
  category: "",
  brand: "",
  description: "",
  pn_number: "",
  ndc_number: "",
  awp_number: "",
  arp_number: "",
  strength: "",
  recommended_price: "",
  gmv: "",
  image_url: "",
  is_active: "True",
};

const productFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_ONE_PRODUCT_IN_MASTER_REQUEST:
    case types.SUBMIT_PRODUCT_IN_MASTER_SUCCESS:
    case types.RESET_FORM:
      return defaultState;
    case types.FETCH_ONE_PRODUCT_IN_MASTER_SUCCESS: {
      const product = objectAssign({}, action.product);
      Object.keys(product).forEach((key) => {
        if (!product[key]) {
          // init empty fields for Formik
          product[key] = "";
        }
      });
      return { ...product, gmv: product.gmv || "" };
    }

    default:
      return state;
  }
};

export default productFormReducer;
