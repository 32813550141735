import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { hot } from "react-hot-loader";

import AdminApp from "./app-master";

import PracticeApp from "./app-practice";
import PracticePrint from "./app-practice/Print";

// import PatientApp from "./app-patient";

import DispenserApp from "./app-dispenser";
import DispenserPrint from "./app-dispenser/Print";

import StaticApp from "./app-static";

import LoginPage from "./app-shared/containers/LoginPage";
import TermsAndPolicy from "./app-shared/components/TermsAndPolicy";

import ScrollToTop from "shared/components/ScrollToTop";

class App extends Component {
  render() {
    return (
      <ScrollToTop>
        <Switch>
          <Route
            path="/login/:role(master|dispenser|pharmacy|practice|doctor)"
            component={LoginPage}
          />

          <Route path="/master" component={AdminApp} />

          {/* <Route path="/patient" component={PatientApp} /> */}

          <Route path="/dispenser/print" component={DispenserPrint} />
          <Route path="/dispenser" component={DispenserApp} />
          <Route path="/pharmacy" component={DispenserApp} />

          <Route path="/practice/print" component={PracticePrint} />
          <Route path="/practice" component={PracticeApp} />
          <Route path="/doctor" component={PracticeApp} />

          <Route
            exact
            path="/"
            component={(props) => <LoginPage {...props} role="practice" />}
          />

          <Route path="/terms-of-use" component={TermsAndPolicy} />
          <Route path="/privacy-policy" component={TermsAndPolicy} />

          <Route path="*" component={StaticApp} />
        </Switch>
      </ScrollToTop>
    );
  }
}

export default hot(module)(App);
