import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ALL_TYPES, AMEX } from "utils/card-helpers";
import CardIcon from "../CardIcon";

const CardTypeWrapper = styled.div`
  position: absolute;
  top: -10px;
  right: 7px;
  z-index: 1;
  transform: ${({ selected }) =>
    selected ? "translate(-15px, 45px)" : "none"};
  transition: all 0.2s ease-in;
`;

const AnimatedCardIcon = ({ card_type }) => {
  const types = card_type ? [card_type] : ALL_TYPES;
  return (
    <CardTypeWrapper selected={Boolean(card_type)}>
      {types.map((card) => (
        <CardIcon key={card} type={card} />
      ))}
    </CardTypeWrapper>
  );
};

AnimatedCardIcon.propTypes = {
  card_type: PropTypes.oneOf([...ALL_TYPES, AMEX]),
};

export default AnimatedCardIcon;
