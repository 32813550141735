export const FETCH_ORGANIZATION_DETAILS_REQUEST =
  "FETCH_ORGANIZATION_DETAILS_REQUEST";
export const FETCH_ORGANIZATION_DETAILS_SUCCESS =
  "FETCH_ORGANIZATION_DETAILS_SUCCESS";
export const FETCH_ORGANIZATION_DETAILS_FAIL =
  "FETCH_ORGANIZATION_DETAILS_FAIL";

export const SUBMIT_ORGANIZATION_DETAILS_REQUEST =
  "SUBMIT_ORGANIZATION_DETAILS_REQUEST";
export const SUBMIT_ORGANIZATION_DETAILS_SUCCESS =
  "SUBMIT_ORGANIZATION_DETAILS_SUCCESS";
export const SUBMIT_ORGANIZATION_DETAILS_FAIL =
  "SUBMIT_ORGANIZATION_DETAILS_FAIL";

export const SHOW_DISPENSER_POPUP = "SHOW_DISPENSER_POPUP";
export const DISMISS_DISPENSER_POPUP = "DISMISS_DISPENSER_POPUP";
