import {
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
} from "../base";

export const patientsGetRequest = (method, query = {}) =>
  getRequest(path(method), query);

export const patientsPostRequest = (method, query = {}) =>
  postRequest(path(method), query);

export const patientsPutRequest = (method, query = {}) =>
  putRequest(path(method), query);

export const patientsPatchRequest = (method, query = {}) =>
  patchRequest(path(method), query);

export const patientsDeleteRequest = (method, query = {}) =>
  deleteRequest(path(method), query);

const path = (method = "") => {
  const ending = method ? `/${method}` : "";
  return `patients${ending}`;
};
