import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Checkbox from "shared/components/Checkbox";

const Wrapper = styled.div`
  position: relative;
  border-bottom: #d9d9d9 2px solid;
  margin: 20px -5px;
  padding: 0 5px 10px;
  display: flex;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
  }
`;

const DawWrapper = styled.div`
  position: relative;
  min-width: 200px;
  top: -10px;
  text-align: right;
`;

const Item = ({
  name,
  instructions,
  amount,
  strength,
  refills,
  dispense_as_written,
  day_supply,
}) => (
  <Wrapper>
    <div>
      <strong>Drug Name:</strong> {name}
      <br />
      <strong>Directions For Use:</strong> {instructions || "-"}
      <br />
      <strong>Size:</strong> {strength}
      <br />
      <strong>Quantity:</strong> {amount}
      <br />
      <strong>Refills:</strong> {refills}
      <br />
      <strong>Day Supply:</strong> {day_supply || "90 days"}
      <br />
    </div>
    <DawWrapper>
      <Checkbox
        label="Dispense as Written"
        checked={dispense_as_written}
        disabled={true}
      />
    </DawWrapper>
  </Wrapper>
);

Item.propTypes = {
  name: PropTypes.string,
  instructions: PropTypes.string,
  strength: PropTypes.string,
  amount: PropTypes.number,
  refills: PropTypes.number,
  day_supply: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dispense_as_written: PropTypes.bool,
};

export default Item;
