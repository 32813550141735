import objectAssign from "object-assign";
import isBoolean from "lodash/isBoolean";
import isArray from "lodash/isArray";
import pick from "lodash/pick";
import first from "lodash/first";
import { getItemSingleUnitPrice } from "utils/product-helpers";

export const prepare = (product) => {
  const result = objectAssign(
    pick(product, [
      "category",
      "image_url",
      "instructions",
      "manufacturer_name",
      "type",
      "strength",
      "best_price",
      "price_set",
      "expires",
      "rx_expiry_date",
      "rx_number",
      "one_time_only",
    ]),
    {
      product_id: product.product_id || product._id,
      product_name: product.product_name || product.name,
      amount: parseInt(product.amount) || 1,
      refills: parseInt(product.refills) || 0,
      auto_refill: isBoolean(product.auto_refill) ? product.auto_refill : true,
      dispense_as_written: isBoolean(product.dispense_as_written)
        ? product.dispense_as_written
        : false,
      price: getItemSingleUnitPrice(product),

      strengths: getStrengths(product),
      available_options: getAvailableOptions(product),
    }
  );

  return objectAssign({}, result, {
    strength: result.strength || first(result.strengths),
  });
};

const getStrengths = (product) => {
  const { strengths, available_options } = product;
  if (isArray(strengths)) {
    return [...strengths];
  }

  if (isArray(available_options)) {
    return product.available_options.map((option) => option.strength);
  }

  if (product.strength) {
    return [product.strength];
  }

  return null;
};

const getAvailableOptions = (product) => {
  const { available_options, strength, amount = 1 } = product;

  if (isArray(available_options)) {
    return available_options.reduce((acc, option) => {
      acc[option.strength] = option.price / amount;
      return acc;
    }, {});
  }
  const price = getItemSingleUnitPrice(product);

  if (strength) {
    return objectAssign({}, available_options, {
      [strength]: price / amount,
    });
  }

  return objectAssign({}, available_options);
};

export const product_schema = {
  _id: "",
  name: "",
  brand: "",
  strength: "",
  type: "RX",
  category: "",
  price: 0,
  is_active: "",
  best_price: true,
  shipping_fee: true,
  awp_number: "",
  arp_number: "",
  ndc_number: "",
  pn_number: "",
  description: "",
  image_url: "",
};
