import objectAssign from "object-assign";

import { getRequest } from "../../base";
import { prepare } from "../../product-helpers";
import { path } from "../../path-helpers";

export const getFilters = (base) => () => {
  return getRequest(path(base, "products/filters")).then((json) => json);
};

export const getAll = (base) => (skip, limit, filters = {}) => {
  const params = objectAssign({}, filters, {
    skip,
    limit,
    active: "true",
    archived: "false",
  });
  return getRequest(path(base, "products"), params).then((json) => {
    return {
      ...json,
      products: json.products.map((product) =>
        objectAssign({}, product, prepare(product))
      ),
    };
  });
};

export const getOne = (base) => (product_id) => {
  return getRequest(path(base, `products/${product_id}`)).then((product) =>
    prepare(product)
  );
};

export const getMultiple = (base) => (ids) => {
  return getRequest(path(base, "products"), {
    ids: ids.join("&ids="),
  }).then((json) => json.products.map((product) => prepare(product)));
};

export const searchActive = (base) => ({ q, skip, limit, ...filters }) => {
  return getRequest(path(base, "products"), {
    q,
    skip,
    limit,
    ...filters,
    active: "true",
    archived: "false",
  }).then((json) => json);
};

const ProductsApi = (base) => {
  return {
    getAll: getAll(base),
    getFilters: getFilters(base),
    getMultiple: getMultiple(base),
    getOne: getOne(base),
    searchActive: searchActive(base),
  };
};

export default ProductsApi;
