import { isArray } from "lodash";
import * as types from "./constants/actionTypes";
import * as defaults from "constants/defaults/paymentAndShipping";
import { simpleCompare } from "utils/object-helpers";
import { showPopup } from "app-shared/containers/Popup/actions";
import { getOrder } from "modules/orderFlow/selectors";
import { APIModule } from "api";
import { GET_ONE_ORDER_MODES } from "api/order-helpers";
import {
  NEW_ORDER_FETCH_ORDER_REQUEST,
  NEW_ORDER_FETCH_ORDER_SUCCESS,
} from "shared/containers/OrderNew/constants/actionTypes";
import { getPortal } from "utils/auth-helpers";
import { isPaymentDefault } from "utils/payment-helpers";

export { cancel } from "shared/containers/OrderNew/actions";

export const changeCard = (updates) => ({
  type: types.PAYMENT_AND_SHIPPING_CARD_UPDATE,
  updates,
});

export const changeShippingAddress = (updates) => ({
  type: types.PAYMENT_AND_SHIPPING_SHIPPING_ADDRESS_UPDATE,
  updates,
});

export const changeBillingAddress = (updates) => ({
  type: types.PAYMENT_AND_SHIPPING_BILLING_ADDRESS_UPDATE,
  updates,
});

export const changeInsurance = (_id, updates) => ({
  type: types.PAYMENT_AND_SHIPPING_INSURANCE_UPDATE,
  _id,
  updates,
});

export const addInsurance = () => ({
  type: types.PAYMENT_AND_SHIPPING_ADD_INSURANCE,
});

export const removeInsurance = (_id) => ({
  type: types.PAYMENT_AND_SHIPPING_REMOVE_INSURANCE,
  _id,
});

export const paymentDetailsUpdate = ({
  card,
  shipping_address,
  billing_address,
  health_insurance,
}) => ({
  type: types.PAYMENT_AND_SHIPPING_BULK_UPDATE,
  card,
  shipping_address,
  billing_address,
  health_insurance,
});

export const handleQuery = ({ order_id }) => {
  return (dispatch) => {
    const modes = [
      GET_ONE_ORDER_MODES.WITH_ORDER_CONTENTS,
      GET_ONE_ORDER_MODES.WITH_SUBSCRIPTION,
    ];

    const portal = getPortal();

    if (order_id) {
      dispatch({ type: NEW_ORDER_FETCH_ORDER_REQUEST });
      APIModule(portal)
        .orders.getOne(order_id, modes)
        .then((order) => {
          dispatch({ type: NEW_ORDER_FETCH_ORDER_SUCCESS, order });
          dispatch(
            paymentDetailsUpdate({
              card: order.card,
              shipping_address: order.shipping_address,
              billing_address: order.billing_address,
              health_insurance: order.health_insurance,
            })
          );
        });
    }
  };
};

export const validate = (cardUpdates) => {
  if (!simpleCompare(defaults.card, cardUpdates)) {
    return {
      type: types.PAYMENT_AND_SHIPPING_VALIDATE,
    };
  }
  return {
    type: types.INSURANCE_VALIDATE,
  };
};

export const reset = () => ({
  type: types.PAYMENT_AND_SHIPPING_RESET_ALL,
});

const cardShippingReset = () => ({
  type: types.PAYMENT_AND_SHIPPING_RESET,
});

const insuranceReset = () => ({
  type: types.INSURANCE_RESET,
});

const resetAndValidate = ({ card, health_insurance, order }) => (dispatch) => {
  const { isCardDefault, isInsuranceDefault } = isPaymentDefault(
    card,
    health_insurance
  );

  if (isCardDefault && isInsuranceDefault) {
    dispatch(validate(card));
  } else if (!isCardDefault && !isInsuranceDefault) {
    dispatch(validate(card)); // validates card details
    dispatch(validate(defaults.card)); // validates insurance details
  } else if (isCardDefault && !isInsuranceDefault) {
    dispatch(cardShippingReset());
    dispatch(validate(card));
  } else if (isInsuranceDefault && !isCardDefault) {
    dispatch(insuranceReset());
    dispatch(validate(card));
  } else if (!order && !order.order_id) {
    dispatch(reset());
  }
};

export const nextFactory = (pureNext, stateGetter) => () => {
  return (dispatch, getState) => {
    const state = stateGetter(getState());
    const order = getOrder(getState());

    const paymentInfoRequiredText =
      "Please indicate either valid credit card or insurance information";
    const paymentAddressRequiredText =
      "Please indicate shipping and billing addresses";

    const nonEmptyInsurances = state.health_insurance.filter(
      (insurance) => !isPaymentDefault(null, [insurance]).isInsuranceDefault
    );

    dispatch(paymentDetailsUpdate({ health_insurance: nonEmptyInsurances }));

    // validate filled form and reset the other
    dispatch(
      resetAndValidate({
        card: state.card,
        health_insurance: nonEmptyInsurances,
        order,
      })
    );

    const updatedState = stateGetter(getState());

    const checkIfValid = (entity) => {
      if (Object.prototype.hasOwnProperty.call(entity, "cvv"))
        return entity.errors && entity.errors.hasErrors;

      const { isInsuranceDefault } = isPaymentDefault(null, [entity]);
      return !isInsuranceDefault && entity.errors && entity.errors.hasErrors;
    };
    const isMethodValid = !["card", "health_insurance"].some((key) =>
      isArray(updatedState[key])
        ? updatedState[key].some((entity) => checkIfValid(entity))
        : checkIfValid(updatedState[key])
    );
    const isAddressValid = !["shipping_address", "billing_address"].some(
      (key) => updatedState[key].errors && updatedState[key].errors.hasErrors
    );

    if (isAddressValid && !isMethodValid) {
      dispatch(showPopup(paymentInfoRequiredText));
    } else if (!isAddressValid && isMethodValid) {
      dispatch(showPopup(paymentAddressRequiredText));
    }

    isMethodValid &&
      isAddressValid &&
      dispatch(pureNext({ order_id: order.order_id }));
  };
};
