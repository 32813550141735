import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";

const defaultState = {
  name: "",
  email: "",
  type: "",
  phone: "",
  fax: "",
  address: {
    street_1: "",
    street_2: "",
    city: "",
    state: "",
    zip: "",
  },
  ocusoft: false,
  hub: false,
  npi_number: "",
  reseller_licence_number: "",
  timezone: "",
  assigned_dispensers: {
    rx: "",
    retail: "",
    compound: "",
  },
  admin: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  },
  error: null,
};

const dispenserPracticeFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_ONE_ORGANIZATION_IN_MASTER_REQUEST:
    case types.SUBMIT_ORGANIZATION_IN_MASTER_SUCCESS:
      return defaultState;
    case types.FETCH_ONE_ORGANIZATION_IN_MASTER_SUCCESS:
      return { ...action.dispenser, admin: action.admin };
    case types.UPDATE_ORGANIZATION_IN_MASTER:
      return objectAssign({}, state, action.updates);
    case types.SUBMIT_ORGANIZATION_IN_MASTER_FAILURE:
      return objectAssign({}, defaultState, { error: action.errorMessage });
    default:
      return objectAssign({}, state, { error: null });
  }
};

export default dispenserPracticeFormReducer;
