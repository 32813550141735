import React, { Component } from "react";
import PropTypes from "prop-types";
import { RawWrapper } from "./styles";
import { priceToString } from "utils/price-helpers";

class BillRow extends Component {
  render() {
    const { name, value } = this.props;
    return (
      <RawWrapper>
        <div className="name">{name}:</div>
        <div style={{ fontSize: 16 }}>{priceToString(value)}</div>
      </RawWrapper>
    );
  }
}

BillRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
};

export default BillRow;
