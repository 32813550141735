import objectAssign from "object-assign";
import {
  masterGetRequest,
  masterPostRequest,
  masterPutRequest,
  masterPatchRequest,
  masterDeleteRequest,
} from "./base";
import { normalize } from "utils/data-helpers";

export const getAll = (skip, limit, filters) => {
  return masterGetRequest("dispensers", { skip, limit, ...filters }).then(
    (json) => json || null
  );
};

export const getOne = (dispenser_id) => {
  return masterGetRequest(`dispensers/${dispenser_id}`).then((json) =>
    objectAssign({}, json, { dispenser_id })
  );
};

export const getAdmin = (dispenser_id) => {
  return masterGetRequest(`dispensers/${dispenser_id}/admin`).then(
    (admin) => admin
  );
};

export const submit = (dispenser) => {
  const { dispenser_id, ...data } = dispenser;
  const cleanData = dispenser_id ? normalize(dispenser) : normalize(data);

  if (dispenser.dispenser_id) {
    return masterPatchRequest(`dispensers/${dispenser_id}`, cleanData);
  } else {
    return masterPostRequest("dispensers", cleanData);
  }
};

export const remove = (dispenser_id) => {
  return masterDeleteRequest(`dispensers/${dispenser_id}`);
};

export const submitAdmin = ({ organization_id, admin_id, admin_data }) => {
  if (admin_id) {
    return masterPatchRequest(
      `dispensers/${organization_id}/admin`,
      admin_data
    );
  } else {
    return masterPostRequest(`dispensers/${organization_id}/admin`, admin_data);
  }
};

export const makeUserAnAdmin = (dispenser_id, user_id) => {
  return masterPutRequest(`dispensers/${dispenser_id}/admin`, { user_id });
};

export const resendAdminInvite = (dispenser_id) => {
  return masterPutRequest(`dispensers/${dispenser_id}/admin/invite`);
};

export const getUsersFor = (dispenser_id) => {
  return masterGetRequest(`dispensers/${dispenser_id}/users`).then(
    (json) => json.users
  );
};
