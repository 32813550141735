import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

const ShippedTo = ({ patient_name, street_1, street_2, city, state, zip }) => (
  <Col md={4} sm={4}>
    <h5>Shipped To:</h5>
    {patient_name}
    <br />
    {[street_1, street_2].join(" ")}
    <br />
    {[city, `${state} ${zip}`].filter((s) => s).join(", ")}
    <br />
  </Col>
);

ShippedTo.propTypes = {
  patient_name: PropTypes.string,
  street_1: PropTypes.string,
  street_2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
};

export default ShippedTo;
