import objectAssign from "object-assign";
import * as types from "./constants/actionTypes";
import { DispenserOrders } from "api/dispensers";
import { GET_ONE_ORDER_MODES } from "api/order-helpers";

export const fetch = (order_id) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_RECEIPT_REQUEST });

    const modes = [
      GET_ONE_ORDER_MODES.WITH_ORDER_CONTENTS,
      GET_ONE_ORDER_MODES.WITH_PATIENT_DETAILS,
      GET_ONE_ORDER_MODES.WITH_DISPENSER_DETAILS,
    ];
    DispenserOrders.getOne(order_id, modes).then((order) => {
      const {
        payment_details,
        patient,
        dispenser,
        history: { created_on },
      } = order;
      const patient_name = `${patient.first_name} ${patient.last_name}`;
      const {
        card: { last_4, card_type },
        shipping_address,
        billing_address,
      } = payment_details;

      dispatch({
        type: types.FETCH_RECEIPT_SUCCESS,
        receipt: objectAssign({
          billed_to: objectAssign(
            { patient_name, card_type, last_4 },
            billing_address
          ),
          shipped_to: objectAssign({ patient_name }, shipping_address),
          pharmacy_name: dispenser.name,
          pharmacy_phone: dispenser.phone,
          order_date: created_on,
          ...order,
        }),
      });
    });
  };
};
