import uuidv1 from "uuid/v1";
import { toServerFormat } from "utils/date";
import objectAssign from "object-assign";
import isObject from "lodash/isObject";
import { normalize } from "utils/data-helpers";
import {
  getItemPriceInServerFormat,
  getItemSingleUnitPrice,
} from "utils/product-helpers";
import { isPractice } from "utils/auth-helpers";
import { removeEmptyProperties } from "utils/object-helpers";
import { prepare } from "./product-helpers";
import {
  singleInsuranceSchema,
  preparePaymentDetails,
  fromServerToClient as parsePaymentDetails,
} from "./payment-helpers";

import { getMultiple as getMultipleDispenserProducts } from "./dispensers/products";
import { getMultiple as getMultiplePracticeProducts } from "./practices/products";
import { getOne as getOneDispenserPatient } from "./dispensers/patients";
import { getOne as getOnePracticePatient } from "./practices/patients";
import { getOne as getOneDispenserDoctor } from "./dispensers/doctors";
import { getOne as getOnePracticeDoctor } from "./practices/doctors";
import { getOne as getOneDispenser } from "./dispensers/dispensers";
import { getOne as getOneDispenserSubscription } from "./dispensers/subscriptions";
import { getOne as getOnePracticeSubscription } from "./practices/subscriptions";
import { getOne as getOneDispenserDocument } from "./dispensers/dropcharts";
import { getOne as getOnePracticeDocument } from "./practices/dropcharts";

const contentWithPrice = (orderContentsSchema) =>
  objectAssign({}, orderContentsSchema, {
    price: { type: "Integer", source: getItemPriceInServerFormat },
  });

export const baseOrderContentsItemSchema = {
  product_id: { type: "String" },
  type: { type: "String" },
  strength: { type: "String" },
  amount: { type: "Integer" },
  refills: { type: "Integer" },
  rx_number: { type: "String" },
};

export const baseOrderContentsItemWithPriceSchema = contentWithPrice(
  baseOrderContentsItemSchema
);

export const extendedOrderContentsItemSchema = objectAssign(
  {},
  baseOrderContentsItemSchema,
  {
    expires: { transformer: toServerFormat },
    auto_refill: { type: "Boolean" },
    refills: { type: "Integer" },
    instructions: { type: "String" },
    price_set: { type: "Boolean" },
    dispense_as_written: { type: "Boolean" },
    rx_expiry_date: { type: "String" },
  }
);

export const extendedOrderContentsItemWithPriceSchema = contentWithPrice(
  extendedOrderContentsItemSchema
);

export const baseOrderSchema = {
  doctor_id: { type: "String" },
  dispenser_id: { type: "String" },
  delivery_method: { type: "String" },
  notes: {
    type: "Array",
    itemSchema: {
      author: { type: "String" },
      text: { type: "String" },
      show_to_pharmacy: { type: "Boolean" },
    },
  },
  items: {
    type: "Array",
    itemSchema: baseOrderContentsItemSchema,
  },
};

export const baseOrderWithPriceSchema = objectAssign({}, baseOrderSchema, {
  items: {
    type: "Array",
    itemSchema: baseOrderContentsItemWithPriceSchema,
  },
});

export const extendedOrderSchema = objectAssign({}, baseOrderSchema, {
  doctor_id: { type: "String" },
  patient_id: { type: "String" },
  subscription_id: { type: "String" },
  type: { type: "String" },
  items: {
    type: "Array",
    itemSchema: extendedOrderContentsItemSchema,
  },
  payment_details: {
    type: "Object",
    schema: {
      card: { type: "Object" },
      billing_address: { type: "Object" },
      shipping_address: { type: "Object" },
      health_insurance: { type: "Array", itemSchema: singleInsuranceSchema },
    },
  },
  shipment_details: { type: "Object" },
  prescriber_notes: { type: "String" },
  patient_allergies: { type: "Array", itemType: "String" },
  preauthorized_amount: { type: "String" },
  payment_program: { type: "String" },
  prescription_on_file: { type: "Boolean" },
  surgery_date: { type: "String" },
  dropchart_ids: { type: "Array", itemType: "String" },
  maximum_total_price: { type: "Float" },
});

export const extendedOrderWithPriceSchema = objectAssign(
  {},
  extendedOrderSchema,
  {
    items: {
      type: "Array",
      itemSchema: extendedOrderContentsItemWithPriceSchema,
    },
  }
);

export const fromServerToClient = (order_id, order) => {
  return objectAssign({ order_id }, order, {
    items: order.items.map((item) => prepare(item)),
    payment_details: parsePaymentDetails(order.payment_details),
    price: isObject(order.price)
      ? {
          shipping_price: parseFloat(order.price?.shipping_price),
          subtotal_price: parseFloat(order.price?.subtotal_price),
          tax: parseFloat(order.price?.tax),
          total_price: parseFloat(order.price?.total_price),
        }
      : order.price,
  });
};

// TODO:
// check
// ```
// order_date: toServerFormat(order.order_date)
// ```

export const extendOrder = (order, paymentAndShipping = {}) => {
  const { ui } = paymentAndShipping;
  const isPaymentDefault = ui ? ui.isDefault : null;

  return objectAssign({}, order, {
    payment_details: !isPaymentDefault
      ? preparePaymentDetails(paymentAndShipping)
      : null,
  });
};

const prepareOrderContents = (order) => {
  const { items, groups } = order;

  const itemsWithPrice = items?.map((i) => ({
    ...i,
    price: getItemSingleUnitPrice(i),
  }));

  const groupsItemsWithPrice = groups?.map((g) =>
    g.map((item) => ({
      ...item,
      type: item.type,
      price: getItemSingleUnitPrice(item),
    }))
  );

  return {
    items: itemsWithPrice,
    groups: groupsItemsWithPrice,
  };
};

export const prepareOrderNotes = (notes) => {
  if (notes && notes.length) {
    return {
      notes: notes.map((n) => {
        const {
          author: { _id },
          text,
          show_to_pharmacy,
        } = n;

        return { author: _id, text, show_to_pharmacy };
      }),
    };
  }
};

export const orderDataFactory = (order, paymentAndShipping) => {
  const paymentDetailsPrepared = removeEmptyProperties(
    normalize(preparePaymentDetails(paymentAndShipping))
  );

  const orderContents = prepareOrderContents(order);
  const orderWithPrices = objectAssign({}, order, orderContents);
  const orderNotes = prepareOrderNotes(order.notes);

  const preparedOrder = objectAssign(
    {},
    normalize(
      objectAssign(
        {},
        extendOrder(orderWithPrices, paymentAndShipping),
        orderNotes,
        { dropchart_ids: order.dropcharts?.map(({ _id }) => _id) }
      ),
      extendedOrderWithPriceSchema
    ),
    { payment_details: paymentDetailsPrepared }
  );

  return removeEmptyProperties(preparedOrder);
};

export const createNoteLocally = ({ author, text, show_to_pharmacy }) => ({
  _id: uuidv1(),
  author,
  text,
  timestamp: new Date(),
  show_to_pharmacy,
});

export const GET_ONE_ORDER_MODES = {
  WITH_ORDER_CONTENTS: "WITH_ORDER_CONTENTS",
  WITH_PATIENT_DETAILS: "WITH_PATIENT_DETAILS",
  WITH_DOCTOR_DETAILS: "WITH_DOCTOR_DETAILS",
  WITH_DISPENSER_DETAILS: "WITH_DISPENSER_DETAILS",
  WITH_SUBSCRIPTION: "WITH_SUBSCRIPTION",
  WITH_DOCUMENTS_DETAILS: "WITH_DOCUMENTS_DETAILS",
};

const withOrderContents = (order) => {
  const ids = order.items.map((c) => c.product_id);
  const apiEndpoint = isPractice()
    ? getMultiplePracticeProducts
    : getMultipleDispenserProducts;

  return apiEndpoint(ids)
    .then((products) => {
      const items = order.items.map((item) => {
        const product = products.find((p) => p.product_id === item.product_id);
        return objectAssign(
          { available_options: product.available_options },
          item
        );
      });

      return { items };
    })
    .catch(() => {
      order.items;
    });
};

const withPatientDetails = ({ patient_id }) => {
  const apiEndpoint = isPractice()
    ? getOnePracticePatient
    : getOneDispenserPatient;

  return apiEndpoint(patient_id).then((patient) => {
    return { patient };
  });
};

const withDoctorDetails = ({ doctor_id }) => {
  const apiEndpoint = isPractice()
    ? getOnePracticeDoctor
    : getOneDispenserDoctor;

  return apiEndpoint(doctor_id).then((doctor) => {
    return { doctor };
  });
};

const withDispenserDetails = ({ dispenser_id }) => {
  return getOneDispenser(dispenser_id).then((dispenser) => {
    return { dispenser };
  });
};

const withSubscription = ({ subscription_id }) => {
  const apiEndpoint = isPractice()
    ? getOnePracticeSubscription
    : getOneDispenserSubscription;

  return (
    subscription_id &&
    apiEndpoint(subscription_id).then((subscription) => ({ subscription }))
  );
};

const withDocumentsDetails = ({ dropchart_ids }) => {
  const dropcharts = [];
  const apiEndpoint = isPractice()
    ? getOnePracticeDocument
    : getOneDispenserDocument;

  dropchart_ids.forEach(async (id) => {
    try {
      const document = await apiEndpoint(id);
      dropcharts.push(document);
    } catch (error) {
      /* eslint-disable-next-line */
    }
  });

  return { dropcharts };
};

const MAP_MODES = {
  [GET_ONE_ORDER_MODES.WITH_ORDER_CONTENTS]: withOrderContents,
  [GET_ONE_ORDER_MODES.WITH_PATIENT_DETAILS]: withPatientDetails,
  [GET_ONE_ORDER_MODES.WITH_DOCTOR_DETAILS]: withDoctorDetails,
  [GET_ONE_ORDER_MODES.WITH_DISPENSER_DETAILS]: withDispenserDetails,
  [GET_ONE_ORDER_MODES.WITH_SUBSCRIPTION]: withSubscription,
  [GET_ONE_ORDER_MODES.WITH_DOCUMENTS_DETAILS]: withDocumentsDetails,
};

export const handleModes = (order, modes) => {
  return Promise.all(
    modes.map((mode) => MAP_MODES[mode](order))
  ).then((values) =>
    values.reduce(
      (accumulator, value) => objectAssign({}, accumulator, value),
      order
    )
  );
};
