import {
  getRequest,
  pureGetRequest,
  postRequest,
  purePostRequest,
  putRequest,
  deleteRequest,
} from "../base";

export const usersGetRequest = (method, query = {}) =>
  getRequest(path(method), query);

export const usersPureGetRequest = (method, query = {}) =>
  pureGetRequest(path(method), query);

export const usersPostRequest = (method, query = {}) =>
  postRequest(path(method), query);

export const usersPurePostRequest = (method, query = {}) =>
  purePostRequest(path(method), query);

export const usersPutRequest = (method, query = {}) =>
  putRequest(path(method), query);

export const usersDeleteRequest = (method, query = {}) =>
  deleteRequest(path(method), query);

const path = (method) => {
  const ending = method ? `/${method}` : "";
  return `auth${ending}`;
};
