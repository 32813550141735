import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50px;
  right: 50px;
`;

export const TitleLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 22px;
`;

export const Title = styled.h1`
  margin: 0;
`;

export const FooterWrapper = styled.div`
  font-size: 14px;
`;
