import { combineReducers } from "redux";
import newOrderReducer from "shared/containers/OrderNew/reducer";
import existingOrderDetailsReducer from "shared/containers/OrderDetailsPage/reducer";
import paymentAndShippingReducer from "shared/containers/PaymentAndShipping/reducer";
import notesReducer from "shared/containers/OrderNotes/reducer";

export default combineReducers({
  new: newOrderReducer,
  existingOrderDetails: existingOrderDetailsReducer,
  payment_and_shipping: paymentAndShippingReducer,
  notes: notesReducer,
});
