import React from "react";
import PropTypes from "prop-types";

import OrderTemplate from "../PrintTemplates/Order";
import OrderDetails from "./OrderDetails";
import Products from "./Products";
import Payment from "./Payment";
import Bill from "./Bill";
import Footer from "./Footer";

const NullRender = () => null;

const OrderConfirmation = (props) => {
  return (
    <OrderTemplate
      {...props}
      title="Order Confirmation"
      orderDetails={OrderDetails}
      bill={Bill}
      shippedTo={NullRender}
      products={Products}
      paymentInfo={Payment}
      pageFooter={Footer}
      footerStyle={{ fontSize: 12 }}
    />
  );
};

OrderConfirmation.propTypes = {
  title: PropTypes.string,
};

export default OrderConfirmation;
