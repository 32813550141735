import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";

const defaultState = {
  edit: { active: false, product: null },
  price_change_warning: false,
  successful_submit: false,
};

const uiReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.TOGGLE_SHOW_EDIT_PRODUCT: {
      return objectAssign({}, state, {
        edit: { active: !state.edit.active },
      });
    }
    case types.TOGGLE_SHOW_PRICING_CHANGE_WARNING: {
      return objectAssign({}, state, {
        price_change_warning: !state.price_change_warning,
      });
    }
    case types.FETCH_SINGLE_PRODUCT_SUCCESS:
      return objectAssign({}, state, {
        edit: { active: state.edit.active, product: action.product },
      });
    case types.UPDATE_PRODUCT:
      return objectAssign({}, state, {
        edit: {
          ...state.edit,
          active: false,
          updates: action.updates,
        },
      });
    case types.SUBMIT_PRODUCT_SUCCESS:
      return objectAssign({}, state, {
        price_change_warning: false,
        successful_submit: true,
      });
    case types.DISMISS_SUCCESSFUL_SUBMIT:
      return objectAssign({}, state, defaultState, {
        successful_submit: false,
      });
    case types.CLOSE_ALL_EDIT_FORMS:
      return objectAssign({}, state, defaultState);
    default:
      return state;
  }
};

export default uiReducer;
