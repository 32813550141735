import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

class NextAlert extends React.Component {
  static show(name) {
    NextAlert.hide();
    NextAlert.refs[name].setState({ show: true });
  }

  static hide() {
    Object.values(NextAlert.refs).map((ref) => {
      if (ref.state.show) ref.setState({ show: false });
    });
  }

  constructor(props) {
    super(props);
    this.state = { show: false };

    this.handleClose = this.handleClose.bind(this);
    this.handleFirstBtnClose = this.handleFirstBtnClose.bind(this);
    this.handleSecondBtnClose = this.handleSecondBtnClose.bind(this);

    NextAlert.refs = NextAlert.refs || {};
  }

  componentDidMount() {
    NextAlert.refs[this.props.name] = this;
  }

  componentWillUnmount() {
    delete NextAlert.refs[this.props.name];
  }

  handleFirstBtnClose() {
    const { onFirstButtonClick } = this.props;

    this.setState({ show: false });
    onFirstButtonClick && onFirstButtonClick();
  }

  handleSecondBtnClose() {
    const { onSecondButtonClick } = this.props;

    this.setState({ show: false });
    onSecondButtonClick && onSecondButtonClick();
  }

  handleClose() {
    this.setState({ show: false });
  }

  render() {
    const { show } = this.state;
    const {
      children,
      firstButtonText,
      secondButtonText,
      closeHidden,
    } = this.props;

    return (
      <Modal show={show} onHide={this.handleClose}>
        <Modal.Header closeButton />
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          {firstButtonText && (
            <Button bsSize="large" onClick={this.handleFirstBtnClose}>
              {firstButtonText}
            </Button>
          )}

          <Button
            bsStyle="primary"
            bsSize="large"
            onClick={this.handleSecondBtnClose}
            style={{ display: closeHidden && "none" }}
          >
            {secondButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

NextAlert.propTypes = {
  name: PropTypes.string,
  children: PropTypes.element,
  firstButtonText: PropTypes.string,
  secondButtonText: PropTypes.string,
  onFirstButtonClick: PropTypes.func,
  onSecondButtonClick: PropTypes.func,
  closeHidden: PropTypes.bool,
};

NextAlert.defaultProps = {
  secondButtonText: "Close",
};

export default NextAlert;
