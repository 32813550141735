import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import CardEnding from "shared/components/CardEnding";

const Payment = ({ card_type, last_4 }) => (
  <Col md={4} sm={4}>
    {last_4 ? (
      <>
        <h5>Payment Information:</h5>
        <CardEnding card_type={card_type} last_4={last_4} />
      </>
    ) : null}
  </Col>
);

Payment.propTypes = {
  card_type: PropTypes.string,
  last_4: PropTypes.string,
};

export default Payment;
