import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { formatDateForClient } from "utils/date";
import { phoneToClientFormat } from "utils/formatter";
import { toString as addressToString } from "utils/address-helpers";

const Wrapper = styled.div`
  padding-top: 10px;
  width: 100%;
`;

const PatientDetails = ({
  first_name,
  last_name,
  date_of_birth,
  phone,
  address,
  allergies,
}) => (
  <Wrapper>
    <Row>
      <Col md={11}>
        <strong>Patient Name:</strong> {[first_name, last_name].join(" ")}
        <br />
        <strong>DOB:</strong> {formatDateForClient(date_of_birth)}
        <br />
        <strong>Phone Number:</strong> {phoneToClientFormat(phone)}
        <br />
        <strong>Address:</strong> {addressToString(address)}
        <br />
        <strong>Allergies:</strong> {allergies ? allergies.join(", ") : "-"}
        <br />
      </Col>
    </Row>
  </Wrapper>
);

PatientDetails.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  date_of_birth: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.object,
  allergies: PropTypes.array,
};

export default PatientDetails;
