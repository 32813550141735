export const FETCH_ONE_PRODUCT_IN_MASTER_REQUEST =
  "FETCH_ONE_PRODUCT_IN_MASTER_REQUEST";
export const FETCH_ONE_PRODUCT_IN_MASTER_SUCCESS =
  "FETCH_ONE_PRODUCT_IN_MASTER_SUCCESS";
export const FETCH_ONE_PRODUCT_IN_MASTER_FAILURE =
  "FETCH_ONE_PRODUCT_IN_MASTER_FAILURE";

export const SUBMIT_PRODUCT_IN_MASTER_REQUEST =
  "SUBMIT_PRODUCT_IN_MASTER_REQUEST";
export const SUBMIT_PRODUCT_IN_MASTER_SUCCESS =
  "SUBMIT_PRODUCT_IN_MASTER_SUCCESS";
export const SUBMIT_PRODUCT_IN_MASTER_FAILURE =
  "SUBMIT_PRODUCT_IN_MASTER_SUCCESS";

export const UPLOAD_PRODUCT_IMG_IN_MASTER_REQUEST =
  "UPLOAD_PRODUCT_IMG_IN_MASTER_REQUEST";
export const UPLOAD_PRODUCT_IMG_IN_MASTER_FAILURE =
  "UPLOAD_PRODUCT_IMG_IN_MASTER_FAILURE";
export const UPLOAD_PRODUCT_IMG_IN_MASTER_SUCCESS =
  "UPLOAD_PRODUCT_IMG_IN_MASTER_SUCCESS";

export const RESET_FORM = "RESET_FORM";

export const TOGGLE_PRODUCT_LOADING = "TOGGLE_PRODUCT_LOADING";
