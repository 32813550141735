import { combineReducers } from "redux";
import patientReducer from "./patientReducer";
import ordersReducer from "./ordersReducer";
import subscriptionReducer from "./subscriptionReducer";
import uiReducer from "./uiReducer";

export default combineReducers({
  patient: patientReducer,
  orders: ordersReducer,
  subscriptions: subscriptionReducer,
  ui: uiReducer,
});
