import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Confirmation from "app-shared/components/Confirmation";
import Checkbox from "app-shared/components/Checkbox";
import Link from "app-shared/components/NewTabLink";

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AgreeToTermsAndPolicy = ({ show, value, onAgree, onSubmit }) => (
  <Confirmation
    show={show}
    dismissAction={onSubmit}
    dismissText="Continue"
    disabled={!value}
  >
    <div>
      <h4>Terms & Policies</h4>
      <CheckboxWrapper>
        <Checkbox checked={value} onChange={onAgree} />
        <div style={{ marginTop: "5px" }}>
          I agree to the{" "}
          <strong>
            <Link to="/terms-of-use">Terms of Use</Link>
          </strong>{" "}
          and{" "}
          <strong>
            <Link to={"/privacy-policy"}>Privacy Policies</Link>
          </strong>
          .
        </div>
      </CheckboxWrapper>
    </div>
  </Confirmation>
);

AgreeToTermsAndPolicy.propTypes = {
  value: PropTypes.bool,
  show: PropTypes.bool,
  onAgree: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AgreeToTermsAndPolicy;
