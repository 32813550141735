import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";
import { initEmptyProperties } from "utils/object-helpers";

const dispenserReducer = (state = null, action) => {
  switch (action.type) {
    case types.SUBMIT_PRACTICE_SETTINGS_SUCCESS: {
      return objectAssign(
        {},
        state,
        action.settings,
        action.organization
          ? {
              organization: {
                ...action.organization,
                address: initEmptyProperties(action.organization.address),
              },
            }
          : {}
      );
    }

    default:
      return state;
  }
};

export default dispenserReducer;
