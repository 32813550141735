import * as types from "../constants/actionTypes";

const defaultState = { count: 0, loading: false };

const uiReducer = (state = defaultState, actions) => {
  switch (actions.type) {
    case types.FETCH_PRODUCTS_LIST_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_PRODUCTS_LIST_SUCCESS:
      return { count: actions.count, loading: false };
    default:
      return state;
  }
};

export default uiReducer;
