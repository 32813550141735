export const FETCH_DROPCHART_DOCUMENTS_REQUEST =
  "FETCH_DROPCHART_DOCUMENTS_REQUEST";
export const FETCH_DROPCHART_DOCUMENTS_SUCCESS =
  "FETCH_DROPCHART_DOCUMENTS_SUCCESS";
export const FETCH_DROPCHART_DOCUMENTS_FAILURE =
  "FETCH_DROPCHART_DOCUMENTS_FAILURE";

export const FETCH_DOCTORS_REQUEST = "FETCH_DOCTORS_REQUEST";
export const FETCH_DOCTORS_SUCCESS = "FETCH_DOCTORS_SUCCESS";
export const FETCH_DOCTORS_FAILURE = "FETCH_DOCTORS_FAILURE";

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";
