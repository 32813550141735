import * as types from "../constants/actionTypes";
import {
  NEW_ORDER_FETCH_ORDER_REQUEST,
  NEW_ORDER_FETCH_ORDER_SUCCESS,
} from "shared/containers/OrderNew/constants/actionTypes";
import objectAssign from "object-assign";
import { phoneToClientFormat } from "utils/formatter";

const defaultState = {
  order_id: null,
  parcel_type: "standard_box",
  dimensions: {
    length: "1",
    height: "1",
    width: "1",
  },
  weight: {
    pounds: "1",
    ounces: "0",
  },
  active_service_type_index: 0,
  ship_to: null,
  ship_from: null,
  rates: [],
  loading: false,
};

const labelReducer = (state = defaultState, action) => {
  switch (action.type) {
    case NEW_ORDER_FETCH_ORDER_REQUEST:
      return defaultState;
    case NEW_ORDER_FETCH_ORDER_SUCCESS: {
      const { order } = action;
      const patient = order.patient || {};
      const dispenser = order.dispenser || {};
      const ship_to = objectAssign(
        {
          name: `${patient.first_name} ${patient.last_name}`,
          phone: phoneToClientFormat(patient.phone),
        },
        order.payment_details?.shipping_address
      );

      const ship_from = objectAssign(
        { name: dispenser.name, phone: phoneToClientFormat(dispenser.phone) },
        dispenser.address
      );

      return objectAssign({}, state, {
        ship_from,
        ship_to,
        order_id: order.order_id,
      });
    }
    case types.UPDATE_ORDER_LABEL: {
      return objectAssign({}, state, action.updates);
    }
    case types.FETCH_ORDER_LABEL_RATES_REQUEST:
      return objectAssign({}, state, { loading: true });
    case types.FETCH_ORDER_LABEL_RATES_FAILURE:
      return objectAssign({}, state, { loading: false });
    case types.FETCH_ORDER_LABEL_RATES_SUCCESS: {
      const { rates } = action;

      const preparedRates = Object.keys(rates)
        .map((serviceType) =>
          rates[serviceType].map((rate) => ({
            ...rate,
            service_name: rate.service_type,
            service_type: serviceType,
          }))
        )
        .flat();

      return objectAssign({}, state, {
        active_service_type_index: 0,
        rates: preparedRates,
        loading: false,
      });
    }
    default:
      return state;
  }
};

export default labelReducer;
