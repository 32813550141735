import React from "react";
import PropTypes from "prop-types";
import ProductImage from "shared/components/ProductImage";
import { phoneToClientFormat } from "utils/formatter";
import { priceToString } from "utils/price-helpers";
import styled from "styled-components";

export const ProductsContentRow = styled.tr`
  td {
    vertical-align: middle !important;
    text-align: left;

    &:nth-child(4),
    &:nth-child(3) {
      text-align: center;
    }

    &:last-child {
      text-align: right;
    }
  }
`;

const Product = ({
  product_name,
  image_url,
  amount,
  strength,
  price,
  pharmacy_name,
  pharmacy_phone,
  rx_number,
}) => (
  <ProductsContentRow>
    <td>
      <ProductImage image_url={image_url} />
    </td>
    <td>{product_name}</td>
    <td>{amount}</td>
    <td>{strength}</td>
    <td>
      <strong>{pharmacy_name}</strong>
      <br />
      {phoneToClientFormat(pharmacy_phone, null)}
      {rx_number && (
        <div
          style={{
            paddingTop: 10,
            marginTop: 10,
            borderTop: "1px solid rgb(204,204,204)",
          }}
        >
          Rx Number: {rx_number}
        </div>
      )}
    </td>
    <td>{priceToString(price)}</td>
  </ProductsContentRow>
);

Product.propTypes = {
  product_name: PropTypes.string,
  image_url: PropTypes.string,
  amount: PropTypes.number,
  strength: PropTypes.string,
  price: PropTypes.number,
  pharmacy_name: PropTypes.string,
  pharmacy_phone: PropTypes.string,
  rx_number: PropTypes.string,
};

export default Product;
