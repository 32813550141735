import objectAssign from "object-assign";
import * as portalTypes from "constants/portalTypes";
import { PRACTICE_ROLES } from "constants/accountRoles";
import { set, get, clear } from "./local-cache-helpers";
import { minsToMilliseconds } from "./date";

export const DEFAULT_TIMEOUT_MINS = 15;

export const setUser = (user) => {
  const userInfo = objectAssign({}, user);
  delete userInfo.jwt;

  set("user_id", user.user_id);
  set("current_user", userInfo);

  setTimestamp(null, userInfo.auto_logout_time);
};

export const getUser = () => {
  const currentTimestamp = new Date().getTime();
  const prevTimestamp = getTimestamp();
  const user = get("current_user");
  const logoutTimeout = minsToMilliseconds(
    user?.auto_logout_time || DEFAULT_TIMEOUT_MINS
  );

  if (
    (prevTimestamp && currentTimestamp - prevTimestamp > logoutTimeout) ||
    !user
  ) {
    clearUser();
    return {};
  } else {
    setTimestamp(null, user?.auto_logout_time);
    return user;
  }
};

export const getLoggedInUser = () => {
  const user = get("current_user");
  return user || null;
};

export const updateUser = (updates) => {
  const user = getUser();
  setUser(objectAssign({}, user, updates));
};

export const clearUser = () => clear();

export const setJWT = (jwt) => set("jwt", jwt);

export const getJWT = () => {
  return get("jwt");
};

export const getUserId = () => {
  return get("user_id");
};

export const getPracticeId = () => {
  const user = getUser();
  return user.practice_id;
};

export const isAuth = () => {
  return Boolean(getJWT());
};

export const isAuthAs = (type, user = null) => {
  const currentUser = user || getUser();

  return currentUser && currentUser.type === type;
};

export const redirectPath = (role) => {
  const user = getUser();

  if (user.type) {
    if (isAuthAs(role, user) || getPortal() === role) return null;
    else return `/${getPortal()}`;
  } else {
    return `/login/${role}`;
  }
};

export const getRole = (user) => {
  const currentUser = user || getUser();

  return currentUser && currentUser.role;
};

export const getUserType = (user) => {
  const currentUser = user || getUser();

  return currentUser && currentUser.type;
};

export const getTimeout = () => {
  const currentUser = getUser();
  const timeoutInMins = currentUser.auto_logout_time || DEFAULT_TIMEOUT_MINS;

  return minsToMilliseconds(timeoutInMins);
};

export const getLoginPath = (user = null) => {
  const role = getRole(user);
  return `/login/${role || portalTypes.PRACTICE}`;
};

export const setTimestamp = (
  timestamp = null,
  timeoutInMins = DEFAULT_TIMEOUT_MINS
) => {
  const time = timestamp || new Date().getTime();
  const prevTimestamp = getTimestamp();
  const timeoutInMs = minsToMilliseconds(parseInt(timeoutInMins));

  if (!prevTimestamp || time - prevTimestamp <= timeoutInMs) {
    set("timestamp", time);
  }
};

export const getTimestamp = () => {
  return parseInt(get("timestamp")) || 0;
};

export const setLoginRole = (role) => set("login_role", role);

export const getLoginRole = () => {
  return get("login_role");
};

export const mapRoleToPortal = (type) => {
  switch (type) {
    case "doctor":
      return portalTypes.PRACTICE;
    case "pharmacist":
    case "pharmacy":
    case portalTypes.DISPENSER:
      return portalTypes.DISPENSER;
    case "patient":
    case "master":
      return type;
    case null:
    case undefined:
    default:
      return undefined;
  }
};

export function setPortal(userType) {
  const portal = mapRoleToPortal(userType);

  set("portal", portal);
}

export const loggedInUserPortal = () => get("portal");

export const getPortal = () => {
  const portal = loggedInUserPortal() || mapRoleToPortal();

  if (portal) return portal;

  return portalTypes.PRACTICE;
};

export const isMaster = () => {
  return getPortal() === portalTypes.MASTER;
};

export const isPractice = () => {
  return getPortal() === portalTypes.PRACTICE;
};

export const isDoctor = () => {
  return getRole() === PRACTICE_ROLES.DOCTOR;
};

export const isDispenser = () => {
  return getPortal() === portalTypes.DISPENSER;
};

export const isHubDispenser = () => isDispenser() && getUser().hub;

export const isStdDispenser = () => isDispenser() && !isHubDispenser();
