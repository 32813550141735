import { combineReducers } from "redux";
import dispensersReducer from "../containers/DispensersPage/reducer";
import practicesReducer from "../containers/PracticesPage/reducer";

import productFormReducer from "../containers/ProductFormPage/reducer";
import dispenserPracticeFormReducer from "../containers/DispenserPracticeForm/reducer";
import masterAdminFormReducer from "../containers/MasterAdminForm/reducer";

import detailsReducer from "../containers/DispenserPracticeDetails/reducer";

import settingsReducer from "../containers/Settings/reducer";

import payorsReducer from "../containers/PayorsPage/reducer";

export default combineReducers({
  dispensers: dispensersReducer,
  practices: practicesReducer,
  payors: payorsReducer,

  productForm: productFormReducer,
  dispenserPracticeForm: dispenserPracticeFormReducer,
  masterAdminForm: masterAdminFormReducer,
  details: detailsReducer,

  settings: settingsReducer,
});
