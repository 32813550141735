import { getRequest } from "../../base";
import { path } from "../../path-helpers";

export const getAll = (base) => ({ skip, limit, filters = {} }) => {
  const params = { skip, limit, ...filters };

  return getRequest(path(base, "dropcharts"), params).then((json) => json);
};

export const getOne = (base) => (dropchart_id) => {
  return getRequest(path(base, `dropcharts/${dropchart_id}`)).then(
    (json) => json
  );
};

export default (base) => ({
  getAll: getAll(base),
  getOne: getOne(base),
});
