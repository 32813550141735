import * as types from "../constants/actionTypes";

const allDoctors = { value: "", label: "All" };

const defaultState = {
  loading: false,
  doctors: [allDoctors],
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.FETCH_DROPCHART_DOCUMENTS_REQUEST:
    case types.DELETE_DOCUMENT_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_DROPCHART_DOCUMENTS_SUCCESS:
      return {
        ...state,
        count: action.count,
        skip: action.skip,
        loading: false,
      };
    case types.DELETE_DOCUMENT_SUCCESS:
      return { ...state, loading: false };
    case types.FETCH_DOCTORS_SUCCESS:
      return {
        ...state,
        doctors: [
          allDoctors,
          ...action.doctors.map(({ _id, first_name, last_name }) => ({
            value: _id,
            label: `${first_name} ${last_name}`,
          })),
        ],
      };
    default:
      return state;
  }
};
