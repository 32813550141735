export const formatter = (mask, str) => {
  if (!str) return str;

  const digit = /\d/;
  let i = 0,
    j = 0,
    result = "";
  while (i < mask.length && j < str.length) {
    if (digit.test(mask[i])) {
      if (digit.test(str[j])) {
        result += str[j++];
        i++;
      } else {
        j++;
      }
    } else {
      result += mask[i++];
      if (!digit.test(str[j])) j++;
    }
  }
  return result;
};

export const phoneToClientFormat = (phone, nested) => {
  const number = nested ? phone && phone[nested] : phone;
  return formatter("(000) 000-0000", number);
};

export const phoneToServerFormat = (phone) => {
  return (
    phone && phone.replace(/[()]/g, "").replace(/[\s]/g, "").replace(/[-]/g, "")
  );
};
