import isFunction from "lodash/isFunction";
import { deepObjectsCompare, removeEmptyProperties } from "./object-helpers";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";

// schema:
// { filedName: { type: 'TypeName' } }
export const normalize = (data, schema) => {
  if (!schema) return data;

  let normalized = {};

  for (let key in schema) {
    const desc = schema[key];
    const value = transformData(data, key, desc);
    if (value !== undefined) normalized[key] = value;
  }

  return Object.keys(normalized).length > 0 ? normalized : undefined;
};

const transformData = (data, key, desc) => {
  const value = getValue(data, desc.source || key);
  const { transformer } = desc;

  if (value === undefined) return value;

  if (transformer && typeof transformer === "function") {
    return transformer(value);
  }

  switch (desc.type) {
    case "Array":
      return (
        value &&
        value.map((item) =>
          desc.itemSchema
            ? normalize(item, desc.itemSchema)
            : typeConverter(item, desc.itemType)
        )
      );
    case "Object":
      return value ? normalize(value, desc.schema) : undefined;
    default:
      return typeConverter(value, desc.type);
  }
};

const typeConverter = (value, type) => {
  switch (type) {
    case "String": {
      if (value == null) {
        return undefined;
      } else {
        return value.toString ? value.toString() : `${value}`;
      }
    }
    case "Interger":
      return parseInt(value);
    case "Float":
      return parseFloat(value);
    case "Price": {
      if (value == null) {
        return undefined;
      } else {
        const price = value.replace(/\s/i, "");
        return price ? price : undefined;
      }
    }
    default:
      return value;
  }
};

// get nested fields by 'a.b.c' notation
// object = { a: { b: { c: 'value' } } }
export const getValue = (object, key) => {
  if (isFunction(key)) {
    return key(object);
  }

  if (key) {
    const [first, ...last] = key.split(".");
    const option = first.split("||").find((option) => object[option] != null);
    return getValue(object[option], last.join("."));
  } else {
    return object;
  }
};

export const extractUpdates = (updates, old) => {
  const changes = deepObjectsCompare(updates, old);

  // to prevent 422 from backend, supply whole object with updates
  for (const property in changes) {
    if (isObject(changes[property]) && !isArray(changes[property])) {
      changes[property] = { ...old[property], ...updates[property] };
    }
  }

  return removeEmptyProperties(changes);
};

export const getName = (person) => {
  if (person.first_name && person.last_name) {
    return `${person.first_name} ${person.last_name}`;
  }

  if (person.name) return person.name;

  return "Unknown";
};
