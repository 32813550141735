import { push } from "connected-react-router";
import objectAssign from "object-assign";
import { SHIPPED } from "constants/orders/order_statuses";
import { STANDARD_SHIPPING } from "constants/orders/delivery_methods";
import { DISPENSER } from "constants/portalTypes";
import NextAlert from "shared/components/NextAlert";
import { getPortal } from "utils/auth-helpers";
import { randomIdGen } from "utils/helpers";
import { APIModule } from "api";
import { DispenserOrders, DispenserPayments, Dispensers } from "api/dispensers";
import { GET_ONE_ORDER_MODES } from "api/order-helpers";
import * as types from "./constants/actionTypes";
import {
  NEW_ORDER_FETCH_ORDER_REQUEST,
  NEW_ORDER_FETCH_ORDER_SUCCESS,
} from "shared/containers/OrderNew/constants/actionTypes";
import {
  unsuccessfulPaymentText,
  successfulPatientPaymentApproval,
  successfulOrderTransferMsg,
  successfulOrderCompleteMsg,
  successfulSendOutOfNetworkMsg,
  successfulOrderCancelMsg,
  successfulOrderUpdateMsg,
  successPaymentLinkSent,
} from "text/OrderExisting";
import { showPopup } from "shared/containers/Popup/actions";

const getOrderId = (state) => {
  const { order_id } = state.uniform.orders.existingOrderDetails.order;

  return order_id;
};

export const fetch = (order_id) => {
  return (dispatch) => {
    dispatch({ type: NEW_ORDER_FETCH_ORDER_REQUEST });
    const portal = getPortal();

    APIModule(portal)
      .orders.getOne(order_id, [
        GET_ONE_ORDER_MODES.WITH_PATIENT_DETAILS,
        GET_ONE_ORDER_MODES.WITH_DOCTOR_DETAILS,
        GET_ONE_ORDER_MODES.WITH_DOCUMENTS_DETAILS,
      ])
      .then(async (order) => {
        if (portal === DISPENSER) {
          const { labels, dispenser } = await getDetailsForDispenser(order);

          return objectAssign({}, order, labels, { dispenser });
        }

        return objectAssign({}, order);
      })
      .then((order) => {
        dispatch({
          type: NEW_ORDER_FETCH_ORDER_SUCCESS,
          order,
        });
      });
  };
};

export const fetchDispensers = ({ q, skip, limit, filters }) => async (
  dispatch
) => {
  dispatch({ type: types.FETCH_DISPENSERS_REQUEST, q, filters });

  const { count, dispensers } = await APIModule(getPortal()).dispensers.getAll({
    q,
    skip,
    limit,
    filters,
  });

  dispatch({ type: types.FETCH_DISPENSERS_SUCCESS, dispensers, count });
};

export const getDetailsForDispenser = (order) =>
  Dispensers.getOne(order.dispenser_id).then((dispenser) => {
    return Promise.all([
      order.order_status === SHIPPED &&
      order.delivery_method === STANDARD_SHIPPING &&
      !order.shipment_details.carrier_name &&
      order.order_fulfilment !== "Ocusoft"
        ? DispenserOrders.getLabel(order.order_id).catch(() => null)
        : null,
    ]).then(([labels]) => ({ labels, dispenser }));
  });

export const updateOrder = (_id, updates) => (dispatch, getState) => {
  dispatch({ type: types.UPDATE_ORDER_DETAILS_REQUEST });

  const portal = getPortal();
  const order_id = _id || getOrderId(getState());

  if (updates.order_status === SHIPPED)
    return dispatch({
      type: types.UPDATE_ORDER_DETAILS_SUCCESS,
      updates,
    });

  APIModule(portal)
    .orders.submit({ order_id, ...updates })
    .then(() => {
      dispatch({
        type: types.UPDATE_ORDER_DETAILS_SUCCESS,
        updates,
      });
      dispatch(showPopup(successfulOrderUpdateMsg));
    });
};

const customOrderUpdate = ({ APImethod, dataToSend, msgOnUpdate }) => (
  dispatch,
  getState
) => {
  dispatch({ type: types.UPDATE_ORDER_DETAILS_REQUEST });

  const order_id = getOrderId(getState());

  return new Promise((resolve, reject) => {
    APImethod(order_id, dataToSend)
      .then(() => {
        dispatch({
          type: types.UPDATE_ORDER_DETAILS_SUCCESS,
        });
        dispatch(fetchOrderStatusHistory(order_id));
        dispatch(showPopup(msgOnUpdate));
        dispatch(fetch(order_id));
        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const markOrderDelivered = (completionReason = "") =>
  customOrderUpdate({
    APImethod: APIModule(getPortal()).orders.completeOrder,
    dataToSend: completionReason,
    msgOnUpdate: successfulOrderCompleteMsg,
  });

export const sendOutOfNetwork = (sendOutReason = "") =>
  customOrderUpdate({
    APImethod: APIModule(getPortal()).orders.sendOutOfNetwork,
    dataToSend: sendOutReason,
    msgOnUpdate: successfulSendOutOfNetworkMsg,
  });

export const confirmCourierPickup = () =>
  customOrderUpdate({
    APImethod: APIModule(getPortal()).orders.completeOrder,
    dataToSend: null,
    msgOnUpdate: successfulOrderUpdateMsg,
  });

export const cancel = (cancelationReason) => {
  return customOrderUpdate({
    APImethod: APIModule(getPortal()).orders.cancel,
    dataToSend: cancelationReason,
    msgOnUpdate: successfulOrderCancelMsg,
  });
};

export const transferOrder = () =>
  customOrderUpdate({
    APImethod: DispenserOrders.transfer,
    dataToSend: null,
    msgOnUpdate: successfulOrderTransferMsg,
  });

export const confirmPatientApproval = () =>
  customOrderUpdate({
    APImethod: DispenserOrders.confirmPatientApproval,
    dataToSend: null,
    msgOnUpdate: successfulPatientPaymentApproval,
  });

export const sendPaymentLink = () => (dispatch, getState) => {
  dispatch({ type: types.SEND_PAYMENT_LINK_REQUEST });

  const order_id = getOrderId(getState());
  DispenserOrders.sendPaymentLink(order_id)
    .then(() => {
      dispatch({
        type: types.SEND_PAYMENT_LINK_SUCCESS,
      });
      dispatch(showPopup(successPaymentLinkSent));
    })
    .catch(() => {
      dispatch({
        type: types.SEND_PAYMENT_LINK_FAILURE,
      });
    });
};

export const processPayment = () => {
  return (dispatch, getState) => {
    dispatch({ type: types.PROCESS_PAYMENT_IN_ORDER_DETAILS_REQUEST });

    const order_id = getOrderId(getState());
    DispenserPayments.create(order_id)
      .then(() => {
        dispatch({
          type: types.PROCESS_PAYMENT_IN_ORDER_DETAILS_SUCCESS,
          order_id,
        });
        dispatch(fetchOrderStatusHistory(order_id));
        setTimeout(() => {
          NextAlert.show("payment_success");
        }, 500);
      })
      .catch(() => {
        dispatch(showPopup(unsuccessfulPaymentText));
      });
  };
};

export const fetchOrderStatusHistory = (order_id) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_ORDER_STATUS_HISTORY_REQUEST });

    DispenserOrders.getOrderStatusHistory(order_id)
      .then((response) => {
        dispatch({
          type: types.FETCH_ORDER_STATUS_HISTORY_SUCCESS,
          payload:
            response.status_history?.map((sh) => ({
              _id: randomIdGen(),
              ...sh,
            })) || [],
        });
      })
      .catch(() => {
        dispatch({ type: types.FETCH_ORDER_STATUS_HISTORY_FAILURE });
      });
  };
};

export const resetStatusHistory = () => ({
  type: types.RESET_ORDER_STATUS_HISTORY,
});

export function printDocuments(dropcharts) {
  dropcharts.forEach((dropchart) => {
    window.open(`/${getPortal()}/print/dropcharts/${dropchart._id}`, "_blank");
  });
}

export const closeAlert = () => push(`/${getPortal()}`);
