import React from "react";
import { Questions, Contacts, Phone } from "./styles";

const Footer = () => {
  return (
    <>
      <Questions>
        Need to complete your prescription/order or have questions?
      </Questions>
      <div>Please contact us at:</div>
      <Contacts>
        <Phone>(855) 276-8555</Phone>
        <br />
        support@legranderx.com | www.legranderx.com
      </Contacts>
      <p>
        For terms and conditions, privacy policy and more, visit us at
        www.legranderx.com
      </p>
    </>
  );
};

export default Footer;
