import { normalize } from "utils/data-helpers";

export const addressSchema = {
  street_1: { type: "String" },
  street_2: { type: "String" },
  city: { type: "String" },
  state: { type: "String" },
  zip: { type: "String" },
};

export const sameShippingAddress = (billing_address, shipping_address) => {
  if (shipping_address && billing_address) {
    for (let key in billing_address) {
      if (billing_address[key] !== shipping_address[key]) return false;
    }

    return true;
  } else {
    return false;
  }
};

export const addressToServerFormat = (address) => {
  if (!address || !address.street_1) return null;

  return normalize(address, addressSchema);
};

export const isEmptyAddress = (address) => {
  if (!address) return true;

  const streetIsEmpty = !address.street;
  const stateIsEmpty = !address.state;
  const cityIsEmpty = !address.city;
  const zipIsEmpty = !address.zip;

  return (streetIsEmpty && stateIsEmpty) || cityIsEmpty || zipIsEmpty;
};
