import { getRequest, postRequest, patchRequest } from "../../base";
import {
  subscriptionFactory,
  fromServerToClient,
} from "../../subscription-helpers";
import { path } from "../../path-helpers";
import { handleModes, GET_ONE_ORDER_MODES } from "api/order-helpers";

export const getList = (base) => async ({ q = "", skip = 0, limit = 10 }) => {
  const { count, subscriptions } = await getRequest(
    path(base, "subscriptions"),
    {
      q,
      skip,
      limit,
    }
  );

  return {
    count,
    subscriptions: subscriptions.slice(skip, skip + limit),
  };
};

export const getOne = (base) => (subscription_id) => {
  return getRequest(path(base, `subscriptions/${subscription_id}`))
    .then((subscription) => fromServerToClient(subscription_id, subscription))
    .then((subscription) =>
      handleModes(subscription, [
        GET_ONE_ORDER_MODES.WITH_DOCTOR_DETAILS,
        GET_ONE_ORDER_MODES.WITH_PATIENT_DETAILS,
        GET_ONE_ORDER_MODES.WITH_DOCUMENTS_DETAILS,
      ])
    );
};

export const submit = (base) => (order, payment_details, template) => {
  const subscription = subscriptionFactory({
    ...order,
    patient_allergies: order.patient_allergies || [],
    payment_details,
    ...template,
  });

  if (order.order_id)
    return patchRequest(
      path(base, `subscriptions/${template._id}`),
      subscription
    );

  return postRequest(path(base, "subscriptions"), subscription);
};

const SubscriptionsApi = (base) => {
  return {
    getList: getList(base),
    submit: submit(base),
    getOne: getOne(base),
  };
};

export default SubscriptionsApi;
