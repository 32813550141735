import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { formatDateForClient, formatTimeForClient } from "utils/date";
import { phoneToClientFormat } from "utils/formatter";
import { toString as addressToString } from "utils/address-helpers";
import { Wrapper } from "./styles";

const DoctorDetails = ({
  first_name,
  last_name,
  date,
  practice,
  dea_number,
  esigned,
}) => {
  const name = `${[first_name, last_name].join(" ")}`;
  const { phone, fax, npi_number } = practice;

  return (
    <Wrapper>
      <Row>
        <Col md={12}>
          {esigned && (
            <h4 style={{ color: "#414244" }}>
              Electronically Signed by: {name}, {formatTimeForClient(date)},{" "}
              {formatDateForClient(date)}
            </h4>
          )}
          <strong>Prescriber Name:</strong> {name}
          <br />
          <strong>Address:</strong> {addressToString(practice?.address)}
          <br />
          <strong>Phone Number:</strong> {phoneToClientFormat(phone)}
          <br />
          <strong>Fax Number:</strong> {phoneToClientFormat(fax) || "-"}
          <br />
          <strong>NPI:</strong> {npi_number}
          <br />
          <strong>DEA:</strong> {dea_number}
          <br />
          <strong>State Licence #:</strong> {practice?.reseller_licence_number}
          <br />
        </Col>
      </Row>
    </Wrapper>
  );
};

DoctorDetails.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  date: PropTypes.string,
  practice: PropTypes.object,
  dea_number: PropTypes.string,
  esigned: PropTypes.bool,
};

export default DoctorDetails;
