import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";

const defaultState = {
  loading: false,
  showPopup: false,
};

const uiReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_PATIENTS_SUCCESS:
      return objectAssign({}, state, {
        loading: false,
        count: action.count,
      });
    case types.FETCH_PATIENTS_FAILURE:
      return objectAssign({}, state, { loading: false });
    case types.FETCH_PATIENTS_REQUEST:
      return objectAssign({}, state, { loading: true });
    case types.TOGGLE_POPUP:
      return objectAssign({}, state, {
        showPopup: !state.showPopup,
        data: action.data,
      });
    default:
      return state;
  }
};

export default uiReducer;
