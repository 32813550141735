import moment from "moment";

export const parseToDate = (str) => {
  if (!str) return "";

  const date = new Date(Date.parse(str));
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  return date.toLocaleString("en-US", options);
};

const parseDate = (string) => {
  if (!string) return "";

  const parsedDate = Date.parse(string);

  if (parsedDate) return new Date(parsedDate);

  const date = string.split(" ")[0];
  const [year, month, day] = date.split("-");
  return new Date(year, parseInt(month) - 1, day);
};

const getParsedDate = (source) => {
  let date;

  if (typeof source === "string") {
    const parsed = parseDate(source);
    date = parsed.getTime() ? parsed : null;
  } else if (source?.getTime && source.getTime()) {
    date = source;
  }

  return date;
};

export const toLocaleDateString = (source = new Date()) => {
  const date = getParsedDate(source);

  const dateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  return date ? date.toLocaleDateString("en-US", dateOptions) : "";
};

export const toLocaleTimeString = (source = new Date()) => {
  const date = getParsedDate(source);

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return date ? date.toLocaleString("en-US", timeOptions) : "";
};

/*
  @param source date, i.e 2020-10-25T15:57:10.655000
  @return string with weekday and date, i.e. Sun Oct 25 2020
 */
export const toWeekdayAndDateString = (source) => {
  const date = parseDate(source);
  if (date) {
    return date.toDateString();
  } else {
    return "";
  }
};

export const toServerFormat = (str) => {
  if (str) {
    return str.replace(/T\d{2}:\d{2}:\d{2}\.\d{3}Z/, "").split(" ")[0];
  }
  return null;
};

export const formatDateString = (string, format) => {
  return (
    string && moment(string, '"MM/DD/YYYY"').format(format || "MM/DD/YYYY")
  );
};

export const birthdayToClientFormat = (string) => {
  if (!string) return "-";

  const [year, month, day] = string.split("-");
  return `${month}/${day}/${year}`;
};

export const minsToMilliseconds = (mins = 15) => 1000 * 60 * mins;

export const formatDateForClient = (string) => {
  if (!string) return "";

  const delimiter = string.includes("T") ? "T" : " ";
  const comp = string.split(delimiter)[0].split("-");
  return `${comp[1]}/${comp[2]}/${comp[0]}`;
};

export const formatTimeForClient = (string) => {
  if (!string) return "";

  const delimiter = string.includes("T") ? "T" : " ";
  const timeComp = string.split(delimiter)[1];
  let [hour, minute] = timeComp?.slice(0, timeComp.indexOf(".")).split(":");

  const dayPart = hour < 12 ? "AM" : "PM";

  if (parseInt(hour, 10) > 12) {
    hour = hour - 12;
  }

  return `${hour}:${minute} ${dayPart}`;
};

export const toClientDateaAndTimeFormat = (source) =>
  `${formatDateForClient(source)} (${formatTimeForClient(source)})`;
