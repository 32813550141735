import practice_dispenser_shared_apis from "./practice-dispenser";
import { MASTER, PRACTICE, PATIENT, DISPENSER } from "constants/portalTypes";

/**
 * List of valid portal types
 */
const validPortalTypes = [MASTER, PRACTICE, PATIENT, DISPENSER];

/**
 * Function to access the shared apis between services.
 * Modify the common function to add more shared apis
 * between different services.
 *
 * @param {string} portalType Portal type of the active portal
 * @param {string} services services to be fetched
 *
 * @returns {object} Returns an object containing the api services
 */
const commonApiServices = (portalType, services) => {
  if (validPortalTypes.indexOf(portalType) >= 0) {
    if (
      portalType === DISPENSER ||
      portalType === PRACTICE ||
      portalType === MASTER
    ) {
      const getApis = practice_dispenser_shared_apis[services];
      return getApis(portalType);
    } else {
      throw new Error(`No common api's defined for ${portalType}`);
    }
  } else {
    throw new Error(`Invalid portal type`);
  }
};

export default commonApiServices;
