import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { FormikInput as Input } from "shared/components/Input";
import {
  emailRequired,
  requiredMessage,
  getPropsForFormikField,
} from "utils/validations";

const validationSchema = yup.object({
  email: emailRequired(),
  password: yup.string().required(requiredMessage),
});

const initialValues = { email: "", password: "" };

const labelStyle = { color: "#0e2439" };

const ErrorMessage = styled.p`
  color: #d0021b;
`;

class LoginForm extends PureComponent {
  render() {
    const { submitting, error, submit } = this.props;

    return (
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          const { handleSubmit } = formikProps;
          const getFieldProps = getPropsForFormikField(formikProps);
          return (
            <form onSubmit={handleSubmit}>
              <ErrorMessage>{error}</ErrorMessage>
              <Field
                component={Input}
                label="Email Address"
                labelStyle={labelStyle}
                {...getFieldProps("email")}
              />
              <Field
                component={Input}
                type="password"
                label="Password"
                labelStyle={labelStyle}
                {...getFieldProps("password")}
              />

              <div className="signinbtn">
                <input
                  type="submit"
                  className="btn btn-lg btn-primary physician_login_button"
                  value="SIGN IN"
                  disabled={submitting}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

LoginForm.propTypes = {
  submitting: PropTypes.bool,
  error: PropTypes.string,
  submit: PropTypes.func,
};

export default LoginForm;
