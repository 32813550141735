import React from "react";
import { Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import Loading from "shared/components/Loading";
import { redirectPath } from "../utils/auth-helpers";

const DispenserApp = Loadable({
  loader: () => import("shared/containers/AppPracticeDispenser"),
  loading: Loading,
});

const DispenserPortal = (params) => {
  const { location } = params;
  const path = redirectPath("dispenser");

  if (location.pathname.includes("pharmacy")) {
    return <Redirect to={"/dispenser"} />;
  }

  if (path) {
    return <Redirect to={path} />;
  } else {
    return <DispenserApp {...params} />;
  }
};

export default DispenserPortal;
