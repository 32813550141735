import * as types from "../actionTypes";
import { address } from "constants/defaults/paymentAndShipping";
import { prepareHealthInsurance } from "utils/payment-helpers";

const defaultState = {
  first_name: "",
  last_name: "",
  email_address: "",
  phone: "",
  date_of_birth: "",
  address: {
    ...address,
  },
  billing_address: {
    ...address,
  },
  health_insurance: prepareHealthInsurance(),
  allergies: [],
};

const patientFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_ONE_PATIENT_REQUEST:
    case types.RESET_PATIENT_FORM:
      return defaultState;
    case types.FETCH_ONE_PATIENT_SUCCESS: {
      const { address, billing_address, health_insurance } = action.payload;
      return {
        ...action.payload,
        address: address || defaultState.address,
        billing_address: billing_address || defaultState.billing_address,
        health_insurance: prepareHealthInsurance(health_insurance),
      };
    }

    default:
      return state;
  }
};

export default patientFormReducer;
