import styled from "styled-components";

const color = "#0e2439";

export const Title = styled.div`
  font-size: 22px;
  margin-bottom: 8px;
  color: ${color};
`;

export const Contacts = styled.div`
  margin-bottom: 10px;
  font-weight: 600;
  color: ${color};
`;
