export const PAYMENT_AND_SHIPPING_CARD_UPDATE =
  "PAYMENT_AND_SHIPPING_CARD_UPDATE";
export const PAYMENT_AND_SHIPPING_SHIPPING_ADDRESS_UPDATE =
  "PAYMENT_AND_SHIPPING_SHIPPING_ADDRESS_UPDATE";
export const PAYMENT_AND_SHIPPING_BILLING_ADDRESS_UPDATE =
  "PAYMENT_AND_SHIPPING_BILLING_ADDRESS_UPDATE";
export const PAYMENT_AND_SHIPPING_INSURANCE_UPDATE =
  "PAYMENT_AND_SHIPPING_INSURANCE_UPDATE";

export const PAYMENT_AND_SHIPPING_ADD_INSURANCE =
  "PAYMENT_AND_SHIPPING_ADD_INSURANCE";

export const PAYMENT_AND_SHIPPING_REMOVE_INSURANCE =
  "PAYMENT_AND_SHIPPING_REMOVE_INSURANCE";

export const PAYMENT_AND_SHIPPING_BULK_UPDATE =
  "PAYMENT_AND_SHIPPING_BULK_UPDATE";

export const PAYMENT_AND_SHIPPING_VALIDATE = "PAYMENT_AND_SHIPPING_VALIDATE";
export const INSURANCE_VALIDATE = "INSURANCE_VALIDATE";

export const PAYMENT_AND_SHIPPING_RESET_ALL = "PAYMENT_AND_SHIPPING_RESET_ALL";
export const PAYMENT_AND_SHIPPING_RESET = "PAYMENT_AND_SHIPPING_RESET";
export const INSURANCE_RESET = "INSURANCE_RESET";

export const TOGGLE_POPUP_VISIBILITY = "TOGGLE_POPUP_VISIBILITY";
