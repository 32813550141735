import { removeById } from "utils/state-helpers";
import * as types from "../constants/actionTypes";

const templatesReducer = (state = null, action) => {
  switch (action.type) {
    case types.FETCH_TEMPLATES_SUCCESS:
      return action.templates;
    case types.REMOVE_TEMPLATE_SUCCESS:
      return removeById(state, action.id, "_id");
    default:
      return state;
  }
};

export default templatesReducer;
