import {
  usersPureGetRequest,
  usersPostRequest,
  usersPurePostRequest,
  usersPutRequest,
} from "./base";

export const resetPassword = (params) => {
  return usersPutRequest("reset", params);
};

export const login = (email, password) => {
  return usersPurePostRequest("login", {
    login: email,
    password: password,
  });
};

export const checkToken = (token) => {
  return usersPureGetRequest("checkToken", { token });
};

export const checkEmailUniqueness = (email) => {
  return usersPurePostRequest("email_validation", { email });
};

export const confirm = (token, password) => {
  return usersPostRequest("confirm", { token, password });
};

export const recover = (token, password) => {
  return usersPostRequest("recover", { token, password });
};

export const forgotPassword = (email) => {
  return usersPurePostRequest("forgot", { email });
};

export const setPassword = (token, password) => {
  return usersPurePostRequest("recovery", { token, password });
};

export const logout = () => {
  return usersPostRequest("logout");
};

export const requestAccessToken = (token) => {
  return usersPostRequest("access_token", { token });
};
