import React from "react";
import PropTypes from "prop-types";
import Paper from "shared/components/Paper";

class Label extends React.Component {
  render() {
    const { label_url } = this.props;

    return (
      <Paper trigger={Boolean(label_url)}>
        <object data={label_url} type="application/pdf">
          <embed src={label_url} type="application/pdf" />
        </object>
      </Paper>
    );
  }
}

Label.propTypes = {
  params: PropTypes.object,
  label_url: PropTypes.string,
  file_url: PropTypes.string,
  fetch: PropTypes.func,
};

export default Label;
