import * as PatientCareTeam from "./care_team";
import * as PatientDetails from "./details";
import * as PatientOrders from "./orders";
import * as PatientTerms from "./terms_and_conditions";

export { PatientCareTeam, PatientDetails, PatientOrders, PatientTerms };

const PatientApi = {
  care_team: PatientCareTeam,
  details: PatientDetails,
  orders: PatientOrders,
  terms: PatientTerms,
};

export default PatientApi;
