export const FETCH_ONE_SUBSCRIPTION_REQUEST = "FETCH_ONE_SUBSCRIPTION_REQUEST";
export const FETCH_ONE_SUBSCRIPTION_SUCCESS = "FETCH_ONE_SUBSCRIPTION_SUCCESS";
export const FETCH_ONE_SUBSCRIPTION_FAILURE = "FETCH_ONE_SUBSCRIPTOIN_FAILURE";

export const SUBMIT_SUBSCRIPTION_REQUEST = "SBUMIT_SUBSCRIPTION_REQUEST";
export const SUBMIT_SUBSCRIPTION_SUCCESS = "SUBMIT_SBUSCRIPTION_SUCCESS";
export const SUBMIT_SUBSCRIPTION_FAILURE = "SUBMIT_SUBSCRIPTION_FAILURE";

export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";

export const NEW_SUBSCRIPTION_ADD_BOX = "NEW_SUBSCRIPTION_ADD_BOX";
export const NEW_SUBSCRIPTION_REMOVE_BOX = "NEW_SUBSCRIPTION_REMOVE_BOX";

export const NEW_SUBSCRIPTION_ADD_PRODUCT_TO_BOX_REQUEST =
  "NEW_SUBSCRIPTION_ADD_PRODUCT_TO_BOX_REQUEST";
export const NEW_SUBSCRIPTION_ADD_PRODUCT_TO_BOX_SUCCESS =
  "NEW_SUBSCRIPTION_ADD_PRODUCT_TO_BOX_SUCCESS";
export const NEW_SUBSCRIPTION_ADD_PRODUCT_TO_BOX_FAILURE =
  "NEW_SUBSCRIPTION_ADD_PRODUCT_TO_BOX_FAILURE";

export const NEW_SUBSCRIPTION_REMOVE_PRODUCT_FROM_BOX =
  "NEW_SUBSCRIPTION_REMOVE_PRODUCT_FROM_BOX";
export const NEW_SUBSCRIPTION_UPDATE_PRODUCT_IN_BOX =
  "NEW_SUBSCRIPTION_UPDATE_PRODUCT_IN_BOX";

export const NEW_SUBSCRIPTION_SEARCH_PRODUCT_REQUEST =
  "NEW_SUBSCRIPTION_SEARCH_PRODUCT_REQUEST";
export const NEW_SUBSCRIPTION_SEARCH_PRODUCT_SUCCESS =
  "NEW_SUBSCRIPTION_SEARCH_PRODUCT_SUCCESS";

export const UPDATE_SHIPMENT_DATE_REQUEST = "UPDATE_SHIPMENT_DATE_REQUEST";
export const UPDATE_SHIPMENT_DATE_SUCCESS = "UPDATE_SHIPMENT_DATE_SUCCESS";
export const UPDATE_SHIPMENT_DATE_FAILURE = "UPDATE_SHIPMENT_DATE_FAILURE";

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const RESET_SUBSCRIPTION = "RESET_SUBSCRIPTION";

export const FETCH_SUBSCRIPTIONS_REQUEST = "FETCH_SUBSCRIPTIONS_REQUEST";
export const FETCH_SUBSCRIPTIONS_SUCCESS = "FETCH_SUBSCRIPTIONS_SUCCESS";
export const FETCH_SUBSCRIPTIONS_FAILURE = "FETCH_SUBSCRIPTOINS_FAILURE";

export const UPDATE_ALLERGIES_REQUEST = "UPDATE_ALLERGIES_REQUEST";
export const UPDATE_ALLERGIES_SUCCESS = "UPDATE_ALLERGIES_SUCCESS";
export const UPDATE_ALLERGIES_FAILURE = "UPDATE_ALLERGIES_FAILURE";
