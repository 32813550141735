import React from "react";
import { Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import Loading from "shared/components/Loading";
import { redirectPath } from "utils/auth-helpers";

const MasterApp = Loadable({
  loader: () => import("./containers/App"),
  loading: Loading,
});

const MasterPortal = (params) => {
  const path = redirectPath("master");
  if (path) {
    return <Redirect to={path} />;
  } else {
    return <MasterApp {...params} />;
  }
};

export default MasterPortal;
