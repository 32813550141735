import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ThemeProvider } from "styled-components";
import App from "./App";

const theme = {
  colors: {
    primary: "#c6aa76",
    success: "#0e2439",
    info: "#fff",
    error: "#e74c3c",
  },
  mediaSizes: {
    xsMin: 480,
    smMin: 768,
    mdMin: 992,
    lgMin: 1200,

    xsMax: 767,
    smMax: 991,
    mdMax: 1199,
  },
  grid: {
    columns: 12,
    gutterWidth: 30,
  },
  containerWidth: {
    sm: 720,
    md: 940,
    lg: 1140,
  },
};

class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Root;
