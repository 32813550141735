import React from "react";
import PropTypes from "prop-types";
import ProductImage from "shared/components/ProductImage";
import { priceToString } from "utils/price-helpers";
import styled from "styled-components";

export const ProductsContentRow = styled.tr`
  td {
    vertical-align: middle !important;
    text-align: left;

    &:nth-child(4) {
      text-align: center;
    }
  }
`;

const Product = ({ product_name, image_url, amount, strength, price }) => (
  <ProductsContentRow>
    <td>
      <ProductImage image_url={image_url} />
    </td>
    <td>{product_name}</td>
    <td>{strength}</td>
    <td>{amount}</td>
    <td>{priceToString(price)}</td>
  </ProductsContentRow>
);

Product.propTypes = {
  product_name: PropTypes.string,
  image_url: PropTypes.string,
  amount: PropTypes.number,
  strength: PropTypes.string,
  price: PropTypes.number,
  pharmacy_name: PropTypes.string,
  pharmacy_phone: PropTypes.string,
};

export default Product;
