import { get } from "lodash";

// TODO: use it with caution
export const getOrderFlow = (state) => get(state, "uniform.orders.new");

export const getOrder = (state) => get(state, "uniform.orders.new.order");

export const getOrderType = (state) =>
  get(state, "uniform.orders.new.order.type");

export const getPatient = (state) => get(state, "uniform.orders.new.patient");

export const getDoctor = (state) => get(state, "uniform.orders.new.doctor");

export const getNotes = (state) => get(state, "uniform.orders.notes");

export const getPaymentAndShipping = (state) =>
  get(state, "uniform.orders.payment_and_shipping");

export const getTemplate = (state) => get(state, "uniform.orders.new.template");

export const getUI = (state) => ({
  ...getPaymentAndShipping(state).ui,
  ...getOrderFlow(state).ui,
});
