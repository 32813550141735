import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px;
  vertical-align: center;
  text-align: center;
  border: 2px solid #e2e2e2;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
