import * as MasterDispensers from "./dispensers";
import * as MasterPractices from "./practices";
import * as MasterProducts from "./products";
import * as MasterUsers from "./users";
import * as MasterPayors from "./payors";

export { MasterDispensers, MasterPractices, MasterProducts, MasterPayors };

const MasterApi = {
  dispensers: MasterDispensers,
  practices: MasterPractices,
  products: MasterProducts,
  users: MasterUsers,
  payors: MasterPayors,
};

export default MasterApi;
