import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";

const defaultState = {
  popup: {
    show: false,
    text: "",
    yesAction: null,
    dismissText: "",
  },
};

const queueReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SHOW_QUEUE_POPUP:
      return objectAssign({}, state, {
        popup: {
          show: true,
          text: action.text,
          yesAction: action.yesAction,
          dismissText: action.dismissText,
        },
      });
    case types.DISMISS_QUEUE_POPUP:
      return objectAssign({}, state, { popup: { show: false, text: "" } });
    default:
      return state;
  }
};

export default queueReducer;
