import { combineReducers } from "redux";

import prescriptionReducer from "../containers/Prescription/reducer";
import orderLabelReducer from "../containers/OrderLabel/reducer";
import receiptReducer from "../containers/Receipt/reducer";

import productsListReducer from "../containers/ProductsList/reducer";

import settingsReducer from "../containers/Settings/reducer";

const rootReducer = combineReducers({
  prescription: prescriptionReducer,
  orderLabel: orderLabelReducer,
  receipt: receiptReducer,

  products: productsListReducer,

  settings: settingsReducer,
});

export default rootReducer;
