import React, { Component } from "react";
import PropTypes from "prop-types";

class Fetching extends Component {
  componentDidMount() {
    const {
      id_field,
      match: { params },
      fetch,
    } = this.props;

    if (params[id_field]) {
      fetch(params[id_field]);
    }
  }

  render() {
    const { WrappedComponent, ...props } = this.props;
    return <WrappedComponent {...props} />;
  }
}

Fetching.propTypes = {
  WrappedComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
    .isRequired,
  id_field: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
};

export default Fetching;
