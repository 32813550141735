import * as types from "../constants/actionTypes";

export default (state = [], action) => {
  switch (action.type) {
    case types.FETCH_ORDERS_SUCCESS:
    case types.SORT_ORDERS_SUCCESS:
      return action.orders;
    default:
      return state;
  }
};
