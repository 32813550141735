import { createStore, compose, applyMiddleware } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import createRootReducer from "../reducers";
import rootSaga from "../sagas";

export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();

const isDev = process.env.NODE_ENV === "development";

let middlewares = [
  // thunk middleware can also accept an extra argument to be passed to each thunk action
  // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
  thunk,
  sagaMiddleware,
  routerMiddleware(history),
];

let composeEnhancers = compose;

if (isDev) {
  middlewares = [
    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),
    ...middlewares,
  ];
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

// add support for Redux dev tools
const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(...middlewares))
);

if (isDev && module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept("../reducers", () => {
    const nextReducer = require("../reducers").default; // eslint-disable-line global-require
    store.replaceReducer(nextReducer);
  });
}

sagaMiddleware.run(rootSaga);

export default store;
