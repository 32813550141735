import { connect } from "react-redux";
import { printDocuments } from "shared/containers/OrderDetailsPage/actions";

import OrderDropchartsList from "./OrderDropchartsList";

const mapStateToProps = (state) => ({
  location: state.router.location,
  order_status: state.uniform.orders.existingOrderDetails.order?.order_status,
});

const mapDispatchToProps = {
  onPrintDocuments: printDocuments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDropchartsList);
