export const PRACTICE_DASHBOARD_FETCH_REQUEST =
  "PRACTICE_DASHBOARD_FETCH_REQUEST";
export const PRACTICE_DASHBOARD_FETCH_SUCCESS =
  "PRACTICE_DASHBOARD_FETCH_SUCCESS";
export const PRACTICE_DASHBOARD_FETCH_FAILURE =
  "PRACTICE_DASHBOARD_FETCH_FAILURE";

export const APPROVE_SEVERAL_REQUEST = "APPROVE_SEVERAL_REQUEST";
export const APPROVE_SEVERAL_SUCCESS = "APPROVE_SEVERAL_SUCCESS";
export const APPROVE_SEVERAL_FAILURE = "APPROVE_SEVERAL_FAILURE";

export const APPROVE_SINGLE_REQUEST = "APPROVE_SINGLE_REQUEST";
export const APPROVE_SINGLE_SUCCESS = "APPROVE_SINGLE_SUCCESS";
export const APPROVE_SINGLE_FAILURE = "APPROVE_SINGLE_FAILURE";

export const SHOW_QUEUE_POPUP = "SHOW_QUEUE_POPUP";
export const DISMISS_QUEUE_POPUP = "DISMISS_QUEUE_POPUP";
