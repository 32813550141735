export const unsuccessfulPaymentText =
  "Sorry, payment hasn't been successfully processed! Please change payment details or try to process later.";
export const successfulPatientPaymentApproval =
  "Patient payment approval has been sucessfully captured.";
export const successfulOrderTransferMsg =
  "This order has been successfully transferred.";
export const successfulOrderCompleteMsg =
  "You have successfully completed this order";
export const successfulOrderCancelMsg =
  "You have successfully canceled this order";
export const successfulSendOutOfNetworkMsg =
  "You have successfully sent this order out of network";
export const successfulOrderUpdateMsg = "Order successfully updated.";
export const successPaymentLinkSent =
  "An email containing payment link has been sent to the patient successfully";
