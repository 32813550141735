import { patientsGetRequest, patientsPutRequest } from "./base";
import { fromServerToClient } from "../order-helpers";

export const getAll = (patient_id) => {
  return patientsGetRequest(`${patient_id}/orders`);
};

export const getOne = (patient_id, order_id) => {
  return patientsGetRequest(`${patient_id}/orders/${order_id}`).then((order) =>
    fromServerToClient(order_id, order)
  );
};

export const updateFeedback = (order_id, params) => {
  return patientsPutRequest(`orders/${order_id}`, params);
};
