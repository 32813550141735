export const loginPath = (role = "practice") => {
  let portal;
  switch (role) {
    case "doctor": {
      portal = "practice";
      break;
    }
    case "pharmacist": {
      portal = "dispenser";
      break;
    }
    default:
      portal = role;
  }
  return `/login/${portal}`;
};

export const createPromiseWithTimeout = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};
