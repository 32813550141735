import commonApiServices from "../shared";
import { practiceEndpoint } from "../path-helpers";
import { postRequest, deleteRequest } from "../base";
import { PRACTICE } from "constants/portalTypes";

export const submit = (params) =>
  postRequest(practiceEndpoint("dropcharts"), params);

export const deleteOne = (id) =>
  deleteRequest(practiceEndpoint(`dropcharts/${id}`));

export const { getAll, getOne } = commonApiServices(PRACTICE, "dropcharts");
