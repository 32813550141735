import { updateById } from "utils/state-helpers";
import * as actionTypes from "../constants/actionTypes";
import { NEW_ORDER_REQUEST } from "shared/containers/OrderNew/constants/actionTypes";
import { LOAD_FROM_CACHE } from "../../../../modules/local-cache/actions";

export default (state = [], action) => {
  switch (action.type) {
    case NEW_ORDER_REQUEST:
    case actionTypes.FETCH_ORDER_NOTES_REQUEST:
      return [];
    case actionTypes.FETCH_ORDER_NOTES_SUCCESS:
      return action.notes;
    case actionTypes.CREATE_ORDER_NOTE_SUCCESS: {
      const allNotes = state ? [...state] : [];
      allNotes.push(action.note);
      return allNotes;
    }
    case actionTypes.UPDATE_ORDER_NOTE_SUCCESS: {
      const notes = updateById(state, action.note_id, action.updates);
      return notes;
    }
    case LOAD_FROM_CACHE:
      return action.state.notes;
    default:
      return state;
  }
};
