import { takeLatest, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { getPortal } from "utils/auth-helpers";
import { update } from "./actions";
import { CREATE_ORDER_FOR_NEW_PATIENT } from "./constants/actionTypes";

function* createOrderForPatient({ patient_id }) {
  yield put(push(`/${getPortal()}/orders/new`));
  yield put(update({ patient_id }));
}

function* newOrderSagas() {
  yield takeLatest(CREATE_ORDER_FOR_NEW_PATIENT, createOrderForPatient);
}

export default newOrderSagas;
