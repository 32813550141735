import * as types from "../constants/actionTypes";

const defaultState = { loading: false, count: 0 };

const uiReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_USERS_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        count: action.count,
        loading: false,
      };
    case types.FETCH_USERS_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default uiReducer;
