import { combineReducers } from "redux";
import queueReducer from "./queueReducer";
import ordersReducer from "./ordersReducer";
import uiReducer from "./uiReducer";

export default combineReducers({
  queue: queueReducer,
  orders: ordersReducer,
  ui: uiReducer,
});
