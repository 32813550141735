import * as types from "../constants/actionTypes";
import { removeById } from "utils/state-helpers";
import { updateArrayItemById } from "utils/product-helpers";

export const prepareOrganization = (organization) => ({
  _id: organization._id,
  name: organization.name,
  email: organization.email,
  phone: organization.phone,
  status: organization.is_active ? "Active" : "Not active",
});

const dispensersReducer = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_DISPENSERS_IN_ADMIN_SUCCESS: {
      return action.dispensers.map((d) => prepareOrganization(d));
    }
    case types.SUBMIT_DISPENSER_IN_ADMIN_REQUEST:
    case types.SUBMIT_DISPENSER_IN_ADMIN_SUCCESS:
    case types.UPDATE_DISPENSER_IN_ADMIN:
      return updateArrayItemById(
        state,
        action.dispenser_id,
        action.updates,
        "dispenser_id"
      );
    case types.DELETE_DISPENSER_IN_ADMIN_SUCCESS:
      return removeById(state, action.dispenser_id, "dispenser_id");
    case types.FETCH_DISPENSERS_IN_ADMIN_REQUEST:
      return [];
    default:
      return state;
  }
};

export default dispensersReducer;
