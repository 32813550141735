export const UPDATE_ORDER_DETAILS_REQUEST = "UPDATE_ORDER_DETAILS_REQUEST";
export const UPDATE_ORDER_DETAILS_SUCCESS = "UPDATE_ORDER_DETAILS_SUCCESS";

export const PROCESS_PAYMENT_IN_ORDER_DETAILS_REQUEST =
  "PROCESS_PAYMENT_IN_ORDER_DETAILS_REQUEST";
export const PROCESS_PAYMENT_IN_ORDER_DETAILS_SUCCESS =
  "PROCESS_PAYMENT_IN_ORDER_DETAILS_SUCCESS";
export const PROCESS_PAYMENT_IN_ORDER_DETAILS_FAILURE =
  "PROCESS_PAYMENT_IN_ORDER_DETAILS_FAILURE";

export const CREATE_ORDER_NOTE_REQUEST =
  "CREATE_ORDER_NOTE_REQUEST_IN_EXISTING_ORDER";
export const CREATE_ORDER_NOTE_SUCCESS =
  "CREATE_ORDER_NOTE_SUCCESS_IN_EXISTING_ORDER";
export const CREATE_ORDER_NOTE_FAILURE =
  "CREATE_ORDER_NOTE_FAILURE_IN_EXISTING_ORDER";

export const UPDATE_ORDER_NOTE_REQUEST =
  "UPDATE_ORDER_NOTE_REQUEST_IN_EXISTING_ORDER";
export const UPDATE_ORDER_NOTE_SUCCESS =
  "UPDATE_ORDER_NOTE_SUCCESS_IN_EXISTING_ORDER";
export const UPDATE_ORDER_NOTE_FAILURE =
  "UPDATE_ORDER_NOTE_FAILURE_IN_EXISTING_ORDER";

export const UPDATE_ORDER_LABEL = "UPDATE_ORDER_LABEL";

export const FETCH_ORDER_LABEL_RATES_REQUEST =
  "FETCH_ORDER_LABEL_RATES_REQUEST";
export const FETCH_ORDER_LABEL_RATES_SUCCESS =
  "FETCH_ORDER_LABEL_RATES_SUCCESS";
export const FETCH_ORDER_LABEL_RATES_FAILURE =
  "FETCH_ORDER_LABEL_RATES_FAILURE";

export const CREATE_ORDER_LABEL_RATES_REQUEST =
  "CREATE_ORDER_LABEL_RATES_REQUEST";
export const CREATE_ORDER_LABEL_RATES_SUCCESS =
  "CREATE_ORDER_LABEL_RATES_SUCCESS";
export const CREATE_ORDER_LABEL_RATES_FAILURE =
  "CREATE_ORDER_LABEL_RATES_FAILURE";

export const FETCH_DISPENSERS_REQUEST = "FETCH_DISPENSERS_REQUEST";
export const FETCH_DISPENSERS_SUCCESS = "FETCH_DISPENSERS_SUCCESS";

export const FETCH_ORDER_STATUS_HISTORY_REQUEST =
  "FETCH_ORDER_STATUS_HISTORY_REQUEST";
export const FETCH_ORDER_STATUS_HISTORY_SUCCESS =
  "FETCH_ORDER_STATUS_HISTORY_SUCCESS";
export const FETCH_ORDER_STATUS_HISTORY_FAILURE =
  "FETCH_ORDER_STATUS_HISTORY_REQUEST";

export const RESET_ORDER_STATUS_HISTORY = "RESET_ORDER_STATUS_HISTORY";

export const FETCH_CARRIERS_REQUEST = "FETCH_CARRIERS_REQUEST";
export const FETCH_CARRIERS_SUCCESS = "FETCH_CARRIERS_SUCCESS";
export const FETCH_CARRIERS_FAILURE = "FETCH_CARRIERS_FAILURE";

export const SEND_PAYMENT_LINK_REQUEST = "SEND_PAYMENT_LINK_REQUEST";
export const SEND_PAYMENT_LINK_SUCCESS = "SEND_PAYMENT_LINK_SUCCESS";
export const SEND_PAYMENT_LINK_FAILURE = "SEND_PAYMENT_LINK_FAILURE";
