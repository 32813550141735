import React from "react";
import PrintableWindow from "./PrintableWindow";

/**
 * This Higher Order Component helps to print the window, based on
 * a trigger prop. When this prop of the WrapperdComponent changes
 * to a truthy value, the window print is triggered.
 *
 * @param {Component} WrappedComponent The Component to be printed
 * @param {string} trigger  The name of the prop which triggers window print
 */
const withPrintableWindow = (WrappedComponent, trigger) => {
  const Wrapper = (props) => (
    <PrintableWindow
      {...props}
      WrappedComponent={WrappedComponent}
      trigger={trigger}
    />
  );

  return Wrapper;
};

export default withPrintableWindow;
