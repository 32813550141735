import { combineReducers } from "redux";
import modalsReducer from "./modalsReducer";
import confirmReducer from "../containers/ConfirmOrRecover/reducer";
import resetPasswordReducer from "../containers/ResetPassword/reducer";
// import customerPageReducer from "../containers/CustomerPages/reducer";

const rootReducer = combineReducers({
  confirmOrRecover: confirmReducer,
  resetPassword: resetPasswordReducer,
  /*   customerPages: customerPageReducer, */
  modals: modalsReducer,
});

export default rootReducer;
