import styled from "styled-components";

export const Wrapper = styled.footer`
  background-color: #516675;
  margin-top: 40px;
  padding-top: 52px;
  padding-bottom: 52px;
  color: #fff;

  @media screen and (max-width: 699px) and (min-width: 360px) {
    text-align: center;
    padding-top: 32px;
    padding-bottom: 22px;
  }
`;

export const Logo = styled.img.attrs({
  src: "/assets/legrande-health.svg",
})`
  margin: 0 0 25px;

  @media screen and (max-width: 699px) and (min-width: 360px) {
    margin: 0 10px 25px;
  }
`;

export const ContactUs = styled.div`
  h4 {
    text-transform: uppercase;
    color: #fff;
  }

  a {
    display: block;
  }
`;
