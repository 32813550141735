import objectAssign from "object-assign";

import * as types from "../constants/actionTypes";

const defaultState = {
  count: 0,
  loading: false,
};

const uiReducer = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case types.FETCH_PRACTICES_IN_ADMIN_REQUEST:
      return objectAssign({}, state, { loading: true });
    case types.FETCH_PRACTICES_IN_ADMIN_SUCCESS:
      return objectAssign({}, state, {
        count: action.count,
        loading: false,
      });
    default:
      return state;
  }
};

export default uiReducer;
