import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./index.scss";

class ProductImage extends PureComponent {
  render() {
    const { image_url, size } = this.props;

    return (
      <img
        className="ProductImage"
        src={image_url}
        style={{
          width: size,
          height: size,
        }}
      />
    );
  }
}

ProductImage.propTypes = {
  image_url: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ProductImage.defaultProps = {
  size: 64,
};

export default ProductImage;
