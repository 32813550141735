export const UPDATE_LOGIN_PAGE = "UPDATE_LOGIN_PAGE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

export const RESET_LOGIN_STATE = "RESET_LOGIN_STATE";

export const TOGGLE_RESET_PASSWORD = "TOGGLE_RESET_PASSWORD";
export const UPDATE_RESET_PASSWORD = "UPDATE_RESET_PASSWORD";

export const SUBMIT_RESET_PASSWORD_REQUEST = "SUBMIT_RESET_PASSWORD_REQUEST";
export const SUBMIT_RESET_PASSWORD_SUCCESS = "SUBMIT_RESET_PASSWORD_SUCCESS";
export const SUBMIT_RESET_PASSWORD_FAILURE = "SUBMIT_RESET_PASSWORD_FAILURE";

export const TOGGLE_CONTACT_US = "TOGGLE_CONTACT_US";
export const UPDATE_CONTACT_US = "UPDATE_CONTACT_US";

export const SUBMIT_CONTACT_US_REQUEST = "SUBMIT_CONTACT_US_REQUEST";
export const SUBMIT_CONTACT_US_SUCCESS = "SUBMIT_CONTACT_US_SUCCESS";
export const SUBMIT_CONTACT_US_FAILURE = "SUBMIT_CONTACT_US_FAILURE";

export const TOGGLE_SHOW_TERMS_AND_POLICY_AGREEMENT_POPUP =
  "TOGGLE_SHOW_TERMS_AND_POLICY_AGREEMENT_POPUP";

export const SUBMIT_TERMS_AND_POLICY_AGREEMENT_REQUEST =
  "SUBMIT_TERMS_AND_POLICY_AGREEMENT_REQUEST";
export const SUBMIT_TERMS_AND_POLICY_AGREEMENT_SUCCESS =
  "SUBMIT_TERMS_AND_POLICY_AGREEMENT_SUCCESS";
