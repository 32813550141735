import * as types from "../constants/actionTypes";

const usersReducer = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_USERS_SUCCESS:
      return action.users;
    default:
      return state;
  }
};

export default usersReducer;
