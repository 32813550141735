import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withFetching from "shared/containers/withFetching";
import OrderConfirmation from "shared/components/Print/OrderConfirmation";
import * as actions from "./actions";

const mapStateToProps = (state) => state.practice.orderConfirmation;

const OrderConfirmationWithFetching = withFetching(
  OrderConfirmation,
  "order_id"
);

export default withRouter(
  connect(mapStateToProps, actions)(OrderConfirmationWithFetching)
);
