import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { HintWrapper, HintText, HintImage } from "./styles";

class Hint extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
  }

  handleClick() {
    const { hint, onSelect } = this.props;

    onSelect(hint.id, hint);
  }

  handleMouseOver() {
    const { onChangeActive, index } = this.props;
    onChangeActive(index);
  }

  render() {
    const {
      hint: { image, text },
      active,
      testID,
    } = this.props;

    return (
      <HintWrapper
        className="Hint"
        active={active}
        onClick={this.handleClick}
        onMouseOver={this.handleMouseOver}
        data-test={testID}
      >
        {image ? <HintImage className="Hint" image={image} /> : null}
        <HintText className="Hint">{text}</HintText>
      </HintWrapper>
    );
  }
}

Hint.propTypes = {
  hint: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string,
  }),
  onSelect: PropTypes.func,
  active: PropTypes.bool,
  onChangeActive: PropTypes.func,
  index: PropTypes.number,
  testID: PropTypes.string.isRequired,
};

export default Hint;
