import commonApiServices from "../shared";
import { PRACTICE } from "constants/portalTypes";

export const {
  getFilters,
  getAll,
  getOne,
  getMultiple,
  searchActive,
} = commonApiServices(PRACTICE, "products");
