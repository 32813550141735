import React from "react";
import PropTypes from "prop-types";
import Product from "./Product";
import { Col } from "react-bootstrap";
import { getItemPrice } from "utils/product-helpers";
import styled from "styled-components";

export const ProductsHeaderRow = styled.tr`
  background-color: #0e2439;
  color: #fff;
`;

const Products = ({ products, ...otherProps }) => (
  <Col md={12} className="Products">
    <table className="table print">
      <thead>
        <ProductsHeaderRow>
          <td colSpan="2">Product</td>
          <td>Qty</td>
          <td>Size</td>
          <td width="200">Sold By</td>
          <td>Price/Copay</td>
        </ProductsHeaderRow>
      </thead>
      <tbody>
        {products.map((product) => (
          <Product
            key={`ReceiptProduct-${product.product_id}`}
            {...product}
            price={getItemPrice(product)}
            {...otherProps}
          />
        ))}
      </tbody>
    </table>
  </Col>
);

Products.propTypes = {
  products: PropTypes.array,
};

export default Products;
