import { combineReducers } from "redux";
import ordersReducer from "./ordersReducer";
import subscriptionReducer from "shared/containers/SubscriptionEdit/reducers";
import patientsListReducer from "app-shared/containers/PatientsList/reducer";
import patientProfileReducer from "app-shared/containers/PatientProfile/reducer";
import popupReducer from "app-shared/containers/Popup/reducer";
import organizationUserFormReducer from "app-shared/containers/OrganizationUserForm/reducer";
import timezonesReducer from "./timezonesReducer";

export default combineReducers({
  orders: ordersReducer,
  subscriptions: subscriptionReducer,
  patients: patientsListReducer,
  patientProfile: patientProfileReducer,
  popup: popupReducer,
  organizationUserForm: organizationUserFormReducer,
  timezones: timezonesReducer,
});
