import details from "./details";
import doctors from "./doctors";
import orders from "./orders";
import patients from "./patients";
import products from "./products";
import subscriptions from "./subscriptions";
import treatment_plans from "./treatment_plans";
import users from "./users";
import dropcharts from "./dropcharts";
import payors from "./payors";

const commonApis = {
  details,
  doctors,
  orders,
  patients,
  products,
  subscriptions,
  treatment_plans,
  users,
  dropcharts,
  payors,
};

export default commonApis;
