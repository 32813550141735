import styled from "styled-components";
import { AMERICAN_EXPRESS, AMEX_ALIASES } from "utils/card-helpers";

export const CardIcon = styled.img.attrs({
  src: ({ type }) => {
    return `/assets/${
      AMEX_ALIASES.includes(type) ? AMERICAN_EXPRESS : type
    }.png`;
  },
})`
  margin-right: 8px;
  height: 30px;
  width: 40px;
  object-fit: contain;
  object-position: center;
`;

export default CardIcon;
