import { all, call } from "redux-saga/effects";

import loginSagas from "shared/containers/LoginPage/sagas";
import apiErrorSagas from "shared/containers/APIErrorHandler/sagas";
import popupSagas from "shared/containers/Popup/sagas";
import newOrderSagas from "shared/containers/OrderNew/newOrderSagas";
import orderListSagas from "shared/containers/OrderList/orderListSagas";
import productListSagas from "shared/containers/Formulary/productListSagas";

// IMPORTANT: These sagas are disabled until the issue with three api calls for
//            a single dispatched action is fixed
// import orderDetailsPageSagas from "shared/containers/OrderDetailsPage/OrderDetailsPageSagas";
// import dispenserDashboardSagas from "app-dispenser/containers/Dashboard/dashboardSagas";

export default function* rootSaga() {
  yield all([
    call(loginSagas),
    call(apiErrorSagas),
    call(popupSagas),
    call(newOrderSagas),
    call(orderListSagas),
    call(productListSagas),
    // call(orderDetailsPageSagas),
    // call(dispenserDashboardSagas),
  ]);
}
