import styled from "styled-components";

import CreditCardIcon from "../CardIcon";

export const Wrapper = styled.span``;

export const CardIcon = styled(CreditCardIcon)`
  height: 23px;
  width: 34px;
`;
