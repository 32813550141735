import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withFetching from "shared/containers/withFetching";
import Prescription from "../../components/Prescription";
import { fetch } from "./actions";

const mapStateToProps = (state) => state.dispenser.prescription;

const PrescriptionWithFetching = withFetching(Prescription, "order_id");

export default withRouter(
  connect(mapStateToProps, { fetch })(PrescriptionWithFetching)
);
