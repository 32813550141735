import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";

const defaultState = {
  show: false,
  query: "",
  results: [],
};

const searchReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.TOGGLE_SEARCH:
      return objectAssign({}, state, { show: !state.show });
    case types.SEARCH_REQUEST:
      return objectAssign({}, state, { show: false, query: action.query });
    case types.SEARCH_SUCCESS:
      return objectAssign({}, state, { results: action.results });
    default:
      return state;
  }
};

export default searchReducer;
