import { isEmpty } from "lodash";

import { SUBSCRIPTION } from "../constants/orders/order_types";
import { COMPOUND, OTC, RX } from "../constants/productTypes";
import { flatten } from "./array-helpers";

export const hasBestPriceProduct = (products) =>
  products.some((product) => product.best_price);

export const priceSet = (products) =>
  !products.some((product) => product.best_price && !product.price_set);

export const bestPrice = (order, template = {}) => {
  if (order.items && order.items.length) {
    return hasBestPriceProduct(order.items);
  } else if (order.type === SUBSCRIPTION && !isEmpty(template)) {
    return template.groups
      .map((box) => hasBestPriceProduct(box.items))
      .some((boxBestPrice) => boxBestPrice);
  }
};

export const mixedProducts = (order, template = {}) => {
  const products = productsList(order, template);
  const compound = products.some((product) => product.type === COMPOUND);
  const other = products.some((product) => product.type !== COMPOUND);

  return compound && other;
};

export const productsList = (order, template = {}) => {
  if (order.type === SUBSCRIPTION) {
    return flatten(template.groups.map((box) => box.items));
  } else {
    return order.items;
  }
};

export const findGroupByGroupNo = (groups, group_no) =>
  groups.find((g) => g.group_no === group_no);

export const isOrderOTC = (order, template) => {
  return checkProductType(order, template, OTC);
};

export const isOrderRX = (order, template) => {
  return checkProductType(order, template, RX);
};

export const checkProductType = (order, template, type) => {
  let items = [];
  if (type && [COMPOUND, RX, OTC].includes(type)) {
    if (order.items && order.items.length) {
      items = order.items;
    } else if (order.type === SUBSCRIPTION && template) {
      items = template.groups[0].items;
    }
    return items.length && items.every((product) => product.type === type);
  } else {
    throw new Error("Invalid product type");
  }
};

export const filterOrderByStatus = (status, orders = []) => {
  if (status) {
    if (typeof status === "string") {
      return orders.filter((order) => order.order_status === status);
    } else if (Array.isArray(status) && status.length) {
      return orders.filter((order) => status.includes(order.order_status));
    }
  }
  return orders;
};
