import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const PrintableWindow = (props) => {
  const { WrappedComponent, trigger, ...rest } = props;

  const triggerRef = useRef(props[trigger]);

  useEffect(() => {
    if (!triggerRef.current && props[trigger]) {
      triggerRef.current = props[trigger];
      setTimeout(window.print, 1000);
    }
  }, [props[trigger]]);

  return <WrappedComponent {...rest} />;
};

PrintableWindow.propTypes = {
  WrappedComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
    .isRequired,
  trigger: PropTypes.string.isRequired,
};

export default PrintableWindow;
