import * as types from "./constants/actionTypes";
import * as UsersApi from "api/users";
import { PatientDetails } from "api/patients";
import { DispenserDetails } from "api/dispensers";
import { PracticeDetails } from "api/practices";

import { contact } from "api/notifications";
import { push } from "connected-react-router";
import {
  getUser,
  setUser,
  setPortal,
  setJWT,
  getPortal,
  DEFAULT_TIMEOUT_MINS,
} from "utils/auth-helpers";

export const toggleResetPassword = () => ({
  type: types.TOGGLE_RESET_PASSWORD,
});

export const submitResetPassword = ({ email }) => (dispatch) => {
  dispatch({ type: types.SUBMIT_RESET_PASSWORD_REQUEST });
  UsersApi.forgotPassword(email)
    .then(() =>
      dispatch({
        type: types.SUBMIT_RESET_PASSWORD_SUCCESS,
      })
    )
    .catch(({ response }) =>
      dispatch({
        type: types.SUBMIT_RESET_PASSWORD_FAILURE,
        error: response.message,
      })
    );
};

export const toggleContactUs = () => ({
  type: types.TOGGLE_CONTACT_US,
});

export const submitContactUs = ({ name, email, message }) => (dispatch) => {
  dispatch({ type: types.SUBMIT_CONTACT_US_REQUEST });
  contact(name, email, message).then(() =>
    dispatch({ type: types.SUBMIT_CONTACT_US_SUCCESS })
  );
};

export const submitLogin = (email, password, portal = "practice") => (
  dispatch
) => {
  dispatch({ type: types.LOGIN_REQUEST });

  UsersApi.login(email, password)
    .then(async (user) => {
      if (portalMatchesUserType(user, portal)) {
        const userInfo = {
          ...user,
          auto_logout_time:
            user.auto_logout_time || DEFAULT_TIMEOUT_MINS.toString(),
        };

        setJWT(userInfo.jwt);
        setUser(userInfo);
        setPortal(userInfo.type);
        dispatch({ type: types.LOGIN_SUCCESS, user: userInfo });

        if (!isMaster(userInfo)) {
          const terms_accepted = await checkIfTermsAccepted(userInfo);

          if (terms_accepted) {
            dispatch(push(`/${portal}`));
          } else dispatch(toggleAgreeToTermsAndPolicyPopup());
        } else {
          dispatch(push(`/${portal}`));
        }
      } else {
        dispatch({
          type: types.LOGIN_FAILURE,
          message: `There is no ${portal} with this login`,
        });
      }
    })
    .catch((error) => {
      dispatch({ type: types.LOGIN_FAILURE, message: error.response });
    });
};

const portalMatchesUserType = (user, portal) => {
  return (
    user.type === portal ||
    isPatient(user, portal) ||
    isMaster(user, portal) ||
    isPractice(user, portal) ||
    isDispenser(user, portal)
  );
};

const isPatient = (user, portal = "patient") => {
  if (portal !== "patient") return false;

  return user.type === "patient";
};

const isPractice = (user, portal = "practice") => {
  if (portal !== "practice") return false;

  return user.type === "doctor";
};

const isDispenser = (user, portal = "dispenser") => {
  if (portal !== "dispenser") return false;

  return user.type === "pharmacist";
};

const isMaster = (user, portal = "admin") => {
  if (portal !== "admin") return false;

  return user.type === "master";
};

const toggleAgreeToTermsAndPolicyPopup = () => ({
  type: types.TOGGLE_SHOW_TERMS_AND_POLICY_AGREEMENT_POPUP,
});

const checkIfTermsAccepted = (user) => {
  const Api = chooseApi(user);
  return (
    Api && Api.get(user.user_id).then((settings) => settings.terms_accepted)
  );
};

const chooseApi = (user) => {
  if (isPatient(user)) return PatientDetails;
  if (isDispenser(user)) return DispenserDetails;
  if (isPractice(user)) return PracticeDetails;

  return null;
};

const getClickedElementClass = (event) => {
  const elem =
    event.target?.tagName.toLowerCase() === "button"
      ? event.target
      : event.target?.parentElement;
  const targetClass = elem?.className;

  return targetClass;
};

export const submitTermsAgreement = (event = {}) => (dispatch) => {
  const targetClass = getClickedElementClass(event);
  const closeClicked = targetClass === "close";

  if (closeClicked || !targetClass) {
    dispatch(toggleAgreeToTermsAndPolicyPopup());
  } else {
    dispatch({ type: types.SUBMIT_TERMS_AND_POLICY_AGREEMENT_REQUEST });

    const portal = getPortal();
    const user = getUser();
    const Api = chooseApi(user);

    Api?.submitTermsOfUse({ _id: user.user_id, terms_accepted: true }).then(
      () => {
        dispatch({ type: types.SUBMIT_TERMS_AND_POLICY_AGREEMENT_SUCCESS });
        dispatch(toggleAgreeToTermsAndPolicyPopup());
        dispatch(push(`/${portal}`));
      }
    );
  }
};
