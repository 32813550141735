import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
`;

export const HintsWrapper = styled.div`
  width: 100%;
  padding: 0.5rem;
  position: absolute;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow: auto;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
  display: ${(props) => (props.show ? "block" : "none")};
`;

export const HintsPrompt = styled.div`
  text-align: center;
  font-size: #c0c0c0;
  padding: 9px 8px;
  box-sizing: border-box;
`;

export const HintsSearchingAlert = styled.div`
  transform: ${({ position }) => `translateY(${position}px)`};
  display: ${({ show }) => (show ? "flex" : "none")};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  transition: display 0.5s ease;

  p {
    margin: 0;
  }
`;

export const HintWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 8px;
  min-height: 30px;
  max-height: 60px;
  box-sizing: border-box;
  background: ${(props) => (props.active ? "#f3eee3" : "#fff")};
`;

export const HintText = styled.span``;

export const HintImage = styled.div`
  display: inline-block;
  border: 1px #c0c0c0 solid;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${(props) => `url(${props.image})`};
`;

export const SearchIcon = styled.div`
  display: ${(props) => (props.float ? "block" : "none")};
  position: absolute;
  top: 0;
  ${(props) => props.float}: 0px;
  transform: scale(${(props) => props.scale || 1});
  transform-origin: ${(props) => props.float};
  width: 31px;
  height: 30px;
  margin: 4px;
  background-size: 31px 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/ic_search_gold.svg);
`;
