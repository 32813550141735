export const FETCH_ONE_ORGANIZATION_IN_MASTER_REQUEST =
  "FETCH_ONE_ORGANIZATION_IN_MASTER_REQUEST";
export const FETCH_ONE_ORGANIZATION_IN_MASTER_SUCCESS =
  "FETCH_ONE_ORGANIZATION_IN_MASTER_SUCCESS";
export const FETCH_ONE_ORGANIZATION_IN_MASTER_FAILURE =
  "FETCH_ONE_ORGANIZATION_IN_MASTER_FAILURE";

export const UPDATE_ORGANIZATION_IN_MASTER = "UPDATE_ORGANIZATION_IN_MASTER";

export const SUBMIT_ORGANIZATION_IN_MASTER_REQUEST =
  "SUBMIT_ORGANIZATION_IN_MASTER_REQUEST";
export const SUBMIT_ORGANIZATION_IN_MASTER_SUCCESS =
  "SUBMIT_ORGANIZATION_IN_MASTER_SUCCESS";
export const SUBMIT_ORGANIZATION_IN_MASTER_FAILURE =
  "SUBMIT_ORGANIZATION_IN_MASTER_FAILURE";

export const FETCH_ORGANIZATIONS_IN_MASTER_REQUEST =
  "FETCH_ORGANIZATIONS_IN_MASTER_REQUEST";
export const FETCH_ORGANIZATIONS_IN_MASTER_SUCCESS =
  "FETCH_ORGANIZATIONS_IN_MASTER_SUCCESS";
export const FETCH_ORGANIZATIONS_IN_MASTER_FAIL =
  "FETCH_ORGANIZATIONS_IN_MASTER_FAIL";
