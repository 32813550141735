import { DISPENSER, PRACTICE } from "constants/portalTypes";

export const path = (portalType, method = "") => {
  const ending = method ? `${method}` : "";
  return `${portalType}/${ending}`;
};

export const dispenserEndpoint = (method) => path(DISPENSER, method);

export const practiceEndpoint = (method) => path(PRACTICE, method);
