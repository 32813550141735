import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import Prescription from "./containers/Prescription";
import OrderLabel from "./containers/OrderLabel";
import Receipt from "./containers/Receipt";
import { SingleDropchartDocumentPrint } from "shared/components/Dropcharts";

class DispenserPrint extends React.Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route
          path={`${match.url}/prescriptions/:order_id`}
          component={Prescription}
        />
        <Route path={`${match.url}/label/:order_id/`} component={OrderLabel} />
        <Route path={`${match.url}/receipt/:receipt_id`} component={Receipt} />
        <Route
          exact
          path={`${match.url}/dropcharts/:dropchart_id`}
          component={SingleDropchartDocumentPrint}
        />
      </Switch>
    );
  }
}

DispenserPrint.propTypes = {
  match: PropTypes.object,
};

export default DispenserPrint;
