import { CACHE_KEY } from "../modules/local-cache/actions";

export const isCached = () => localStorage.getItem(CACHE_KEY);

export const clearCachedData = () =>
  isCached() && localStorage.removeItem(CACHE_KEY);

export const setLocalCache = (cachedData) => {
  localStorage.setItem(CACHE_KEY, JSON.stringify(cachedData));
};

export const getLocalCache = () => {
  return JSON.parse(localStorage.getItem(CACHE_KEY));
};

export const updateLocalCache = (field, updates) => {
  const oldCache = JSON.parse(localStorage.getItem(CACHE_KEY));
  localStorage.setItem(
    CACHE_KEY,
    JSON.stringify(Object.assign({}, oldCache, { [field]: updates }))
  );
};

export const set = (key, data) =>
  localStorage.setItem(key, JSON.stringify(data));

export const get = (key) => {
  const serializedData = localStorage.getItem(key);
  return serializedData ? JSON.parse(serializedData) : "";
};

export const remove = (key) => localStorage.removeItem(key);

export const clear = () => localStorage.clear();

export const cacheFactory = (key) => ({
  set: (data) => set(key, data),
  get: () => get(key),
  clear: () => remove(key),
});
