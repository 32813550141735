import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";

const defaultState = {
  submitting: false,
  error: null,
};

const resetPasswordReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.TOGGLE_RESET_PASSWORD:
      return objectAssign({}, state, { show: !state.show });
    case types.TOGGLE_CONTACT_US:
      return objectAssign({}, state, { show: false });
    case types.SUBMIT_RESET_PASSWORD_REQUEST:
      return objectAssign({}, defaultState, { submitting: true });
    case types.LOGIN_REQUEST:
    case types.LOGIN_SUCCESS:
    case types.SUBMIT_RESET_PASSWORD_SUCCESS:
    case types.SUBMIT_CONTACT_US_REQUEST:
    case types.SUBMIT_CONTACT_US_SUCCESS:
      return defaultState;
    case types.SUBMIT_RESET_PASSWORD_FAILURE:
      return objectAssign({}, defaultState, { error: action.message });
    default:
      return state;
  }
};

export default resetPasswordReducer;
