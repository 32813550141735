import * as types from "../constants/actionTypes";

const patientsReducer = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_PATIENTS_SUCCESS:
      return action.patients;
    default:
      return state;
  }
};

export default patientsReducer;
