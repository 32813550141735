import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";
import { LOCATION_CHANGE } from "connected-react-router";
import {
  NEW_ORDER_FETCH_ORDER_REQUEST,
  NEW_ORDER_FETCH_ORDER_SUCCESS,
  NEW_ORDER_FETCH_ORDER_FAILURE,
} from "shared/containers/OrderNew/constants/actionTypes";
import { isPractice } from "utils/auth-helpers";

const defaultState = {
  loading: false,
  updating: false,
  show_order_notes: false,
  show_submit_order: false,
  dispensersOptions: [],
  carrierOptions: [],
};

const uiReducer = (state = defaultState, action) => {
  switch (action.type) {
    case NEW_ORDER_FETCH_ORDER_REQUEST:
      return objectAssign({}, state, { loading: true });
    case NEW_ORDER_FETCH_ORDER_SUCCESS: {
      return objectAssign({}, state, {
        loading: false,
        show_order_notes: !isPractice(),

        show_submit_order: !isPractice(),
      });
    }
    case NEW_ORDER_FETCH_ORDER_FAILURE: {
      return objectAssign({}, state, { loading: false });
    }
    case types.FETCH_DISPENSERS_REQUEST:
      return objectAssign({}, state, { dispensersOptions: [] });
    case types.FETCH_DISPENSERS_SUCCESS:
      return objectAssign({}, state, {
        dispensersOptions: action.dispensers.map((d) => ({
          value: d._id,
          label: d.name,
        })),
      });
    case types.FETCH_CARRIERS_REQUEST:
      return objectAssign({}, state, { carrierOptions: [] });
    case types.FETCH_CARRIERS_SUCCESS:
      return objectAssign({}, state, { carrierOptions: action.carriers });
    case types.UPDATE_ORDER_DETAILS_REQUEST:
      return objectAssign({}, state, { updating: true });
    case types.UPDATE_ORDER_DETAILS_SUCCESS: {
      return objectAssign({}, state, {
        updating: false,
      });
    }

    case LOCATION_CHANGE:
      return defaultState;
    default:
      return state;
  }
};

export default uiReducer;
