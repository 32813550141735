export { NEW_ORDER_FLOW_RESET } from "constants/actionTypes";

export const NEW_ORDER_FETCH_ORDER_REQUEST = "NEW_ORDER_FETCH_ORDER_REQUEST";
export const NEW_ORDER_FETCH_ORDER_SUCCESS = "NEW_ORDER_FETCH_ORDER_SUCCESS";
export const NEW_ORDER_FETCH_ORDER_FAILURE = "NEW_ORDER_FETCH_ORDER_FAILURE";

export const CREATE_ORDER_FOR_NEW_PATIENT = "CREATE_ORDER_FOR_NEW_PATIENT";

export const NEW_ORDER_TEMPLATES_REQUEST = "NEW_ORDER_TEMPLATES_REQUEST";
export const NEW_ORDER_TEMPLATES_SUCCESS = "NEW_ORDER_TEMPLATES_SUCCESS";
export const NEW_ORDER_TEMPLATES_FAILURE = "NEW_ORDER_TEMPLATES_FAILURE";

export const NEW_ORDER_FETCH_PATIENT_REQUEST =
  "NEW_ORDER_FETCH_PATIENT_REQUEST";
export const NEW_ORDER_FETCH_PATIENT_SUCCESS =
  "NEW_ORDER_FETCH_PATIENT_SUCCESS";
export const NEW_ORDER_FETCH_PATIENT_FAILURE =
  "NEW_ORDER_FETCH_PATIENT_FAILURE";

export const NEW_ORDER_SELECT_DOCTOR = "NEW_ORDER_SELECT_DOCTOR";

export const SEARCH_PATIENT_REQUEST = "NEW_ORDER_SEARCH_PATIENT_REQUEST";
export const SEARCH_PATIENT_SUCCESS = "NEW_ORDER_SEARCH_PATIENT_SUCCESS";
export const SEARCH_PATIENT_FAILURE = "NEW_ORDER_SEARCH_PATIENT_FAILURE";

export const SEARCH_DOCTOR_REQUEST = "SEARCH_DOCTOR_REQUEST";
export const SEARCH_DOCTOR_SUCCESS = "SEARCH_DOCTOR_SUCCESS";
export const SEARCH_DOCTOR_FAILURE = "SEARCH_DOCTOR_FAILURE";

export const SEARCH_PRODUCT_REQUEST = "NEW_ORDER_SEARCH_PRODUCT_REQUEST";
export const SEARCH_PRODUCT_SUCCESS = "NEW_ORDER_SEARCH_PRODUCT_SUCCESS";
export const SEARCH_PRODUCT_FAILURE = "NEW_ORDER_SEARCH_PRODUCT_FAILURE";

export const NEW_ORDER_FETCH_PRODUCT_REQUEST =
  "NEW_ORDER_FETCH_PRODUCT_REQUEST";
export const NEW_ORDER_FETCH_PRODUCT_SUCCESS =
  "NEW_ORDER_FETCH_PRODUCT_SUCCESS";
export const NEW_ORDER_FETCH_PRODUCT_FAILURE =
  "NEW_ORDER_FETCH_PRODUCT_FAILURE";

export const NEW_ORDER_UPDATE_SUBSCRIPTION = "NEW_ORDER_UPDATE_SUBSCRIPTION";

export const NEW_ORDER_ADD_BOX = "NEW_ORDER_ADD_BOX";

export const NEW_ORDER_REMOVE_ORDER = "NEW_ORDER_REMOVE_ORDER";

export const NEW_ORDER_ADD_PRODUCT_TO_BOX_REQUEST =
  "NEW_ORDER_ADD_PRODUCT_TO_BOX_REQUEST";
export const NEW_ORDER_ADD_PRODUCT_TO_BOX_SUCCESS =
  "NEW_ORDER_ADD_PRODUCT_TO_BOX_SUCCESS";
export const NEW_ORDER_ADD_PRODUCT_TO_BOX_FAILURE =
  "NEW_ORDER_ADD_PRODUCT_TO_BOX_FAILURE";

export const NEW_ORDER_REMOVE_PRODUCT_FROM_BOX =
  "NEW_ORDER_REMOVE_PRODUCT_FROM_BOX";

export const NEW_ORDER_UPDATE_PRODUCT_IN_BOX =
  "NEW_ORDER_UPDATE_PRODUCT_IN_BOX";

export const UPDATE_NEW_ORDER = "UPDATE_NEW_ORDER";
export const UPDATE_PRODUCT_PRICE_IN_NEW_ORDER =
  "UPDATE_PRODUCT_PRICE_IN_NEW_ORDER";

export const UPDATE_PRODUCT_IN_NEW_ORDER = "UPDATE_PRODUCT_IN_NEW_ORDER";
export const REMOVE_PRODUCT_FROM_NEW_ORDER = "REMOVE_PRODUCT_FROM_NEW_ORDER";

export const NEW_ORDER_USE_TEMPLATE_REQUEST = "NEW_ORDER_USE_TEMPLATE_REQUEST";
export const NEW_ORDER_USE_TEMPLATE_SUCCESS = "NEW_ORDER_USE_TEMPLATE_SUCCESS";
export const NEW_ORDER_USE_TEMPLATE_FAILURE = "NEW_ORDER_USE_TEMPLATE_FAILURE";

export const NEW_ORDER_CLOSE_ALERT = "DOCTRO_NEW_ORDER_CLOSE_ALERT";

// This action types should be changed.
// Because we don't need to actually send data in each step.
export const NEW_ORDER_REQUEST = "NEW_ORDER_REQUEST";
export const NEW_ORDER_SUCCESS = "NEW_ORDER_SUCCESS";
export const NEW_ORDER_FAILURE = "NEW_ORDER_FAILURE";

export const GET_CREATED_ORDER_SUCCESS = "GET_CREATED_ORDER_SUCCESS";
export const GET_CREATED_ORDER_FAILURE = "GET_CREATED_ORDER_FAILURE";

export const SUBMIT_ORDER_PROCESSING_PAYMENT = "SUBMIT_ORDER_SUCCESS";
export const SUBMIT_ORDER_PAYMENT_SUCCESSFUL =
  "SUBMIT_ORDER_PAYMENT_SUCCESSFUL";
export const SUBMIT_ORDER_NOTIFICATION_SENT = "SUBMIT_ORDER_NOTIFICATION_SENT";

export const SUBMIT_ORDER_REQUEST = "SUBMIT_ORDER_REQUEST";
export const SUBMIT_ORDER_SUCCESS = "SUBMIT_ORDER_SUCCESS";
export const SUBMIT_ORDER_FAILURE = "SUBMIT_ORDER_FAILURE";

export const HANDLE_QUERY_NEW_ORDER = "HANDLE_QUERY_NEW_ORDER";

export const FETCH_PRESCRIBER_DETAILS_REQUEST =
  "FETCH_PRESCRIBER_DETAILS_REQUEST";
export const FETCH_PRESCRIBER_DETAILS_SUCCESS =
  "FETCH_PRESCRIBER_DETAILS_SUCCESS";
export const FETCH_PRESCRIBER_DETAILS_FAILURE =
  "FETCH_PRESCRIBER_DETAILS_FAILURE";

export const FETCH_DISPENSERS_REQUEST = "FETCH_DISPENSERS_REQUEST";
export const FETCH_DISPENSERS_SUCCESS = "FETCH_DISPENSERS_SUCCESS";

export const FETCH_CARRIERS_REQUEST = "FETCH_CARRIERS_REQUEST";
export const FETCH_CARRIERS_SUCCESS = "FETCH_CARRIERS_SUCCESS";
export const FETCH_CARRIERS_FAILURE = "FETCH_CARRIERS_FAILURE";
