import * as PracticeProducts from "./products";
import * as PracticeDetails from "./details";
import * as PracticeOrders from "./orders";
import * as PracticeDashboard from "./dashboard";
import * as PracticePatients from "./patients";
import * as PracticeDoctors from "./doctors";
import * as PracticeUsers from "./users";
import * as PracticeTemplates from "./treatment_plans";
import * as PracticePayments from "./payments";
import * as PracticeSubscriptions from "./subscriptions";
import * as PracticeDropcharts from "./dropcharts";
import * as Practices from "./practices";
import * as PracticePayors from "./payors";
import * as Dispensers from "./dispensers";

export {
  Practices,
  PracticeDetails,
  PracticeDoctors,
  PracticeOrders,
  PracticePatients,
  PracticeUsers,
  PracticeTemplates,
  PracticePayments,
  PracticeProducts,
  PracticeSubscriptions,
  PracticeDashboard,
  PracticeDropcharts,
  PracticePayors,
  Dispensers,
};

const PracticeApi = {
  practices: Practices,
  dashboard: PracticeDashboard,
  payments: PracticePayments,
  details: PracticeDetails,
  doctors: PracticeDoctors,
  orders: PracticeOrders,
  patients: PracticePatients,
  users: PracticeUsers,
  templates: PracticeTemplates,
  products: PracticeProducts,
  subscriptions: PracticeSubscriptions,
  dropcharts: PracticeDropcharts,
  payors: PracticePayors,
  dispensers: Dispensers,
};

export default PracticeApi;
