import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";
import { LOAD_FROM_CACHE } from "../../../../modules/local-cache/actions";
import { prepare as preparePatient } from "utils/patient-helpers";

const defaultPatient = {
  patient_id: null,
  patient_name: null,
  patient_allergies: null,
};

const patientReducer = (state = defaultPatient, action) => {
  switch (action.type) {
    case types.NEW_ORDER_FETCH_ORDER_SUCCESS:
      return preparePatient(action.order.patient);
    case types.HANDLE_QUERY_NEW_ORDER: {
      const {
        query: { patient_id },
      } = action;
      return patient_id ? objectAssign({}, state, { patient_id }) : state;
    }
    case types.UPDATE_NEW_ORDER:
      return objectAssign({}, state, action.updates);
    case types.NEW_ORDER_FETCH_PATIENT_SUCCESS:
      return objectAssign({}, action.patient);
    case LOAD_FROM_CACHE:
      return action.state.patient;
    default:
      return state;
  }
};

export default patientReducer;
