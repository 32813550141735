import * as types from "../constants/actionTypes";
import { LOAD_FROM_CACHE } from "../../../../modules/local-cache/actions";
import objectAssign from "object-assign";

const defaultDoctor = {
  doctor_id: null,
  name: null,
};

const doctorReducer = (state = defaultDoctor, action) => {
  switch (action.type) {
    case types.NEW_ORDER_SELECT_DOCTOR: {
      const { doctor } = action;
      return objectAssign({}, { doctor_id: doctor.id, name: doctor.text });
    }
    case types.FETCH_PRESCRIBER_DETAILS_SUCCESS:
      return action.doctor;
    case types.NEW_ORDER_FETCH_ORDER_SUCCESS:
      return action.order.doctor || state;
    case LOAD_FROM_CACHE:
      return action.state.doctor;
    default:
      return state;
  }
};

export default doctorReducer;
