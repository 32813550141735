import { getRequest, postRequest } from "../base";

import { practiceEndpoint as portalEndpoint } from "../path-helpers";
import commonApiServices from "../shared";
import { PRACTICE } from "constants/portalTypes";

export const getAll = () => {
  return getRequest(portalEndpoint("orders")).then((json) => json.orders);
};

export const getQueue = () => {
  return getRequest(portalEndpoint("orders/queue"));
};

export const getRecent = () => {
  return getRequest(portalEndpoint("orders/recent"));
};

export const approve = (ids) => {
  return postRequest(portalEndpoint("orders/approve"), { orders: ids });
};

export const {
  getList,
  getOne,
  getOrderPrice,
  submit,
  create,
  update,
  cancel,
  fetchNotes,
} = commonApiServices(PRACTICE, "orders");
