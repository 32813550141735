import { combineReducers } from "redux";
import patientReducer from "./patientReducer";
import allergiesReducer from "./allergiesReducer";

export const reducer = combineReducers({
  current: patientReducer,
  allergies: allergiesReducer,
});

export const REDUCER_NAME = "CURRENT_PATIENT";
