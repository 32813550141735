import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";

const defaultState = {
  type: null,
  loading: false,
  error: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_ORGANIZATION_DETAILS_REQUEST:
      return objectAssign({}, state, { type: null, error: null });
    case types.FETCH_ORGANIZATION_DETAILS_SUCCESS:
      return objectAssign({}, state, { type: action.accountType, error: null });
    case types.FETCH_ORGANIZATION_DETAILS_FAIL:
      return objectAssign({}, state, { loading: false, error: action.error });
    default:
      return state;
  }
};
