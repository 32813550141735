import objectAssign from "object-assign";
import { getRequest, postRequest, patchRequest } from "../../base";
import {
  prepareFromServerToClient,
  prepareToServerFormat,
} from "../../patient-helpers";
import { fromServerToClient } from "../../order-helpers";
import { path } from "../../path-helpers";

export const getAll = (base) => ({ q, skip, limit }) => {
  return getRequest(path(base, "patients"), { q, skip, limit }).then(
    (json) => json
  );
};

export const getOne = (base) => (patient_id) => {
  return getRequest(path(base, `patients/${patient_id}`)).then((patient) =>
    prepareFromServerToClient(objectAssign({}, patient, { patient_id }))
  );
};

export const search = (base) => (q) => {
  return getRequest(path(base, "patients"), { q }).then(
    (json) => json.patients
  );
};

export const getAllOrders = (base) => (patient_id) => {
  return getRequest(
    path(base, `patients/${patient_id}/orders`)
  ).then(({ orders }) =>
    orders.map((order) => fromServerToClient(order._id, order))
  );
};

export const submit = (base) => (id, params) => {
  const data = prepareToServerFormat(params);

  if (id) return patchRequest(path(base, `patients/${id}`), data);
  return postRequest(path(base, "patients"), data);
};

const PatientsApi = (base) => {
  return {
    getAll: getAll(base),
    getOne: getOne(base),
    search: search(base),
    getAllOrders: getAllOrders(base),
    submit: submit(base),
  };
};

export default PatientsApi;
