import objectAssign from "object-assign";
import { normalize } from "utils/data-helpers";
import { getItemPrice } from "utils/product-helpers";
import {
  singleInsuranceSchema,
  preparePaymentDetails,
} from "./payment-helpers";
import { prepareOrderNotes } from "./order-helpers";
import { prepare } from "./product-helpers";

export const subscriptionFactory = (params, subscription_id) => {
  const orderNotes = prepareOrderNotes(params.notes);

  return normalize(
    objectAssign(
      {},
      params,
      {
        payment_details: preparePaymentDetails(params.payment_details),
        dropchart_ids: params.dropcharts?.map(({ _id }) => _id),
      },
      orderNotes
    ),
    subscriptionSchema(Boolean(subscription_id))
  );
};

const itemSchema = {
  product_id: { type: "String" },
  type: { type: "String" },
  instructions: { type: "String" },
  amount: { type: "Integer" },
  refills: { type: "Integer" },
  price: { type: "Integer", source: (item) => getItemPrice(item).toFixed(2) },
  dispense_as_written: { type: "Boolean" },
  rx_expiry_date: { type: "String" },
  rx_number: { type: "String" },
  one_time_only: { type: "Boolean" },
};

const groupSchema = {
  type: "Object",
  prescriber_notes: { type: "String" },
  items: { type: "Array", itemSchema: itemSchema },
  preauthorized_amount: { type: "String" },
};

const subscriptionSchema = (edit = false) => {
  return {
    doctor_id: { type: "String" },
    patient_id: { type: "String" },
    prescriber_notes: { type: "String" },
    cadence: { type: "Float" },
    upcoming_shipment_date: { type: "String" },
    groups: {
      type: "Array",
      itemSchema: edit
        ? {
            ...groupSchema,
            group_no: { type: "String" },
            is_future_group: { type: "Boolean" },
          }
        : groupSchema,
    },
    payment_details: {
      type: "Object",
      schema: {
        card: { type: "Object" },
        billing_address: { type: "Object" },
        shipping_address: { type: "Object" },
        health_insurance: { type: "Array", itemSchema: singleInsuranceSchema },
      },
    },
    patient_allergies: { type: "Array", itemType: "String" },
    approved: { type: "Boolean" },
    is_active: { type: "Boolean" },
    payment_program: { type: "String" },
    delivery_method: { type: "String" },
    surgery_date: { type: "String" },
    dropchart_ids: { type: "Array", itemType: "String" },
    notes: {
      type: "Array",
      itemSchema: {
        author: { type: "String" },
        text: { type: "String" },
        show_to_pharmacy: { type: "Boolean" },
      },
    },
  };
};

export const fromServerToClient = (subscription_id, subscription) => {
  return objectAssign({}, subscription, {
    subscription_id,
    groups: subscription.groups.map((g) => {
      return {
        ...g,
        items: g.items.map((item) => {
          return prepare(item);
        }),
      };
    }),
  });
};
