import * as types from "../constants/actionTypes";

const initialState = {};

const orderConfirmationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ORDER_CONFIRMATION_REQUEST:
      return initialState;
    case types.FETCH_ORDER_CONFIRMATION_SUCCESS:
      return action.order_confirmation;
    default:
      return state;
  }
};

export default orderConfirmationReducer;
