import { getRequest } from "../base";
import { dispenserEndpoint as portalEndpoint } from "../path-helpers";
import commonApiServices from "../shared";
import { DISPENSER } from "constants/portalTypes";

export const getAllSubscriptions = (patient_id) => {
  return getRequest(portalEndpoint("subscriptions"), { patient_id }).then(
    ({ subscriptions }) => subscriptions
  );
};

export const {
  getAll,
  getOne,
  search,
  getAllOrders,
  submit,
} = commonApiServices(DISPENSER, "patients");
