import * as types from "../constants/actionTypes";

const orderLabelReducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_ORDER_LABEL_REQUEST:
      return null;
    case types.FETCH_ORDER_LABEL_SUCCESS:
      return {
        label_url: action.label_url,
        file_url: action.file_url,
      };
    default:
      return state;
  }
};

export default orderLabelReducer;
