export const FETCH_PATIENT_SETTINGS_REQUEST = "FETCH_PATIENT_SETTINGS_REQUEST";
export const FETCH_PATIENT_SETTINGS_SUCCESS = "FETCH_PATIENT_SETTINGS_SUCCESS";
export const FETCH_PATIENT_SETTINGS_FAILURE = "FETCH_PATIENT_SETTINGS_FAILURE";

export const SUBMIT_PATIENT_SETTINGS_REQUEST =
  "SUBMIT_PATIENT_SETTINGS_REQUEST";
export const SUBMIT_PATIENT_SETTINGS_SUCCESS =
  "SUBMIT_PATIENT_SETTINGS_SUCCESS";
export const SUBMIT_PATIENT_SETTINGS_FAILURE =
  "SUBMIT_PATIENT_SETTINGS_FAILURE";

export const DELETE_PET_IN_PATIENT_SETTINGS_REQUEST =
  "DELETE_PET_IN_PATIENT_SETTINGS_REQUEST";
export const DELETE_PET_IN_PATIENT_SETTINGS_SUCCESS =
  "DELETE_PET_IN_PATIENT_SETTINGS_SUCCESS";
export const DELETE_PET_IN_PATIENT_SETTINGS_FAILURE =
  "DELETE_PET_IN_PATIENT_SETTINGS_FAILURE";
