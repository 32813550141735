import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Item from "./Item";

const Wrapper = styled.div`
  margin: 0px -10px;
  padding: 0 0 30px;
`;

const ItemsWrapper = styled.div`
  margin-bottom: 30px;
  border-bottom: #d9d9d9 5px solid;
  padding: 0 10px;
`;

const NotesWrapper = styled.div`
  padding: 0 10px;
`;

const OrderContents = ({ items, notes }) => (
  <Wrapper>
    <ItemsWrapper>
      {items && items.map((item) => <Item key={item._id} {...item} />)}
    </ItemsWrapper>
    <NotesWrapper>
      <strong>Additional Notes: </strong>
      {notes || "-"}
    </NotesWrapper>
  </Wrapper>
);

OrderContents.propTypes = {
  items: PropTypes.array,
  notes: PropTypes.string,
};

export default OrderContents;
