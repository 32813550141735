const DOCTOR = "Doctor";
const PHARMACIST = "Pharmacist";
const TECHNICIAN = "Technician";
const CLERK = "Clerk";

export const PRACTICE_ROLES = { DOCTOR, TECHNICIAN, CLERK };
export const DISPENSER_ROLES = { PHARMACIST, TECHNICIAN, CLERK };

export default {
  practice: [DOCTOR, TECHNICIAN, CLERK].map((role) => ({
    label: role,
    value: role,
  })),
  dispenser: [PHARMACIST, TECHNICIAN, CLERK].map((role) => ({
    label: role,
    value: role,
  })),
};
