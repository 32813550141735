export const FETCH_ORDER_NOTES_REQUEST =
  "FETCH_ORDER_NOTES_REQUEST_IN_EXISTING_ORDER";
export const FETCH_ORDER_NOTES_SUCCESS =
  "FETCH_ORDER_NOTES_SUCCESS_IN_EXISTING_ORDER";
export const FETCH_ORDER_NOTES_FAILURE =
  "FETCH_ORDER_NOTES_FAILURE_IN_EXISTING_ORDER";

export const CREATE_ORDER_NOTE_REQUEST = "CREATE_ORDER_NOTE_REQUEST";
export const CREATE_ORDER_NOTE_SUCCESS = "CREATE_ORDER_NOTE_SUCCESS";
export const CREATE_ORDER_NOTE_FAILURE = "CREATE_ORDER_NOTE_FAILURE";

export const UPDATE_ORDER_NOTE_REQUEST = "UPDATE_ORDER_NOTE_REQUEST";
export const UPDATE_ORDER_NOTE_SUCCESS = "UPDATE_ORDER_NOTE_SUCCESS";
export const UPDATE_ORDER_NOTE_FAILURE = "UPDATE_ORDER_NOTE_FAILURE";
