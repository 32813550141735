import { omit } from "lodash";
import { getRequest, patchRequest } from "../base";

import { practiceEndpoint as portalEndpoint } from "../path-helpers";

export const getOne = (practice_id) => {
  return getRequest(portalEndpoint(`practices/${practice_id}`)).then(
    (practice) => practice
  );
};

export const submit = (practice) => {
  const requestBody = omit(practice, ["_id"]);
  return patchRequest(portalEndpoint(`practices/${practice._id}`), requestBody);
};

export const getTimezones = () =>
  getRequest(portalEndpoint("practices/list_tz"));
