import { isEqual, differenceWith, isEmpty } from "lodash";

export const flatten = (array) => {
  return array.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    []
  );
};

export const unique = (array = []) =>
  array.reduce((accumulator, current) => {
    if (accumulator.indexOf(current) + 1) {
      return accumulator;
    } else {
      return accumulator.concat(current);
    }
  }, []);

export const isArrayEqual = (x, y) => {
  return isEmpty(differenceWith(x, y, isEqual));
};
