import * as types from "../constants/actionTypes";

const defaultState = {
  loading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_ONE_TEMPLATE_REQUEST:
    case types.SAVE_CURRENT_TEMPLATE_REQUEST:
      return { loading: true };
    case types.FETCH_ONE_TEMPLATE_SUCCESS:
    case types.FETCH_ONE_TEMPLATE_FAILURE:
    case types.SAVE_CURRENT_TEMPLATE_FAILURE:
    case types.SAVE_CURRENT_TEMPLATE_SUCCESS:
      return { loading: false };
    default:
      return state;
  }
};
