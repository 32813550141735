import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";

export default (state = null, action) => {
  switch (action.type) {
    case types.FETCH_ORGANIZATION_DETAILS_REQUEST:
    case types.FETCH_ORGANIZATION_DETAILS_FAIL:
      return null;
    case types.FETCH_ORGANIZATION_DETAILS_SUCCESS:
      return objectAssign({}, state, action.settings, { admin: action.admin });
    default:
      return state;
  }
};
