import { omit } from "lodash";
import { getRequest, patchRequest } from "../../base";
import { path } from "../../path-helpers";
import { DISPENSER } from "constants/portalTypes";

export const get = (base) => (_id) => {
  return getRequest(
    path(base, `${base === DISPENSER ? "pharmacists" : "doctors"}/${_id}`)
  );
};

export const submit = (base) => (params) => {
  const requestBody = omit(params, ["_id", "provider_id"]);
  return patchRequest(
    path(
      base,
      `${base === DISPENSER ? "pharmacists" : "doctors"}/${params._id}`
    ),
    requestBody
  );
};

export const submitTermsOfUse = (base) => (params) => {
  const { _id, ...updates } = params;
  return patchRequest(
    path(base, `${base === DISPENSER ? "pharmacists" : "doctors"}/${_id}`),
    updates
  );
};

const DetailsApi = (base) => {
  return {
    get: get(base),
    submit: submit(base),
    submitTermsOfUse: submitTermsOfUse(base),
  };
};

export default DetailsApi;
