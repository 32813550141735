import { MASTER, PRACTICE, PATIENT, DISPENSER } from "constants/portalTypes";
import PatientApi from "./patients";
import MasterApi from "./master";
import PracticeApi from "./practices";
import DispenserApi from "./dispensers";
import * as UsersApi from "./users";

export const AUTH = "auth";

const APIModule = (endpoint) => {
  if (endpoint === PATIENT) return PatientApi;
  if (endpoint === MASTER) return MasterApi;
  if (endpoint === PRACTICE) return PracticeApi;
  if (endpoint === DISPENSER) return DispenserApi;
  if (endpoint === AUTH) return UsersApi;
};

export { APIModule };
