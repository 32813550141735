import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Confirmation from "../../components/Confirmation";
import * as actions from "./actions";

const mapStateToProps = (state) => state.uniform.popup;

const mapActionsToProps = { ...actions };

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(Confirmation)
);
