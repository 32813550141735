import * as types from "shared/containers/OrderNew/constants/actionTypes";
import objectAssign from "object-assign";
import { defaultOrderCompetedState } from "../../../../constants/defaultModels";

const orderCompletedReducer = (state = defaultOrderCompetedState, action) => {
  switch (action.type) {
    case types.GET_CREATED_ORDER_SUCCESS: {
      const order = objectAssign({}, state.payload, action.payload);
      return objectAssign({}, state, {
        header: action.header,
        subtitle: action.subtitle,
        payload: order,
        success: true,
      });
    }
    case types.GET_CREATED_ORDER_FAILURE:
      return objectAssign({}, state, {
        header: action.header,
        description: action.description,
        payload: action.payload,
        success: false,
      });
    default:
      return state;
  }
};

export default orderCompletedReducer;
