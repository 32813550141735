import { takeLatest, put, call } from "redux-saga/effects";
import { APIModule } from "api";
import { getPortal } from "utils/auth-helpers";
import {
  FETCH_PRODUCTS_LIST_REQUEST,
  FETCH_PRODUCTS_LIST_SUCCESS,
} from "./constants/actionTypes";

function* fetchProductList({ queryParams }) {
  const { q, skip, limit, filters } = queryParams;

  const { products, count } = yield call(
    APIModule(getPortal()).products.getAll,
    skip,
    limit,
    { ...filters, q }
  );

  yield put({
    type: FETCH_PRODUCTS_LIST_SUCCESS,
    ...queryParams,
    products,
    count,
  });
}

function* productListSagas() {
  yield takeLatest(FETCH_PRODUCTS_LIST_REQUEST, fetchProductList);
}

export default productListSagas;
