import React from "react";
import PropTypes from "prop-types";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { FormikInput as Input } from "shared/components/Input";
import { emailRequired, getPropsForFormikField } from "utils/validations";
import { Modal, Button } from "react-bootstrap";

const validationSchema = yup.object({
  email: emailRequired(),
});

const initialValues = { email: "" };
const contactUsLinkStyle = {
  marginLeft: "5px",
  cursor: "pointer",
  fontWeight: "bold",
};

const ResetPassword = ({
  show,
  submitted,
  toggle,
  submit,
  toggleContactUs,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={(values) => (submitted ? toggle() : submit(values))}
  >
    {(formikProps) => {
      const { handleSubmit } = formikProps;
      const getFieldProps = getPropsForFormikField(formikProps);
      return (
        <Modal show={show} onHide={toggle}>
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton />
            <Modal.Body>
              {submitted ? (
                <p>
                  Please check your email to complete your <br />
                  password reset.
                </p>
              ) : (
                <div className="Alert-content">
                  <h4>Password Reset</h4>
                  <p>
                    Please enter the email address associated with your account.
                    We’ll send you an email with your username and a link to
                    reset your password.
                  </p>

                  <Field
                    component={Input}
                    className="col-sm-12"
                    {...getFieldProps("email")}
                  />
                  <br />
                  <p>
                    If you still need help, please
                    <a onClick={toggleContactUs} style={contactUsLinkStyle}>
                      Contact Us
                    </a>
                  </p>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer className="reset-password-footer">
              <Button bsStyle="primary" bsSize="large" type="submit">
                {submitted ? "Close" : "Submit"}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      );
    }}
  </Formik>
);

ResetPassword.propTypes = {
  show: PropTypes.bool,
  form: PropTypes.object,
  submitted: PropTypes.bool,
  errors: PropTypes.object,
  toggle: PropTypes.func,
  update: PropTypes.func,
  submit: PropTypes.func,
  toggleContactUs: PropTypes.func,
};

export default ResetPassword;
