import objectAssign from "object-assign";
import { flatten, unique } from "utils/array-helpers";
import { assignProduct } from "utils/product-helpers";
import { prepare } from "./product-helpers";
import { ONE_TIME, SUBSCRIPTION } from "constants/orders/order_types";

export const getProductIds = (template) =>
  template.type === ONE_TIME
    ? template.items.map((item) => item._id)
    : unique(
        flatten(template.groups.map((box) => box.items.map((item) => item._id)))
      );

export const getGroupsItemsCombined = (groups) => {
  if (!groups) return [];
  return unique(flatten(groups.map((box) => box.items.map((item) => item))));
};

export const fullfillTemplate = (template, products) => {
  if (template.type === ONE_TIME) {
    const items = fullfillItems(template.items, products);

    return objectAssign({}, template, { items });
  } else {
    const groups = template.groups.map((box) => fullfillBox(box, products));

    return objectAssign({}, template, { groups, type: SUBSCRIPTION });
  }
};

export const fullfillBox = (box, products) => {
  return objectAssign({ preauthorized_amount: "" }, box, {
    items: fullfillItems(box.items, products),
  });
};

export const fullfillItems = (items = [], products) => {
  return items.map((item) => {
    const product = products.find((p) => p.product_id == item._id);

    // Recomended price is the unit price, and price is the total price
    const price =
      parseFloat(item.recommended_price || item.price) * item.amount;

    const preparedItem = prepare(assignProduct(product, { ...item, price }));

    return preparedItem;
  });
};
