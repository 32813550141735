export const SOMETHING_WENT_WRONG = "Something Went Wrong!";
export const FILL_REQUIRED_FIELDS = "Please fill required fields";
export const NOT_FOUND = "Server reported an error when processing request.";
export const CRITICAL_ERROR = "Critical Error";
export const UNAUTHORIZED = "Session expired";

export const errorMessagesWithStatuses = {
  "401": UNAUTHORIZED,
  "403": SOMETHING_WENT_WRONG,
  "422": FILL_REQUIRED_FIELDS,
  "500": CRITICAL_ERROR,
};
