export const fromJsonToQueryString = (query) => {
  let q = [];
  for (let key in query) {
    if (query[key] || (key == "q" && query[key] === "")) {
      if (Array.isArray(query[key])) {
        q = q.concat(query[key].map((f) => `${key}=${f}`));
      } else {
        q = q.concat(`${key}=${query[key]}`);
      }
    }
  }

  const string = q.join("&");

  return string ? `?${string}` : string;
};

export function fromQueryStringToJson(qs) {
  const pairs = qs.replace("?", "").split("&");
  const result = {};

  pairs.forEach(function (p) {
    const pair = p.split("=");
    const key = pair[0];
    const value = decodeURIComponent(pair[1] || "");

    if (!key) return;

    if (result[key]) {
      if (Object.prototype.toString.call(result[key]) === "[object Array]") {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
    }
  });

  return JSON.parse(JSON.stringify(result));
}
