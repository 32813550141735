import React from "react";
import styled from "styled-components";
import Link from "../NewTabLink";

const Wrapper = styled.div`
  color: #c6aa76;
`;

class TermsPolicyLinks extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <Link to="/terms-of-use">Terms of Use</Link>
        <span style={{ color: "#fff" }}> | </span>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </Wrapper>
    );
  }
}

export default TermsPolicyLinks;
