import * as types from "../constants/modalsActionTypes";
import objectAssign from "object-assign";

const defaultState = {
  contactUsForm: {
    show: false,
    submitSucceeded: false,
  },
  showNeedHelp: false,
};

const modalsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.TOGGLE_MODAL: {
      const name = `${
        action.modal === "NeedHelp" ? "showNeedHelp" : "contactUsForm"
      }`;
      const updates =
        name === "showNeedHelp"
          ? !state[name]
          : objectAssign(
              {},
              { submitSucceeded: false, show: !state[name].show }
            );

      return objectAssign({}, state, { [name]: updates });
    }
    case types.CONTACT_US_REQUEST_SUCCESS:
      return objectAssign({}, state, {
        contactUsForm: { ...state.contactUsForm, submitSucceeded: true },
      });
    case types.CONTACT_US_REQUEST_FAIL:
      return objectAssign({}, state, {
        contactUsForm: { ...state.contactUsForm, submitSucceeded: false },
      });
    default:
      return state;
  }
};

export default modalsReducer;
