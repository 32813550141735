import objectAssign from "object-assign";
import * as types from "../constants/actionTypes";

const defaultState = {
  loadingProduct: false,
};

const uiReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.TOGGLE_PRODUCT_LOADING:
      return objectAssign({}, state, { loadingProduct: !state.loadingProduct });
    default:
      return state;
  }
};

export default uiReducer;
