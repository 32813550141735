import { takeLatest, put, call } from "redux-saga/effects";
import { getPortal } from "utils/auth-helpers";
import { APIModule } from "api";
import {
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
} from "./constants/actionTypes";

function* fetchOrderList({ queryParams }) {
  try {
    const { orders, count } = yield call(
      APIModule(getPortal()).orders.getList,
      queryParams
    );

    yield put({
      type: FETCH_ORDERS_SUCCESS,
      ...queryParams,
      orders,
      count,
    });
  } catch (e) {
    yield put({ type: FETCH_ORDERS_FAILURE });
  }
}

function* orderListSagas() {
  yield takeLatest(FETCH_ORDERS_REQUEST, fetchOrderList);
}

export default orderListSagas;
