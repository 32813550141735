import objectAssign from "object-assign";
import { updateByIndex, updateById } from "./state-helpers";
import has from "lodash/has";
import toNumber from "lodash/toNumber";
import { isNumberValid } from "utils/primitiveTypesHelpers/number-helpers";
import { capitalize } from "utils/string-helpers";
import { RX, OTC, COMPOUND } from "constants/productTypes";
import { afterTransferStatuses } from "constants/orders/order_statuses";

const validateUpdates = (product, updates) => {
  const keys = [
    "instructions",
    "refills",
    "strength",
    "dispense_as_written",
    "best_price",
    "shipping_fee",
    "recommended_price",
    "price",
    "rx_expiry_date",
    "rx_number",
  ];
  const object = {};

  keys.forEach((key) => {
    if (has(updates, key)) object[key] = updates[key];
  });

  if (
    updates.strength &&
    product.available_options?.[updates.strength] != null
  ) {
    object.strength = updates.strength;
  }

  if (updates.amount && updates.amount > 0) {
    object.amount = updates.amount;
  }

  return object;
};

export const getItemPriceInServerFormat = (item) => {
  const priceAsString = getItemPrice(item).toFixed(2);
  return parseFloat(priceAsString);
};

export const getItemPrice = (item = {}) => {
  return getItemSingleUnitPrice(item) * (item.amount || 0);
};

export const getItemSingleUnitPrice = (product) => {
  let productPrice;

  if (isNumberValid(product.available_options?.[product.strength])) {
    productPrice = product.available_options?.[product.strength];
  } else if (isNumberValid(product.price)) {
    productPrice = product.price;
  } else if (isNumberValid(product.recommended_price)) {
    productPrice = product.recommended_price;
  }

  const preparedPrice = parseFloat(productPrice);

  return isNumberValid(preparedPrice) ? preparedPrice : 0;
};

export const assignProduct = (product, updates = {}) => {
  return objectAssign({}, product, validateUpdates(product, updates));
};

export const updateItemPrice = (product, price) => {
  const { strength } = product;
  const safePrice = toNumber(price);

  const available_options = objectAssign({}, product.available_options, {
    [strength]: safePrice / product.amount,
  });

  return objectAssign({}, product, { available_options, price });
};

export const updateProductByIndex = (products, index, updates) =>
  updateByIndex(products, index, updates, assignProduct);

export const updateArrayItemById = (
  products,
  id,
  updates,
  key = "product_id"
) => updateById(products, id, updates, key, assignProduct);

export const updateItemPriceById = (
  products,
  id,
  price,
  key = "product_id"
) => {
  const index = products.findIndex((product) => product[key] === id);
  const updates = updateItemPrice(products[index], price);

  return updateByIndex(products, index, updates);
};

export const formatTypeForClient = (type) => {
  return type === OTC ? type : capitalize(type);
};

export const checkShowRxNumber = (
  sub_status,
  type,
  isHubDispenser,
  isDispenser
) => {
  if (type !== RX && type !== COMPOUND) {
    return false;
  }

  if (!sub_status) {
    return isDispenser;
  }

  const isShowRxNumberStatus = afterTransferStatuses.includes(sub_status);

  return isHubDispenser || (isDispenser && isShowRxNumberStatus);
};
