import objectAssign from "object-assign";
import isFunction from "lodash/isFunction";

const isEmptyObject = (obj) => {
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};

export const isRequired = (value, message) => {
  const isObject = typeof value === "object" && !isEmptyObject(value);
  const isString = typeof value === "string" && value.replace(/\s*/, "");
  const isNumber = typeof value === "number";

  if (value && (isObject || isString || isNumber)) {
    return null;
  } else {
    return message || "REQUIRED FIELD";
  }
};

export const isEmail = (value, message) => {
  const reg = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/;
  if (reg.test(value) || value === "") {
    return null;
  } else {
    return message || "SHOULD BE VALID EMAIL";
  }
};

export const isPhone = (value, message) => {
  const reg = /^\d{10}$/;
  if (reg.test(value.replace(/[()\s-]/g, ""))) {
    return null;
  } else {
    return message || "SHOULD BE VALID PHONE NUMBER";
  }
};

export const isBirthday = (value, message) => {
  const [month, day, year] = value.split("/").map((v) => parseInt(v));
  const currentYear = new Date().getFullYear();
  if (
    month >= 1 &&
    month <= 12 &&
    day >= 1 &&
    day <= 31 &&
    year >= 1900 &&
    year <= currentYear
  ) {
    return null;
  } else {
    return message || "SHOULD BE VALID BIRTHDAY";
  }
};

const validationErrors = (value, validators) => {
  if (validators) {
    let nested = {};
    for (let i = 0; i < validators.length; i++) {
      const v = validators[i];
      if (Array.isArray(value)) {
        return value.map((sub) => validationErrors(sub, validators));
      } else {
        const valueToTest = v.nested ? value && value[v.nested] : value;
        if (v.nested) {
          if (!nested[v.nested]) {
            const error = isFunction(v.validator)
              ? v.validator(valueToTest, v.message)
              : null;
            nested[v.nested] = error ? [error] : null;
          }
        } else {
          const error = isFunction(v.validator)
            ? v.validator(valueToTest, v.message)
            : null;
          if (error) return [error];
        }
      }
    }
    return isEmptyObject(nested) ? null : nested;
  } else {
    return null;
  }
};

export const validate = (state, validations) => {
  if (!state) return state;

  let newState = objectAssign({}, state);

  for (let key in newState) {
    newState[key] = objectAssign({}, newState[key], {
      errors: validationErrors(newState[key].value, validations[key]),
    });
  }
  return newState;
};

export const validateByNewWay = (state, validations) => {
  if (!state) return state;

  let errors = {};

  for (let key in state) {
    if (key !== "errors") {
      errors[key] = validationErrors(state[key], validations[key]);
    }
  }
  return objectAssign({}, state, { errors });
};

// New Parts

const validationError = (value, validators) => {
  if (validators) {
    for (let i = 0; i < validators.length; i++) {
      const error = validators[i].validator(value, validators[i].message);
      if (error) return error;
    }
  } else {
    return null;
  }
};

export const newValidate = (state, validations) => {
  if (!state) return state;

  let errors = {};

  for (let key in state) {
    if (key !== "errors") {
      errors[key] = validationError(state[key], validations[key]);
    }
  }

  return objectAssign({}, errors, { hasErrors: newHasErrors(errors) });
};

const newHasErrors = (errors) => {
  for (let key in errors) {
    if (errors[key]) return true;
  }
  return false;
};

// End of New Parts

export const hasErrors = (state) => {
  const { errors } = state;
  for (let key in errors) {
    if (Object.hasOwnProperty.call(errors, key) && errors[key]) {
      if (Array.isArray(errors[key])) {
        if (typeof errors[key][0] === "string") {
          return true;
        } else {
          if (errors[key].find((sub) => hasErrors({ errors: sub })))
            return true;
        }
      } else {
        if (hasErrors({ errors: errors[key] })) return true;
      }
    }
  }
  return false;
};

export const updateStateWithErasingErrors = (state, updates) => {
  let errorsUpdates = {};
  Object.keys(updates).map((key) => (errorsUpdates[key] = null));
  const errors = objectAssign({}, state.errors, errorsUpdates);
  return objectAssign({}, state, updates, { errors });
};

// TODO
// Write test for this
export const validateExpiryByType = (items, errors = [], typeValue) => {
  items.forEach(({ type, rx_expiry_date, refills }) => {
    if (
      type === typeValue &&
      !rx_expiry_date &&
      !errors.includes("rx_expiry_date")
    ) {
      errors.push("rx_expiry_date");
    }

    if (type === typeValue && !refills && !errors.includes("refills")) {
      errors.push("refills");
    }
  });
  return errors;
};
