import objectAssign from "object-assign";

import * as types from "../constants/actionTypes";

const defaultState = {
  loading: false,
  products: [],
  maxProducts: 0,
  loadingProducts: false,
};

const uiReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_ONE_SUBSCRIPTION_REQUEST:
    case types.FETCH_ONE_SUBSCRIPTION_FAILURE:
    case types.FETCH_ONE_SUBSCRIPTION_SUCCESS:
      return { ...state, loading: !state.loading };

    case types.NEW_SUBSCRIPTION_SEARCH_PRODUCT_REQUEST:
      return { ...state, loadingProducts: true };
    case types.NEW_SUBSCRIPTION_SEARCH_PRODUCT_SUCCESS: {
      const products = action.results.map((p) => ({
        id: p._id,
        text: p.name,
        image_url: p.image_url,
      }));
      return objectAssign({}, state, {
        loadingProducts: false,
        products,
        maxProducts: action.count,
      });
    }
    case types.RESET_SUBSCRIPTION:
      return defaultState;
    default:
      return state;
  }
};

export default uiReducer;
