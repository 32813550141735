import * as types from "../constants/actionTypes";
import objectAssign from "object-assign";

const defaultState = {
  submitting: false,
  error: null,
  show_terms_policy_agreement: false,
};

const loginReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return objectAssign({}, defaultState, { submitting: true });
    case types.LOGIN_SUCCESS:
    case types.SUBMIT_RESET_PASSWORD_REQUEST:
    case types.SUBMIT_RESET_PASSWORD_SUCCESS:
    case types.SUBMIT_CONTACT_US_REQUEST:
    case types.SUBMIT_CONTACT_US_SUCCESS:
      return defaultState;
    case types.LOGIN_FAILURE:
      return objectAssign({}, defaultState, { error: action.message });
    case types.TOGGLE_SHOW_TERMS_AND_POLICY_AGREEMENT_POPUP: {
      return objectAssign({}, state, {
        show_terms_policy_agreement: !state.show_terms_policy_agreement,
      });
    }
    default:
      return state;
  }
};

export default loginReducer;
