import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import BaseInputWithValidation from "../BaseInputWithValidation";

class TextArea extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { onChange } = this.props;
    const { value } = event.target;
    onChange && onChange(value);
  }

  render() {
    const {
      value,
      name,
      placeholder,
      onChange,
      textareaStyle,
      ...baseProps
    } = this.props;
    return (
      <BaseInputWithValidation {...baseProps}>
        <textarea
          className="form-control"
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={name && onChange ? onChange : this.handleChange}
          style={textareaStyle}
        />
      </BaseInputWithValidation>
    );
  }
}

TextArea.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  errors: PropTypes.array,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  textareaStyle: PropTypes.object,
};

TextArea.defaultProps = {
  placeholder: "",
  value: "",
};

export default TextArea;
