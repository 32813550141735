export const FETCH_PRACTICES_IN_ADMIN_REQUEST =
  "FETCH_PRACTICES_IN_ADMIN_REQUEST";
export const FETCH_PRACTICES_IN_ADMIN_SUCCESS =
  "FETCH_PRACTICES_IN_ADMIN_SUCCESS";
export const FETCH_PRACTICES_IN_ADMIN_FAILURE =
  "FETCH_PRACTICES_IN_ADMIN_FAILURE";

export const UPDATE_PRACTICE_IN_ADMIN = "UPDATE_PRACTICE_IN_ADMIN";

export const DELETE_PRACTICE_IN_ADMIN_REQUEST =
  "DELETE_PRACTICE_IN_ADMIN_REQUEST";
export const DELETE_PRACTICE_IN_ADMIN_SUCCESS =
  "DELETE_PRACTICE_IN_ADMIN_SUCCESS";
export const DELETE_PRACTICE_IN_ADMIN_FAILURE =
  "DELETE_PRACTICE_IN_ADMIN_FAILURE";

export const UPLOAD_PRACTICES_IN_ADMIN_REQUEST =
  "UPLOAD_PRACTICES_IN_ADMIN_REQUEST";
export const UPLOAD_PRACTICES_IN_ADMIN_SUCCESS =
  "UPLOAD_PRACTICES_IN_ADMIN_SUCCESS";
export const UPLOAD_PRACTICES_IN_ADMIN_FAILURE =
  "UPLOAD_PRACTICES_IN_ADMIN_FAILURE";

export const SUBMIT_PRACTICE_IN_ADMIN_REQUEST =
  "SUBMIT_PRACTICE_IN_ADMIN_REQUEST";
export const SUBMIT_PRACTICE_IN_ADMIN_SUCCESS =
  "SUBMIT_PRACTICE_IN_ADMIN_SUCCESS";
export const SUBMIT_PRACTICE_IN_ADMIN_FAILURE =
  "SUBMIT_PRACTICE_IN_ADMIN_FAILURE";

export const UPDATE_FILTER = "UPDATE_FILTER";
