import objectAssign from "object-assign";
import { templateSchema, treatmentPlanSchema } from "schemas";

import {
  getRequest,
  deleteRequest,
  patchRequest,
  postRequest,
} from "../../base";
import ProductsApi from "./products";
import { getProductIds, fullfillTemplate } from "../../treatment-plan-helpers";
import { ONE_TIME, SUBSCRIPTION } from "constants/orders/order_types";
import { normalize } from "utils/data-helpers";
import { getUser } from "utils/auth-helpers";
import { path } from "../../path-helpers";
import { PRACTICE, DISPENSER } from "constants/portalTypes";

export const getAll = (base) => () => {
  if (base === DISPENSER) {
    return getRequest(path(base, "templates")).then((json) => json.templates);
  } else if (base === PRACTICE) {
    const getTemplates = getRequest(path(base, "templates"));
    const getTreatmentPlans = getRequest(path(base, "treatment-plan"));

    return Promise.all([
      getTemplates,
      getTreatmentPlans,
    ]).then(([{ templates }, { treatment_plans }]) =>
      templates
        .concat(treatment_plans)
        .map((template) =>
          template.type === ONE_TIME
            ? template
            : { ...template, type: SUBSCRIPTION }
        )
    );
  }
};

const prepareFromServerToClient = (base) => (template) => {
  const product_ids = getProductIds(template);

  if (product_ids && product_ids.length) {
    return ProductsApi(base)
      .getMultiple(product_ids)
      .then((products) => fullfillTemplate(template, products));
  } else {
    return new Promise((resolve) => resolve(template));
  }
};

export const getOne = (base) => (template_id, type) => {
  let pathRoot = "";
  if (base === DISPENSER) {
    pathRoot = "templates";
  } else if (base === PRACTICE) {
    pathRoot = type === ONE_TIME ? "templates" : "treatment-plan";
  }

  return getRequest(path(base, `${pathRoot}/${template_id}`)).then((json) =>
    TreatmentPlansApi(base).prepareFromServerToClient(
      base === PRACTICE ? { _id: template_id, ...json } : { ...json }
    )
  );
};

export const remove = (base) => (template_id, type) => {
  let pathRoot = "";
  if (base === DISPENSER) {
    pathRoot = "templates";
  } else if (base === PRACTICE) {
    pathRoot = type === ONE_TIME ? "templates" : "treatment-plan";
  }
  return deleteRequest(path(base, `${pathRoot}/${template_id}`));
};

export const submit = (base) => (template) => {
  let params = normalize(template, templateSchema);
  if (base === DISPENSER || (base === PRACTICE && template.type === ONE_TIME)) {
    return template._id
      ? patchRequest(path(base, `templates/${template._id}`), params)
      : postRequest(path(base, "templates"), params);
  } else if (base === PRACTICE) {
    const { provider_id } = getUser();
    params = normalize(
      objectAssign({ practice_id: provider_id }, template),
      treatmentPlanSchema
    );

    return template._id
      ? patchRequest(path(base, `treatment-plan/${template._id}`), params)
      : postRequest(path(base, "treatment-plan"), params);
  }
};

const TreatmentPlansApi = (base) => {
  return {
    getAll: getAll(base),
    getOne: getOne(base),
    remove: remove(base),
    submit: submit(base),
    prepareFromServerToClient: prepareFromServerToClient(base),
  };
};

export default TreatmentPlansApi;
