import { combineReducers } from "redux";

import orderCompletedReducer from "../containers/OrderCompleted/reducer";
import templatesReducer from "../containers/Templates/reducer";
import settingsReducer from "../containers/Settings/reducer";
import dashboardReducer from "../containers/Dashboard/reducer";
import dropchartsListPageReducer from "../containers/DropchartDocumentsListPage/reducer";
import orderConfirmationReducer from "../containers/OrderConfirmation/reducer";
import newTemplateReducer from "../containers/TemplateForm/reducers";

export default combineReducers({
  orderCompleted: orderCompletedReducer,
  templates: templatesReducer,
  newTemplate: newTemplateReducer,
  settings: settingsReducer,
  dashboard: dashboardReducer,
  dropchartDocuments: dropchartsListPageReducer,
  orderConfirmation: orderConfirmationReducer,
});
