import objectAssign from "object-assign";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import { formatDateString } from "utils/date";
import { phoneToClientFormat, phoneToServerFormat } from "utils/formatter";
import { normalize } from "utils/data-helpers";
import { addressSchema, addressToServerFormat } from "./address-helpers";
import { singleInsuranceSchema } from "./payment-helpers";

export const prepareToServerFormat = (patient) => {
  const data = objectAssign({}, patient, {
    date_of_birth: formatDateString(patient.date_of_birth),
    phone: phoneToServerFormat(patient.phone),
    address: addressToServerFormat(patient.address),
    billing_address: addressToServerFormat(patient.billing_address),
  });
  const schema = generateSchema(data);

  return normalize(data, schema);
};

const basicPatientSchema = {
  first_name: { type: "String" },
  last_name: { type: "String" },
  date_of_birth: { type: "String" },
};

const creditCardSchema = {
  name: { type: "String" },
  expiration_date: { type: "String" },
  card_type: { type: "String" },
  last_4: { type: "String" },
};

const extendedPatientSchema = objectAssign({}, basicPatientSchema, {
  phone: { type: "String" },
  email: { type: "String" },
  gender: { type: "String" },
  terms_accepted: { type: "Boolean" },
  allergies: { type: "Array", itemType: "String" },
  address: { type: "Object", schema: addressSchema },
  billing_address: { type: "Object", schema: addressSchema },
  card: { type: "Object", schema: creditCardSchema },
  health_insurance: { type: "Array", itemSchema: singleInsuranceSchema },
});

export const generateSchema = (data) => {
  if (data.email) {
    return objectAssign({}, basicPatientSchema, extendedPatientSchema);
  }

  let schema = objectAssign({}, basicPatientSchema);
  for (let key in data) {
    if (!isObject(data[key]) && data[key]) {
      // check strings
      if (typeof data[key] === "string" && data[key]) {
        schema[key] = extendedPatientSchema[key];
      }
    } else if (isArray(data[key])) {
      schema[key] = extendedPatientSchema[key];
    } else {
      // check non-empty objects
      if (data[key] && Object.keys(data[key]).length !== 0) {
        schema[key] = extendedPatientSchema[key];
      }
    }
  }
  return schema;
};

export const prepareFromServerToClient = (data) => {
  return { ...data, phone: phoneToClientFormat(data.phone) };
};
