import {
  masterGetRequest,
  masterPostRequest,
  masterPatchRequest,
  masterDeleteRequest,
} from "./base";
import objectAssign from "object-assign";
import { prepare } from "../product-helpers";

export const getFilters = () => {
  return masterGetRequest("products/filters").then((json) => json);
};

export const getAll = (offset, limit, filters = {}) => {
  const params = objectAssign({}, filters, { skip: offset, limit });
  return masterGetRequest("products", params).then((json) => ({
    ...json,
    products: json.products.map((product) =>
      objectAssign({}, product, prepare(product))
    ),
  }));
};

export const getOne = (product_id) => {
  return masterGetRequest(`products/${product_id}`).then((json) =>
    objectAssign({}, json, { product_id })
  );
};

export const submit = (product) => {
  if (product._id) {
    const { _id, ...updates } = product;
    return masterPatchRequest(`products/${_id}`, updates);
  } else {
    return masterPostRequest("products", product);
  }
};

export const remove = (product_id) => {
  return masterDeleteRequest(`products/${product_id}`);
};

export const upload = (s3_file) => {
  return masterPostRequest("upload", { s3_file });
};
