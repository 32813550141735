import * as types from "../constants/actionTypes";

export default (state = [], action = {}) => {
  switch (action.type) {
    case types.FETCH_DROPCHART_DOCUMENTS_SUCCESS:
    case types.DELETE_DOCUMENT_SUCCESS:
      return action.dropcharts;
    default:
      return state;
  }
};
