import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withFetching from "shared/containers/withFetching";
import Receipt from "shared/components/Print/Receipt";
import * as actions from "./actions";

const mapStateToProps = (state) => state.dispenser.receipt;

const ReceiptWithFetching = withFetching(Receipt, "receipt_id");

export default withRouter(
  connect(mapStateToProps, actions)(ReceiptWithFetching)
);
