import { combineReducers } from "redux";
import labelReducer from "./labelReducer";
import orderReducer from "./orderReducer";
import statusHistoryReducer from "./statusHistoryReducer";
import uiReducer from "./uiReducer";

export default combineReducers({
  label: labelReducer,
  order: orderReducer,
  status_history: statusHistoryReducer,
  ui: uiReducer,
});
