import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { APIModule } from "api";
import { DISPENSER } from "constants/portalTypes";
import Checkbox from "shared/components/Checkbox";
import Edit from "shared/components/EditButton";
import ErrorMessage from "shared/components/ErrorMessage";
import {
  ListWrapper,
  SingleDocumentWrapper,
  DocumentInfoWrapper,
  DocumentProductsWrapper,
} from "./styles";

const SelectDropchartDocumentsModal = ({
  show,
  dropcharts,
  selectedDoctorId,
  onHide,
  onSelect,
  onPrintDocuments,
}) => {
  const [availableDocuments, updateAvailableDocumentsList] = React.useState([]);

  useEffect(() => {
    fetchDoctorDocuments(selectedDoctorId);
  }, [selectedDoctorId]);

  async function fetchDoctorDocuments(doctor_id) {
    const dropchartsInfo = await APIModule(DISPENSER).dropcharts.getAll({
      filters: { doctor_id },
    });

    const doctorDropcharts = dropchartsInfo.dropcharts;

    doctorDropcharts.forEach((document) => {
      if (dropcharts.find((d) => d._id === document._id)) {
        document.checked = true;
      }
    });

    updateAvailableDocumentsList(doctorDropcharts);
  }

  function handleDocumentCheck(id) {
    const updatedDocuments = availableDocuments.map((document) =>
      document._id === id
        ? { ...document, checked: !document.checked }
        : document
    );

    updateAvailableDocumentsList(updatedDocuments);
  }

  function handleSelectDocuments() {
    const selectedDocuments = availableDocuments.filter(
      ({ checked }) => checked
    );

    onSelect(selectedDocuments);
    onHide();
  }

  function handleSingleDocumentPrint(event, document) {
    event.preventDefault();
    event.stopPropagation();

    onPrintDocuments([document]);
  }

  const submitDisabled =
    !dropcharts.length &&
    !availableDocuments.find((document) => document.checked);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <h5>Documents</h5>
        <span>Below are the available documents from the selected doctor</span>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0, paddingBottom: "30px" }}>
        <ListWrapper>
          {availableDocuments.length ? (
            availableDocuments.map((document) => (
              <SingleDocumentWrapper key={document._id}>
                <Checkbox
                  checked={document.checked}
                  onChange={() => handleDocumentCheck(document._id)}
                />
                <DocumentInfoWrapper>
                  <span>{document.type}</span>
                  <Edit
                    data-test="print-document"
                    onClick={(e) => handleSingleDocumentPrint(e, document)}
                  >
                    {document.name}
                  </Edit>
                  <DocumentProductsWrapper>
                    <span>
                      {document.details
                        .map((product) => product.name)
                        .join(", ")}
                    </span>
                  </DocumentProductsWrapper>
                </DocumentInfoWrapper>
              </SingleDocumentWrapper>
            ))
          ) : (
            <ErrorMessage error="No documents found from selected doctor" />
          )}
        </ListWrapper>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="primary"
          bsSize="large"
          onClick={handleSelectDocuments}
          disabled={submitDisabled}
        >
          Select
        </Button>
        <Button bsStyle="default" bsSize="large" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SelectDropchartDocumentsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  selectedDoctorId: PropTypes.string,
  dropcharts: PropTypes.array.isRequired,
  onHide: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onPrintDocuments: PropTypes.func.isRequired,
};

export default SelectDropchartDocumentsModal;
