import * as types from "../constants/actionTypes";
import { prepareHealthInsurance } from "utils/payment-helpers";

const patientReducer = (state = null, action) => {
  switch (action.type) {
    case types.FETCH_PATIENT_PROFILE_REQUEST:
      return null;
    case types.FETCH_PATIENT_PROFILE_SUCCESS: {
      const {
        patient: {
          address,
          billing_address,
          card,
          health_insurance,
          ...otherDetails
        },
      } = action;

      return {
        ...otherDetails,
        payment_details: {
          shipping_address: address,
          billing_address: billing_address,
          card,
          health_insurance: prepareHealthInsurance(health_insurance),
        },
      };
    }

    case types.UPDATE_PATIENT_PROFILE_SUCCESS:
      return { ...state, ...action.updates };
    case types.FETCH_PATIENT_PROFILE_FAILURE:
      return action.error;
    default:
      return state;
  }
};

export default patientReducer;
