import { postRequest, deleteRequest, patchRequest } from "../base";
import { subscriptionFactory } from "../subscription-helpers";

import { dispenserEndpoint as portalEndpoint } from "../path-helpers";
import commonApiServices from "../shared";
import { DISPENSER } from "constants/portalTypes";

export const createOne = (params) => {
  const subscription = subscriptionFactory(params);

  return postRequest(portalEndpoint("subscriptions"), subscription);
};

export const updateOne = (subscription_id, params) => {
  const subscription = subscriptionFactory(params, subscription_id);

  return patchRequest(
    portalEndpoint(`subscriptions/${subscription_id}`),
    subscription
  );
};

export const removeOne = (subscription_id) => {
  return deleteRequest(portalEndpoint(`subscriptions/${subscription_id}`));
};

export const addOneProduct = (subscription_id, group_id, params) => {
  return postRequest(
    portalEndpoint(`subscriptions/${subscription_id}/groups/${group_id}`),
    params
  );
};

export const { getList, getOne, submit } = commonApiServices(
  DISPENSER,
  "subscriptions"
);
