import {
  masterGetRequest,
  masterPostRequest,
  masterPutRequest,
  masterPatchRequest,
  masterDeleteRequest,
} from "./base";
import objectAssign from "object-assign";

export const getAll = (skip, limit, filters) => {
  return masterGetRequest("practices", { skip, limit, ...filters }).then(
    (json) => json || null
  );
};

export const getOne = (practice_id) => {
  return masterGetRequest(`practices/${practice_id}`).then((json) =>
    objectAssign({}, json, { practice_id })
  );
};

export const getAdmin = (practice_id) => {
  return masterGetRequest(`practices/${practice_id}/admin`).then(
    (admin) => admin
  );
};

export const submit = (practice) => {
  if (practice.practice_id) {
    return masterPatchRequest(`practices/${practice.practice_id}`, practice);
  } else {
    return masterPostRequest("practices", practice);
  }
};

export const remove = (practice_id) => {
  return masterDeleteRequest(`practices/${practice_id}`);
};

export const submitAdmin = ({ organization_id, admin_id, admin_data }) => {
  if (admin_id) {
    return masterPatchRequest(`practices/${organization_id}/admin`, admin_data);
  } else {
    return masterPostRequest(`practices/${organization_id}/admin`, admin_data);
  }
};

export const makeUserAnAdmin = (practice_id, user_id) => {
  return masterPutRequest(`practices/${practice_id}/admin`, { user_id });
};

export const resendAdminInvite = (practice_id) => {
  return masterPutRequest(`practices/${practice_id}/admin/invite`);
};

export const getUsersFor = (practice_id) => {
  return masterGetRequest(`practices/${practice_id}/users`).then(
    (json) => json.users
  );
};
