import { isObject, get } from "lodash";

import * as delivery_methods from "./delivery_methods";
import * as order_types from "constants/productTypes";

export const OPEN = "Open";
export const APPROVED = "Approved";
export const AWAITING_PATIENT_APPROVAL = "Awaiting Patient Approval";
export const AWAITING_TRANSFER = "Awaiting Transfer";
export const TRANSFERRED = "Transferred";
export const PENDING = "Pending";
export const SHIPPED = "Shipped";
export const DELIVERED = "Delivered";
export const ENQUEUED = "Enqueued";
export const CANCELED = "Canceled";

export const DETAILS_PENDING = "Details Pending";
export const EN_ROUTE = "En Route";
export const NEW = "New order";

export const OUT_OF_NETWORK = "Out Of Network";

// This is raw statuses. Not all of them will be displaied
export const ALL_STATUSES = [
  OPEN,
  APPROVED,
  AWAITING_PATIENT_APPROVAL,
  AWAITING_TRANSFER,
  TRANSFERRED,
  PENDING,
  ENQUEUED,
  SHIPPED,
  DELIVERED,
  CANCELED,
  OUT_OF_NETWORK,
];

export const VISIBLE_STATUSES = [
  OPEN,
  PENDING,
  ENQUEUED,
  SHIPPED,
  DELIVERED,
  CANCELED,
  OUT_OF_NETWORK,
];

export const HUB_DISPENSER_ORDER_DETAILS_VISIBLE_STATUSES = [
  OPEN,
  AWAITING_TRANSFER,
  TRANSFERRED,
  PENDING,
  SHIPPED,
  DELIVERED,
  CANCELED,
];

export const OPEN_STATUSES = [
  OPEN,
  APPROVED,
  AWAITING_PATIENT_APPROVAL,
  AWAITING_TRANSFER,
  TRANSFERRED,
];

export const PENDING_STATUSES = [PENDING];

export const COMPLETED_STATUSES = [SHIPPED, DELIVERED];

export const EDITABLE_STATUSES = [
  NEW,
  OPEN,
  APPROVED,
  AWAITING_PATIENT_APPROVAL,
  AWAITING_TRANSFER,
  TRANSFERRED,
];

export const CANCELABLE_STAUSES = [OPEN];

export const RECEIPT_PRINTABLE_STATUSES = [
  PENDING,
  ENQUEUED,
  SHIPPED,
  DELIVERED,
];

export const ADD_COURIER_DETAILS_STATUSES = [PENDING, SHIPPED];

export const MAX_TOTAL_PRICE_EDITABLE_STATUSES = [
  NEW,
  OPEN,
  APPROVED,
  AWAITING_PATIENT_APPROVAL,
  AWAITING_TRANSFER,
];

const SHIPPED_FULL_INFO_PRESENT = 0;
const SHIPPED_PARTIAL_INFO_PRESENT = 1;

export const SHIPPED_STATUS_STAGES = {
  SHIPPED_PARTIAL_INFO_PRESENT,
  SHIPPED_FULL_INFO_PRESENT,
};

const AWAITING_PAYMENT = "Awaiting Payment";
const AWAITING_SHIPMENT_DETAILS = "Awaiting Shipment Details";
const AWAITING_COURIER_PICKUP = "Awaiting Courier Pick-Up";
const AWAITING_LOCAL_PICKUP = "Awaiting Local Pick-Up";
const PICKEDUP_BY_COURIER = "Picked Up By Courier";

export const afterTransferStatuses = [
  AWAITING_PAYMENT,
  AWAITING_SHIPMENT_DETAILS,
  AWAITING_COURIER_PICKUP,
  AWAITING_LOCAL_PICKUP,
  PICKEDUP_BY_COURIER,
];

export const STATUS_DESCRIPTIONS = {
  [OPEN]: {
    [order_types.RX]: "Awaiting Doctor's Approval",
    [order_types.COMPOUND]: "Details Pending",
    [order_types.OTC]: "Details Pending",
  },
  [APPROVED]: "Details Pending",
  [AWAITING_PATIENT_APPROVAL]: "Pending Patient Approval",
  [AWAITING_TRANSFER]: "Awaiting Transfer",
  [TRANSFERRED]: AWAITING_PAYMENT,
  [PENDING]: {
    [delivery_methods.COURIER_SERVICE]: AWAITING_COURIER_PICKUP,
    [delivery_methods.STANDARD_SHIPPING]: AWAITING_SHIPMENT_DETAILS,
    [delivery_methods.PICKUP_IN_PERSON]: AWAITING_LOCAL_PICKUP,
  },
  [SHIPPED]: {
    [delivery_methods.COURIER_SERVICE]: PICKEDUP_BY_COURIER,
    [delivery_methods.STANDARD_SHIPPING]: "Out For Delivery",
  },
  [ENQUEUED]: "Enqueued for fulfilment",
  [DELIVERED]: {
    [delivery_methods.COURIER_SERVICE]: "",
    [delivery_methods.STANDARD_SHIPPING]: "",
    [delivery_methods.PICKUP_IN_PERSON]: "Local Pick-Up Confirmed",
  },
  [CANCELED]: "",
};

const PENDING_PATIENT_APPROVAL = "Pending Patient Approval";

export const TRANSFER_STATUSES = [
  DETAILS_PENDING,
  PENDING_PATIENT_APPROVAL,
  AWAITING_TRANSFER,
  TRANSFERRED,
];

export const isEditable = (order_status) => {
  return EDITABLE_STATUSES.includes(getCanonicalStatus(order_status));
};

// This function is needed because some of statuses are hidden
// for portals except Hub Dispenser
export const getCanonicalStatus = (status) => {
  if (!status) return NEW;

  return status && VISIBLE_STATUSES.includes(status) ? status : OPEN;
};

// TODO: too long function naming
export const getHubDispenserOrderDetailsCanonicalStatus = (status) => {
  return status && HUB_DISPENSER_ORDER_DETAILS_VISIBLE_STATUSES.includes(status)
    ? status
    : OPEN;
};

export const withNullHandledStatus = (status) => {
  if (!status) return NEW;

  return status;
};

export const displayShippedAsEnRoute = (status) =>
  status === SHIPPED ? EN_ROUTE : status;

export const getSubStatus = (status, path) => {
  const sub_status = STATUS_DESCRIPTIONS[status];

  if (sub_status && isObject(sub_status)) {
    return path ? get(sub_status, path) : "- -";
  }

  return sub_status;
};
