import * as types from "../constants/actionTypes";
import { APIModule } from "api";
import { getPortal } from "utils/auth-helpers";
import { recalcOrderPrice } from "utils/price-helpers";
import { getGroupsItemsCombined } from "api/treatment-plan-helpers";
import * as custom_template_ids from "constants/custom_templates_ids";
import { ONE_TIME } from "constants/orders/order_types";

export const fetchTreatmentPlans = () => {
  return (dispatch) => {
    dispatch({ type: types.NEW_ORDER_TEMPLATES_REQUEST });
    const portal = getPortal();

    APIModule(portal)
      .templates.getAll()
      .then((treatment_plans) => {
        dispatch({
          type: types.NEW_ORDER_TEMPLATES_SUCCESS,
          treatment_plans,
        });
      });
  };
};

const prepareTemplateData = async (template) => {
  const portal = getPortal();

  const { _id, type } = template;
  const isCustom =
    _id === custom_template_ids.ONE_TIME ||
    _id === custom_template_ids.SUBSCRIPTION;

  if (!isCustom) {
    const fetchedTreatmentDetails = await APIModule(portal).templates.getOne(
      _id,
      type
    );
    return { ...template, ...fetchedTreatmentDetails };
  }

  return template;
};

export const useTemplate = (treatment_plan) => {
  return async (dispatch) => {
    dispatch({ type: types.NEW_ORDER_USE_TEMPLATE_REQUEST });

    const fullTemplateInfo = await prepareTemplateData(treatment_plan);
    const { type, items = [], groups } = fullTemplateInfo;

    const allItems =
      type === ONE_TIME ? [...items] : getGroupsItemsCombined(groups);

    const price = await recalcOrderPrice(allItems);

    dispatch({
      type: types.NEW_ORDER_USE_TEMPLATE_SUCCESS,
      template: fullTemplateInfo,
      items,
      price,
    });
  };
};

export const addProductToBox = (index, product_id) => {
  return async (dispatch) => {
    dispatch({ type: types.NEW_ORDER_ADD_PRODUCT_TO_BOX_REQUEST });

    const portal = getPortal();
    const product = await APIModule(portal).products.getOne(product_id);

    dispatch({
      type: types.NEW_ORDER_ADD_PRODUCT_TO_BOX_SUCCESS,
      product,
      index,
    });
  };
};

export const addNewBox = () => ({
  type: types.NEW_ORDER_ADD_BOX,
});

export const removeProductFromBox = (index, product_id) => ({
  type: types.NEW_ORDER_REMOVE_PRODUCT_FROM_BOX,
  index,
  product_id,
});

export const updateProductInBox = (index, product_id, updates) => ({
  type: types.NEW_ORDER_UPDATE_PRODUCT_IN_BOX,
  index,
  product_id,
  updates,
});
