import objectAssign from "object-assign";
import { updateById, removeById } from "utils/state-helpers";
import { updateArrayItemById } from "utils/product-helpers";
import { findGroupByGroupNo } from "utils/order-helpers";

const updateTemplateBoxItems = (state, index, items) => {
  const groups = updateById(state.groups, index, { items }, "group_no");
  return objectAssign(objectAssign({}, state, { groups }));
};

export const addGroup = (state) => {
  const { groups } = state;
  const group_no = groups.length + 1;
  const newBox = { group_no, items: [] };
  return objectAssign({}, state, { groups: groups.concat(newBox) });
};

export const removeGroup = (state, action) => {
  const boxIndex = action.group_no;
  const groups = removeById(
    state.groups,
    boxIndex,
    "group_no"
  ).map((box, index) => objectAssign({}, box, { group_no: index + 1 }));
  return objectAssign({}, state, { groups });
};

export const addProductToBox = (state, action) => {
  const { index, product } = action;
  const { items } = findGroupByGroupNo(state.groups, index);

  if (items.some((item) => item.product_id === product.product_id)) {
    return state;
  } else {
    return updateTemplateBoxItems(state, index, items.concat(product));
  }
};

export const removeProductFromBox = (state, action) => {
  const { index, product_id } = action;

  const groupToUpdate = findGroupByGroupNo(state.groups, index);
  const items =
    groupToUpdate && removeById(groupToUpdate.items, product_id, "product_id");

  return updateTemplateBoxItems(state, index, items);
};

export const updateProductInBox = (state, action) => {
  const { index, product_id, updates } = action;

  const groupToUpdate = findGroupByGroupNo(state.groups, index);

  const items =
    groupToUpdate &&
    updateArrayItemById(groupToUpdate.items, product_id, updates, "product_id");

  return updateTemplateBoxItems(state, index, items);
};
