import styled from "styled-components";
import { standardBorderColorGrey } from "styles/theme";

export const ListWrapper = styled.div`
  height: 20em;
  overflow-y: scroll;
  background-color: #fff;
  font-size: 16px;
`;

export const SingleDocumentWrapper = styled.section`
  display: flex;
  border-bottom: 1px solid ${standardBorderColorGrey};
  margin: 1em;
`;

export const DocumentInfoWrapper = styled.section`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0.5em 1em;
`;

export const DocumentProductsWrapper = styled.div`
  margin-top: 10px;
`;
